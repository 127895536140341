import SalesOrderTableItem from "./SalesOrderTableItem";
import ExaminationResultForm from "./ExaminationResultForm";
import ExaminationResultDetail from "./ExaminationResultDetail";
import SalesOrderGridItem from "./SalesOrderGridItem";
import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";
import ExaminationResultGridItem from "./ExaminationResultGridItem";
import ExaminationResultTableItem from "./ExaminationResultTableItem";
import LabContactForm from "./LabContactForm";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  SalesOrder: {
    Grid: {
      ...Grid,
      Item: SalesOrderGridItem,
    },
    Table: {
      ...Table,
      Item: SalesOrderTableItem,
    },
  },
  ExaminationResult: {
    Grid: {
      ...Grid,
      Item: ExaminationResultGridItem,
    },
    Table: {
      ...Table,
      Item: ExaminationResultTableItem,
    },
    Detail: ExaminationResultDetail,
    Form: ExaminationResultForm,
  },
  LabContact: {
    Form: LabContactForm,
  },
};

export default exportObjs;
