import React from "react";
import Label from "app/components/common/Label";

function ProductDeliveryHistoryTableItem(props) {
  const { children } = props;
  const productDeliveryHistoryItem = children;

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {productDeliveryHistoryItem.deliveryDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {productDeliveryHistoryItem.scalpLabProduct.name}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {productDeliveryHistoryItem.salon.name}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {productDeliveryHistoryItem.orderAmount}
      </td>
      <td className="whitespace-pre-wrap px-6 py-4">
        {productDeliveryHistoryItem.memo}
      </td>
    </tr>
  );
}

export default ProductDeliveryHistoryTableItem;
