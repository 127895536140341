import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Drc from "app/components/Drc";

function ExaminationResultDetailPage(props) {
  const { Id } = useParams();

  const [examinationResultItem, setExaminationResultItem] = useState({});
  const [examinationResultItemLoaded, setExaminationResultItemLoaded] =
    useState(false);

  useEffect(() => {
    async function getExaminationResultDetail(Id) {
      //examinationDataId
      const response = await Api.examinationDatas.get(Id);
      const item = response.data;
      setExaminationResultItem(item);
      setExaminationResultItemLoaded(true);
    }

    getExaminationResultDetail(Id);
  }, [Id]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: examinationResultItem.id,
      }}
      showNotice={false}
    >
      {!examinationResultItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <Drc.ExaminationResult.Detail>
            {examinationResultItem}
          </Drc.ExaminationResult.Detail>
        </div>
      )}
    </DefaultLayout>
  );
}

export default ExaminationResultDetailPage;
