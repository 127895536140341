import SharedInfoLevelTableItem from "./SharedInfoLevelTableItem";

import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
  },
  Table: {
    ...Table,
    SharedInfoLevelTableItem: SharedInfoLevelTableItem,
  },
};

export default exportObjs;
