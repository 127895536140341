import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function SalonManagementCreateMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.salon.manage.list);
  };

  return (
    <MainMenuItem
      itemTitleString="美容室情報を見る"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default SalonManagementCreateMenuItem;
