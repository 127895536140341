import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MainMenuItem from "app/components/MainMenuItem";
import BarcodeScannerModal from "./BarcodeScannerModal";
import RegisterInputModal from "./RegisterInputModal";
import RegisterSuccessFailModal from "./RegisterSuccessFailModal";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SelectRegisterMethodModal({ openDialog, handleOnCloseClicked, title, content, handleOnSendClicked }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [barCode, setBarCode] = React.useState(false);
  const [openBarcodeScannerModal, setOpenBarcodeScannerModal] = React.useState(false);
  const [openRegisterInputModal, setOpenRegisterInputModal] = React.useState(false);
  const [openRegisterSuccessFailModal, setOpenRegisterSuccessFailModal] = React.useState(false);
  

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const onCloseClicked = () => {
    handleClose();
    handleOnCloseClicked();
  }

  const onSendClicked = (code) => {
    handleOnSendClicked(code);
  }

  const handleCodeDetected = (code) => {
    // 在这里处理从 BarcodeScanner 传来的条形码信息
    //setFieldValue("inspectionKitId", code || "")
    console.log(code)
    setBarCode(code)
    setOpenBarcodeScannerModal(false)
    setOpenRegisterInputModal(false)
    onSendClicked(code)
  };

  const handleOnNextCloseClicked = () => {
    setOpenBarcodeScannerModal(false)
    setOpenRegisterInputModal(false)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="flex">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2"  className="">
            頭皮ラボに発送しますか
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 8 }} align="center" className="">
            {content}
          </Typography> */}
          <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
            <MainMenuItem
              itemTitleString="カメラでスキャン"
              onClick={ () => {
                setOpenBarcodeScannerModal(true)
              }}
              size="100%"
            />
          </div>
          <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
            <MainMenuItem
              itemTitleString="検体No.入力"
              onClick={ () => {
                setOpenRegisterInputModal(true)
              }}
              size="100%"
            />
          </div>
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-end place-content-end absolute inset-x-0 top-2/3 ">
            {/* <button
              onClick={onSendClicked}
              type="button"
              className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
            >
              一括発送
            </button> */}
          </div>
          <BarcodeScannerModal
            openDialog={openBarcodeScannerModal}
            onCodeDetected={handleCodeDetected} 
            handleOnNextCloseClicked={handleOnNextCloseClicked} >
          </BarcodeScannerModal>

          <RegisterInputModal
            openDialog={openRegisterInputModal}
            onCodeDetected={handleCodeDetected} 
            handleOnNextCloseClicked={handleOnNextCloseClicked} >
          </RegisterInputModal>

          {/* <RegisterSuccessFailModal
            openDialog={openRegisterSuccessFailModal}
            onCodeDetected={handleCodeDetected} 
            handleOnNextCloseClicked={handleOnNextCloseClicked}
            title={"エラー"} 
            content={"見つかりません"}>
          </RegisterSuccessFailModal> */}

        </Box>
      </Modal>
    </div>
  );
}