import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function SalonManageCreatePage(props) {
  const { salonId, stepId } = useParams();

  const [salonItem, setSalonItem] = useState({});
  const [salonItemLoaded, setSalonItemLoaded] = useState(false);

  useEffect(() => {
    async function getSalonItem(salonId) {
      const response = await Api.salons.get(salonId);
      const { _id: id, name, imageUrl, nameKana, picLastName, picFirstName, telephone, email, postalCode, prefecture, city, street, building, latitude, longitude, salonMenus, salonStaffs } = response?.data || {};

      const menus = salonMenus?.map((item) => {
        const { imageUrl, name, description, durationNumber, durationString, priceTaxExc, priceTaxInc, publicityStatus, itemToUse, menuType, _id: id } = item || {};
        return {
          id,
          imageUrl,
          name,
          description,
          duration: durationNumber,
          price: priceTaxExc,
          durationString,
          durationNumber,
          priceTaxExc,
          priceTaxInc,
          publicityStatus,
          itemToUse,
          menuType,
        };
      });

      const artists = salonStaffs?.map((item) => {
        const { imageUrl, name, lastNameKanji: lastName, firstNameKanji: firstName, lastNameKata: lastNameKana, firstNameKata: firstNameKana, dob, phone: telephone, email, postalCode, address, _id: id } = item || {};
        return {
          id,
          imageUrl,
          name,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          dob,
          telephone,
          email,
          postalCode,
          address,
        };
      });

      const item = {
        id,
        name,
        imageUrl,
        nameKana,
        picLastName,
        picFirstName,
        telephone,
        email,
        postalCode,
        latitude,
        longitude,
        menus,
        artists,
        prefecture,
        city,
        street,
        building,
      };
      setSalonItem(item);
      setSalonItemLoaded(true);
    }

    getSalonItem(salonId);
  }, [salonId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店情報編集",
      }}
      showNotice={false}>
      {!salonItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Salon.Manage.Form isEdit={true} stepId={+stepId} salonId={salonId}>
          {salonItem}
        </Salon.Manage.Form>
      )}
    </DefaultLayout>
  );
}

export default SalonManageCreatePage;
