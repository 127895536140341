import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";

function KitStockCreatePage() {
  const { stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "在庫登録",
      }}
      showNotice={false}
    >
      <Kit.Stock.Form stepId={+stepId} />
    </DefaultLayout>
  );
}

export default KitStockCreatePage;
