import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Salon from "app/components/Salon";

function DepositCreatePage() {
  const { salonId, stepId } = useParams();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "入金登録",
      }}
      showNotice={false}>
      <Salon.Deposit.Form stepId={+stepId} salonId={salonId} />
    </DefaultLayout>
  );
}

export default DepositCreatePage;
