import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Kit from "app/components/Kit";

function KitStockCreatePage() {
  const { kitStockHistoryId, stepId } = useParams();
  const [kitStockHistoryItem, setKitStockHistoryItem] = useState({});
  const [kitStockHistoryItemLoaded, setKitStockHistoryItemLoaded] =
    useState(false);

  useEffect(() => {
    async function getDepositItem(kitStockHistoryId) {
      const response = await Api.kit.stock.histories.get(kitStockHistoryId);
      const item = response.data;
      setKitStockHistoryItem(item);
      setKitStockHistoryItemLoaded(true);
    }

    getDepositItem(kitStockHistoryId);
  }, [kitStockHistoryId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録",
      }}
      showNotice={false}
    >
      {!kitStockHistoryItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Kit.Stock.Form isEdit={true} stepId={+stepId}>
          {kitStockHistoryItem}
        </Kit.Stock.Form>
      )}
    </DefaultLayout>
  );
}

export default KitStockCreatePage;
