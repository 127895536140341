import React from "react";
import MainMenuItem from "app/components/MainMenuItem";

function MainContentLayout(props) {
  const { children, pageTitle } = props;
  return (
    <div className="w-full h-full overflow-auto">
      <div className="w-full h-full relative pt-10 pb-6 pl-32 pr-12 flex flex-col justify-center">
        <div className="absolute top-6 left-6">
          <MainMenuItem
            itemTitle={pageTitle}
            disabled
            isActive
            borderSize={3}
            size={80}
          />
        </div>

        {children}
      </div>
    </div>
  );
}

export default MainContentLayout;
