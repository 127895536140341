import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";

function DepositDoneTableItem(props) {
  const { children, onActionClick } = props;
  const salonDepositItem = children;

  const onActionClicked = () => {
    onActionClick(salonDepositItem);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {salonDepositItem?.paymentDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {salonDepositItem?.paymentNumber}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{salonDepositItem?.paymentTitle}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {salonDepositItem?.paymentAccount}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Label.Price>{salonDepositItem?.paymentAmount}</Label.Price>
      </td>
      <td className="whitespace-pre-wrap px-6 py-4">{salonDepositItem?.memo}</td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onActionClicked}>修正</Button.Primary>
      </td>
    </tr>
  );
}

export default DepositDoneTableItem;
