import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import ProductOrder from "app/components/ProductOrder";
import Button from "app/components/common/Button";
import BackBtn from "../../../components/BackButton";

function ProductOrderListPage() {
  const navigate = useNavigate();

  const [productOrderHistories, setProductOrderHistories] = useState([]);
  const [productOrderHistoriesLoaded, setProductOrderHistoriesLoaded] =
    useState(false);

  async function getAllProductOrderHistories() {
    const response = await Api.labProductSalonOrder.notDeliver()
    const items = response.data;
    setProductOrderHistories(items);
    setProductOrderHistoriesLoaded(true);
  }

  useEffect(() => {

    getAllProductOrderHistories();
  }, []);

  const onDeliverButtonClicked = (productOrder) => {
    navigate(Path.product.order.delivery.confirmForm(productOrder._id, 0));
  };

  const onConfirmButtonClicked = async (productOrder) => {
    const response = await Api.labProductSalonOrder.orderConfirm(productOrder._id, { orderAmount: productOrder.orderAmount})
    .then((res) => {
      getAllProductOrderHistories();
    })
    .catch((e) => {
      alert(e);
    })
    .finally(() => {
    });
  };

  const onCreateDeliveryButtonClicked = () => {
    navigate(Path.kit.delivery.create(0));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "商品発注確認・発送",
      }}
    >
      <p>確認待ち</p>
      <ProductOrder.Order.Table.Table
        items={productOrderHistories}
        loading={!productOrderHistoriesLoaded}
      >
        <ProductOrder.Order.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </ProductOrder.Order.Table.Loading>
        <ProductOrder.Order.Table.Empty>
          商品の発注がないです。
        </ProductOrder.Order.Table.Empty>
        <ProductOrder.Order.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              受注日時
            </th>
            <th scope="col" className="px-6 py-4">
              商品名
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </ProductOrder.Order.Table.Header>
        <ProductOrder.Order.Table.Items>
          {productOrderHistories.filter((item) => {
            if (item.orderStatus == "waiting") {
              return true;
            }
            return false;
          })
          .map((item) => (
            <ProductOrder.Order.Table.Item
              key={item.id}
              onDeliverButtonClick={onDeliverButtonClicked}
              onConfirmButtonClick={onConfirmButtonClicked}
            >
              {item}
            </ProductOrder.Order.Table.Item>
          ))}
        </ProductOrder.Order.Table.Items>
      </ProductOrder.Order.Table.Table>

      <p>未発送リスト</p>

      {/* <Button.Primary onClick={onCreateDeliveryButtonClicked}>
        新規登録
      </Button.Primary> */}


      <ProductOrder.Order.Table.Table
        items={productOrderHistories}
        loading={!productOrderHistoriesLoaded}
      >
        <ProductOrder.Order.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </ProductOrder.Order.Table.Loading>
        <ProductOrder.Order.Table.Empty>
        商品の発注がないです。
        </ProductOrder.Order.Table.Empty>
        <ProductOrder.Order.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              受注日時
            </th>
            <th scope="col" className="px-6 py-4">
              商品名
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </ProductOrder.Order.Table.Header>
        <ProductOrder.Order.Table.Items>
          {productOrderHistories.filter((item) => {
            if (item.orderStatus == "confirmed") {
              return true;
            }
            return false;
          })
          .map((item) => (
            <ProductOrder.Order.Table.Item
              key={item.id}
              onDeliverButtonClick={onDeliverButtonClicked}
              onConfirmButtonClick={onConfirmButtonClicked}
            >
              {item}
            </ProductOrder.Order.Table.Item>
          ))}
        </ProductOrder.Order.Table.Items>
      </ProductOrder.Order.Table.Table>
      <BackBtn url={Path.product.home}/>
    </DefaultLayout>
  );
}

export default ProductOrderListPage;
