import { useState, useEffect } from "react";

export default function CategorySelection({ defaultValue, onChange, valuesArray }) {
  const [categoryValue, setCategoryValue] = useState(defaultValue);

  const handleSetValue = (categoryValue) => {
    setCategoryValue(categoryValue);
    onChange && onChange(categoryValue);
  };

  useEffect(() => {
    if (onChange) onChange(categoryValue);
  }, []);

  return (
    <select
      name="categories"
      id="categories"
      onChange={(event) => handleSetValue(event.target.value)}
    >
      {valuesArray?.map((item) => {
        return (
          <option value={item.value} selected={categoryValue === item.value} key={item.value}>
            {item.value}
          </option>
        );
      })}
    </select>
  );
}
