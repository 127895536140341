import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function AgencyCreateMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.contractor.create(0, "agency"));
  };

  return (
    <MainMenuItem
      itemTitleString="代理店を登録する"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default AgencyCreateMenuItem;
