import React from "react";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Button from "app/components/common/Button";

function SalonMenuGridItem(props) {
  const { children, onClicked, onDeleteClicked, onEditClicked, selected } = props;
  const menuItem = children;

  const onMenuClick = () => {
    if (onClicked) {
      onClicked(menuItem);
    }
  };

  const handleDeleteClicked = () => {
    if (onDeleteClicked) {
      onDeleteClicked(menuItem);
    }
  };

  const handleEditClicked = () => {
    if (onEditClicked) {
      onEditClicked(menuItem);
    }
  };

  return (
    <Layout.Container
      className={`bg-white px-2 py-6 flex flex-row rounded-lg ${
        selected
          ? "!border-main-default !border-2 !border-solid"
          : "!border-gridItem-border !border-2 !border-solid"
      }`}
      onClick={onMenuClick}
    >
      {menuItem ? (
        <Layout.Row>
          {menuItem?.imageUrl && (
            <Layout.Col>
              <img src={menuItem?.imageUrl} alt={menuItem?.name} />
            </Layout.Col>
          )}
          <Layout.Col grow={true}>
            {/* <p>ID: {menuItem?.id}</p> */}
            <p className="font-bold">Name: {menuItem?.name}</p>
            <p className="whitespace-pre-wrap">
              Description: {menuItem?.description}
            </p>
            <Label.Duration>Duration: {menuItem?.duration}</Label.Duration>
            <Label.Price>Price: {menuItem?.price}</Label.Price>
            {onEditClicked && <Button.Primary onClick={handleEditClicked}>編集</Button.Primary>}
            {onDeleteClicked && onEditClicked && (
              <Button.Danger onClick={handleDeleteClicked}>削除</Button.Danger>
            )}
          </Layout.Col>
        </Layout.Row>
      ) : (
        <Layout.Row>メニュー選択しない</Layout.Row>
      )}
    </Layout.Container>
  );
}

export default SalonMenuGridItem;
