import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import ExaminationData from "app/components/ExaminationData";

function ExaminationDataDetailPage(props) {
  const { examinationDataId } = useParams();

  const [examinationDataItem, setExaminationDataItem] = useState({});
  const [examinationDataItemLoaded, setExaminationDataItemLoaded] =
    useState(false);

  useEffect(() => {
    async function getDepositItem(examinationDataId) {
      const response = await Api.examinationDatas.get(examinationDataId);
      const item = response.data;
      setExaminationDataItem(item);
      setExaminationDataItemLoaded(true);
    }

    getDepositItem(examinationDataId);
  }, [examinationDataId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: examinationDataItem.name,
      }}
      showNotice={false}
    >
      {!examinationDataItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <ExaminationData.Detail>{examinationDataItem}</ExaminationData.Detail>
        </div>
      )}
    </DefaultLayout>
  );
}

export default ExaminationDataDetailPage;
