import React from "react";
import { useMemo, useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate, createSearchParams } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Button from "app/components/common/Button";
import DM from "app/components/DM";
import { useParams } from "react-router-dom";
import { DMHistories } from "app/services/api";
import AnalyseStatusSelection from "app/components/AnalyseStatusSelection";
import { getNameFromFirstLast } from "app/utils";
import DateRangePicker from "app/components/common/DateRangePicker";
import BackBtn from "../../../components/BackButton";
import useQueryConfig from "./useQueryConfig";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";

function DMHistoryPage() {
  const { customerOrSalon } = useParams();
  const pageRoute = `/dm/${customerOrSalon}/history`;

  const navigate = useNavigate();
  const queryConfig = useQueryConfig();

  const [searchHistories, setSearchHistories] = useState([]);
  const [searchHistoriesLoaded, setsearchHistoriesLoaded] = useState(false);
  // state manage the filtered list
  const [filteredSearchHistories, setFilteredSearchHistories] = useState([]);

  // handle filter by Distribution target, Delivery information
  // first filter
  const filterDistributeTargetSelectionData = useMemo(() => {
    if (customerOrSalon === "customer") {
      const responseMapData = searchHistories?.map((item) => {
        // get the customer name
        let customerName = "";
        if (item?.customers[0]) {
          customerName = getNameFromFirstLast(item?.customers[0]);
        }
        return {
          value: customerName,
          title: customerName,
        };
      });
      const result = [
        {
          value: "all",
          title: "all",
        },
        ...responseMapData,
      ];
      // remove dupplicate object.value
      const uniqueResult = result.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );
      return uniqueResult;
    } else if (customerOrSalon === "salon") {
      // salon
      const responseMapData = searchHistories?.map((item) => {
        // get the customer name
        let salonName = "";
        if (item?.salon[0]?.name) {
          salonName = item?.salon[0]?.name;
        }
        return {
          value: salonName,
          title: salonName,
        };
      });
      const result = [
        {
          value: "all",
          title: "all",
        },
        ...responseMapData,
      ];
      // remove dupplicate object.value
      const uniqueResult = result.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      );
      return uniqueResult;
    }
  }, [searchHistories, customerOrSalon]);

  // second filter
  const filterStatusSelectionData = useMemo(() => {
    const responseMapData = searchHistories?.map((item) => ({
      value: item.status,
      title: item.status,
    }));
    const result = [
      {
        value: "all",
        title: "all",
      },
      ...responseMapData,
    ];
    // remove dupplicate object.value
    const uniqueResult = result.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );
    return uniqueResult;
  }, [searchHistories, customerOrSalon]);

  // filter the list by the filterValue
  useEffect(() => {
    const targetFilter = [...searchHistories];
    if (searchHistories.length > 0 && customerOrSalon === "customer") {
      const filteredList = targetFilter?.filter((item) => {
        // name
        let customerName = "";
        if (item?.customers[0]) {
          customerName = getNameFromFirstLast(item?.customers[0]);
        }
        const condition1 = queryConfig.fromDate
          ? new Date(queryConfig.fromDate) <= new Date(item.presetTime)
          : true;
        const condition2 = queryConfig.toDate
          ? new Date(queryConfig.toDate) >= new Date(item.presetTime)
          : true;
        const condition3 = queryConfig.distributionTarget
          ? customerName === queryConfig.distributionTarget
          : true;
        const condition4 = queryConfig.deliveryInformation
          ? item.status === queryConfig.deliveryInformation
          : true;
        return condition1 && condition2 && condition3 && condition4;
      });
      setFilteredSearchHistories(filteredList);
    } else if (searchHistories.length > 0 && customerOrSalon === "salon") {
      const filteredList = targetFilter?.filter((item) => {
        // name
        let salonName = "";
        if (item?.salon[0]?.name) {
          salonName = item?.salon[0]?.name;
        }
        const condition1 = queryConfig.fromDate
          ? new Date(queryConfig.fromDate) <= new Date(item.presetTime)
          : true;
        const condition2 = queryConfig.toDate
          ? new Date(queryConfig.toDate) >= new Date(item.presetTime)
          : true;
        const condition3 = queryConfig.distributionTarget
          ? salonName === queryConfig.distributionTarget
          : true;
        const condition4 = queryConfig.deliveryInformation
          ? item.status === queryConfig.deliveryInformation
          : true;
        return condition1 && condition2 && condition3 && condition4;
      });
      setFilteredSearchHistories(filteredList);
    }
    // first Filter and the Second filter -> filter by 2
  }, [queryConfig, searchHistories, customerOrSalon]);

  // get dm histories
  useEffect(() => {
    if (customerOrSalon === "salon") {
      // call Api get dm-salon
      const handleGetAllDMSalonHistories = async () => {
        await DMHistories.getDMSalonHistoriesApi().then((data) => {
          const dmSalonHistoriesData = data.data.result;
          setSearchHistories(dmSalonHistoriesData);
          setsearchHistoriesLoaded(true);
        });
      };
      handleGetAllDMSalonHistories();
    } else if (customerOrSalon === "customer") {
      // call Api get dm-customer
      const handleGetAllDMCustomerHistories = async () => {
        await DMHistories.getDMCustomerApi().then((data) => {
          const dmCustomerHistoriesData = data.data.result;
          setSearchHistories(dmCustomerHistoriesData);
          setsearchHistoriesLoaded(true);
        });
      };
      handleGetAllDMCustomerHistories();
    }
  }, [customerOrSalon]);

  const onDetailClicked = (DMHistory) => {
    navigate(Path.dm.detail(DMHistory._id, customerOrSalon));
  };

  const handleFilterByDateRange = (value) => {
    const { fromDate, toDate } = value;
    navigate({
      pathname: pageRoute,
      search: createSearchParams(
        omitBy(
          {
            ...queryConfig,
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
          },
          isUndefined
        )
      ).toString(),
    });
  };

  const handleSelectDistributionTargetValue = (filterValue) => {
    navigate({
      pathname: pageRoute,
      search: createSearchParams(
        omitBy(
          {
            ...queryConfig,
            distributionTarget: filterValue === "all" ? undefined : filterValue,
          },
          isUndefined
        )
      ).toString(),
    });
  };

  const handleSelectDeliveryInformationValue = (filterValue) => {
    navigate({
      pathname: pageRoute,
      search: createSearchParams(
        omitBy(
          {
            ...queryConfig,
            deliveryInformation:
              filterValue === "all" ? undefined : filterValue,
          },
          isUndefined
        )
      ).toString(),
    });
  };

  const distributionTargetDefaultValue = useMemo(() => {
    if (queryConfig.distributionTarget) return queryConfig.distributionTarget;
    return filterDistributeTargetSelectionData[0].value;
  }, [filterDistributeTargetSelectionData, queryConfig]);

  const deliveryInformationDefaultValue = useMemo(() => {
    if (queryConfig.deliveryInformation) return queryConfig.deliveryInformation;
    return filterStatusSelectionData[0].value;
  }, [filterStatusSelectionData, queryConfig]);

  const dateRangeDefaultValue = useMemo(() => {
    let result = { fromDate: "", toDate: "" };
    if (queryConfig.fromDate || queryConfig.toDate) {
      result = {
        fromDate: queryConfig.fromDate ? queryConfig.fromDate : "",
        toDate: queryConfig.toDate ? queryConfig.toDate : "",
      };
    }
    return result;
  }, [queryConfig]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "配信履歴",
      }}
    >
      <DM.Table.Table items={searchHistories} loading={!searchHistoriesLoaded}>
        <DM.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </DM.Table.Loading>
        <DM.Table.Empty>配信履歴がないです。</DM.Table.Empty>
        <DM.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              <p>配信日時</p>
              <DateRangePicker
                valueFromUrl={dateRangeDefaultValue}
                onchange={handleFilterByDateRange}
              />
            </th>
            <th scope="col" className="flex flex-col gap-1 px-6 py-4">
              配信対象
              <AnalyseStatusSelection
                sx={{ maxWidth: "100%" }}
                title={"配信対象"}
                arrayData={filterDistributeTargetSelectionData}
                onchange={(filterValue) =>
                  handleSelectDistributionTargetValue(filterValue)
                }
                valueFromUrl={distributionTargetDefaultValue}
              />
            </th>
            <th scope="col" className="px-6 py-4">
              タイトル
            </th>
            <th scope="col" className="flex flex-col gap-1 px-6 py-4">
              配信情報
              <AnalyseStatusSelection
                title={"配信情報"}
                arrayData={filterStatusSelectionData}
                onchange={(filterValue) =>
                  handleSelectDeliveryInformationValue(filterValue)
                }
                valueFromUrl={deliveryInformationDefaultValue}
              />
            </th>
          </tr>
        </DM.Table.Header>
        <DM.Table.Items>
          {filteredSearchHistories.map((item) => (
            <DM.Table.Item
              key={item._id}
              onDetailClick={onDetailClicked}
              customerOrSalon={customerOrSalon}
            >
              {item}
            </DM.Table.Item>
          ))}
        </DM.Table.Items>
      </DM.Table.Table>
      <BackBtn url={Path.dm.home} />
    </DefaultLayout>
  );
}

export default DMHistoryPage;
