import homePage from "./homePage";
import detailPage from "./detailPage";
import createPage from "./Customer/createPage";
import historyPage from "./Customer/historyPage";

const exportObjs = {
  Home: homePage,
  Detail: detailPage,
  Customer: {
    Create: createPage,
    History: historyPage,
  },
  Salon: {
    Create: createPage,
  },
};

export default exportObjs;
