const encodePath = (url) => {
    if (!Boolean(url)) {
        return "";
    }

    const newURL = new URL(url);
    const path = url.replace(newURL.origin + "/", "");
    const pathEncode = encodeURIComponent(path);

    return newURL.origin + "/" + pathEncode;
};

export default encodePath;