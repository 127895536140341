import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Image from "app/components/Image"

function RecommendTableItem(props) {
  const { children, onDetailClick } = props;
  const recommend = children;

  const onDetailClicked = () => {
    onDetailClick(recommend);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4"><Image src={recommend?.imageUrl} alt={recommend?.name} /> </td>
      <td className="whitespace-nowrap px-6 py-4">{recommend?.name}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {recommend?.price}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{recommend?.category}</td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default RecommendTableItem;
