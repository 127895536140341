import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fake_history_order_list } from "../../../../services/api/fake_services/fake_data";
import Path from "../../../../route/Path";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import InputField from "../../../../components/commonUI/InputField";
import { getScalpDataByBatchingGroup, batchDeliverToLab } from "app/services/api";
//import NoData from "../../../../components/commonUI/NoData";
import TableBatchDelivery from "../TableBatchDelivery";
import TableScalpDataList from "./TableScalpDataList";
import SelectRegisterMethodModal from "./SelectRegisterMethodModal1";
import Api from "app/services/api";
import RegisterSuccessFailModal from "./RegisterSuccessFailModal";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function BatchReceiveDrcDetail() {
  const customerNo = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [fetchScalpDataByGroupDetail, setFetchScalpDataByGroupDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [scalpDataRegisteredList, setScalpDataRegisteredList] = useState([])
  const [scalpDataUnregisteredList, setScalpDataUnregisteredList] = useState([])
  const [openRegisterSuccessFailModal, setOpenRegisterSuccessFailModal] = React.useState(false);
  const [registerSuccessFailInfo, setRegisterSuccessFailInfo] = React.useState({
    title: "",
    content: "",
  });

  async function getScalpData(examinationDataId) {
    const response = await Api.examinationDatas.get(examinationDataId)
    .then((res) => {
      console.log("Ricky --res--", res)
    })
    .catch((e) => {
      //setIsSubmitting(false);
      alert(e);
    })
    .finally(() => {
      //setIsSubmitting(false);
    });
  }

  async function registerScalpData(scalpData, barCode) {
    let payload = {
      // analyseStatus: values.analyseStatus,
      // factorCellArea: Number(values.cellArea),
      // factorCluster: Number(values.cluster),
      // factorCP: Number(values.cp),
      customerNo: scalpData?.customerNo? scalpData?.customerNo: "-",
      inspectionKitId: scalpData?.inspectionKitId? scalpData?.inspectionKitId: "-",
      // factorScore: Number(values.overall),
      // factorPeeling: Number(values.peeling),
      // analyseDate: values.registerDate.toISOString(),
      // inspectionDate: examinationDataItem.inspectionDate,
      // name: examinationDataItem.name,
      scalpLabDeliverStatus: "done",
    };

    Api.examinationDatas
      .updateScalpLabDeliverStatus({
        examinationDataId: scalpData?._id? scalpData?._id: "",
        groupId: fetchScalpDataByGroupDetail?.data?._id,
        payload,
      })
      .then((res) => {
        // async function getAllExaminationDatas() {
        //   const response = await Api.examinationDatas.all();
        //   const items = response.data;
        //   setExaminationDatas(items);
        //   setExaminationDatasLoaded(true);
        // }

        // getAllExaminationDatas();
        // //gotoNextStep();
        // //setIsSubmitting(false);

        console.log("Ricky --.then.res--", res)

        let msgObject = { isSuccess: true }
        if (res.status == 200 && res.data.result) {
          msgObject.title = "登録しました"
          msgObject.content = "検体No: " + res.data.result.inspectionKitId + "<br />お客様名: " + res.data.result.customerName
        }

        setRegisterSuccessFailInfo(msgObject)
        setOpenRegisterSuccessFailModal(true)

        loadScalpDataByGroupDetail();
      })
      .catch((e) => {
        //setIsSubmitting(false);
        console.log(registerSuccessFailInfo)
        let msgObject = { isSuccess: false }
        console.log(e);
        if (e.response.data.message == "error.scalp.data.already.registered") {
          msgObject.title = "検体はすでに登録されています"
          msgObject.content = "検体No: " + barCode + "<br />"
        } else if (e.response.data.message == "error.scalp.data.not.in.the.group") {
          msgObject.title = "検体が見つかりません"
          msgObject.content = "検体No: " + barCode + "<br />"
        } else {
          msgObject.title = "予期せぬエラーが発生しました"
          msgObject.content = ""
        }

        console.log(msgObject)

        setRegisterSuccessFailInfo(msgObject)
        setOpenRegisterSuccessFailModal(true)
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  }

  const loadScalpDataByGroupDetail = () => {
    setFetchScalpDataByGroupDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getScalpDataByBatchingGroup(customerNo.group_id)
      .then((res) => {
        setFetchScalpDataByGroupDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });

        const dataUnregisteredList = res.data?.inspectionKitIds.filter((scalpData) => {
          if (scalpData.scalpLabDeliverStatus === "pending") return true
          return false;
        })
        const dataRegisteredList = res.data?.inspectionKitIds.filter((scalpData) => {
          if (scalpData.scalpLabDeliverStatus === "done") return true
          return false;
        })

        setScalpDataUnregisteredList(dataUnregisteredList)
        setScalpDataRegisteredList(dataRegisteredList)
      })
      .catch((error) => {
        setFetchScalpDataByGroupDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadScalpDataByGroupDetail();
  }, []);

  const handleOnCloseClicked = () => {
    setOpenModal(false)
  }

  const handleOnSendClicked = (code) => {
    console.log("das4d89a4d89as4d98a4d98a4--", code)
    if (!code) return;
    const scalpData = fetchScalpDataByGroupDetail.data?.inspectionKitIds?.find((scalpData)=>{
      if(scalpData.inspectionKitId == code) return true;

      return false;
    })
    registerScalpData(scalpData, code)

    // batchDeliverToLab(customerNo.group_id)
    //   .then((res) => {
    //     async function getAllExaminationDatas() {
    //       // const response = await Api.examinationDatas.all();
    //       // const items = response.data;
    //       // setExaminationDatas(items);
    //       // setExaminationDatasLoaded(true);
    //     }

    //     getAllExaminationDatas();
    //     //gotoNextStep();
    //     //setIsSubmitting(false);
    //     setOpenModal(false)
    //     alert("一括発送しました")
    //   })
    //   .catch((e) => {
    //     //setIsSubmitting(false);
    //     alert(e);
    //   })
    //   .finally(() => {
    //     //setIsSubmitting(false);
    //   });
  }

  console.log("Ricky --fetchScalpDataByGroupDetail.data--", fetchScalpDataByGroupDetail.data)
  console.log("Ricky --openModal--", openModal)
  const isDeliveryDisabled = fetchScalpDataByGroupDetail.data?.scalpLabDeliverStatus === "pending"? false : true
  console.log("Ricky --isDeliveryDisabled--", isDeliveryDisabled)

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ",
      }}
      showNotice={false}
    >
      <MainContentLayout
      pageTitle= {"検査"}
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
        <h2 className="text-base lg:text-xl font-semibold">{`  管理番号 : ${fetchScalpDataByGroupDetail.data?.manageCode}`}</h2>
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">{`頭皮検体登録`}</h2>
            <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-end absolute inset-x-0 top-0 ">
              <div
                className={`w-auto h-8 flex items-center justify-center gap-2 px-4`}>
                {"未登録数: " + scalpDataUnregisteredList?.length}
              </div>
              <Tooltip title={""}>
                <button
                  onClick={() => {
                    setOpenModal(true)
                  }}
                  type="button"
                  className={`w-auto h-8 flex items-center justify-center gap-2 px-4 rounded text-white bg-[#666FC8] mr-4 lg:mr-6`} 
                  //disabled={fetchScalpDataByGroupDetail.data?.scalpLabDeliverStatus != "None"}
                >
                  検体登録
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                <div>
                  {fetchScalpDataByGroupDetail.isLoading ? <LoadingProgress /> : null}

                  {!fetchScalpDataByGroupDetail.isLoading &&
                  fetchScalpDataByGroupDetail.error &&
                  fetchScalpDataByGroupDetail.data?.inspectionKitId ? (
                    <LoadInitError error={fetchScalpDataByGroupDetail.error} />
                  ) : null}
                  <div>
                    <div className="sticky top-0 left-0 w-full h-14 flex bg-red-100 border-b z-10 justify-center">
                      <div className="w-1/6 flex items-center justify-center shrink-0 truncate font-bold">
                        未登録
                      </div>
                    </div>
                  </div>
                  {!fetchScalpDataByGroupDetail.isLoading &&
                  !fetchScalpDataByGroupDetail.error ? (
                    Array.isArray(scalpDataUnregisteredList) &&
                    scalpDataUnregisteredList.length > 0 ? (
                      <TableScalpDataList
                        allHistoryOrdering={scalpDataUnregisteredList}
                      />
                    ) : (
                      // <NoData />
                      <div>データがないです</div>
                    )
                  ) : (
                    // <NoData />
                    <div>データがないです</div>
                  )}
                </div>
                <div>
                  {fetchScalpDataByGroupDetail.isLoading ? <LoadingProgress /> : null}

                  {!fetchScalpDataByGroupDetail.isLoading &&
                  fetchScalpDataByGroupDetail.error &&
                  fetchScalpDataByGroupDetail.data?.inspectionKitId ? (
                    <LoadInitError error={fetchScalpDataByGroupDetail.error} />
                  ) : null}
                  <div>
                    <div className="sticky top-0 left-0 w-full h-14 flex bg-blue-100 border-b z-10 justify-center">
                      <div className="w-1/6 flex items-center justify-center shrink-0 truncate font-bold">
                        登録済み
                      </div>
                    </div>
                  </div>
                  {!fetchScalpDataByGroupDetail.isLoading &&
                  !fetchScalpDataByGroupDetail.error ? (
                    Array.isArray(scalpDataRegisteredList) &&
                    scalpDataRegisteredList.length > 0 ? (
                      <TableScalpDataList
                        allHistoryOrdering={scalpDataRegisteredList}
                      />
                    ) : (
                      // <NoData />
                      <div>データがないです</div>
                    )
                  ) : (
                    // <NoData />
                    <div>データがないです</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <SelectRegisterMethodModal
            openDialog={openModal}
            handleOnCloseClicked={handleOnCloseClicked}
            content={"検体  " + fetchScalpDataByGroupDetail.data?.inspectionKitIds.length + "  個"}
            handleOnSendClicked={handleOnSendClicked}
          >
          </SelectRegisterMethodModal>

          <RegisterSuccessFailModal
            openDialog={openRegisterSuccessFailModal}
            //onCodeDetected={handleCodeDetected} 
            handleOnCloseClicked={()=>{
              setOpenRegisterSuccessFailModal(false);
            }}
            title={registerSuccessFailInfo.title} 
            content={registerSuccessFailInfo.content}
            isSuccess={registerSuccessFailInfo.isSuccess}>
          </RegisterSuccessFailModal>


          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="w-auto h-8 flex items-center justify-center gap-2 bg-[#666FC8] px-4 rounded text-white">
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default BatchReceiveDrcDetail;
