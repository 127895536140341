import React from "react";

function FormCol(props) {
  const { children, className, grow, ...otherProps } = props;

  return (
    <div
      className={`basis-1/4 ${grow ? "grow" : ""} ${className ?? ""}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default FormCol;
