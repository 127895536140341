import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function StaffCreateMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.systemSetting.employee.create(0, "staff"));
  };

  return (
    <MainMenuItem
      itemTitleString="スタッフを登録する"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default StaffCreateMenuItem;
