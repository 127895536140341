import React, { useRef, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import SegmentedControl from "app/components/commonUI/SegmentedControl";
import ProductGrid from "app/components/Product/ProductGrid";
import ProductGridItem from "app/components/Product/ProductGridItem";
// import MainMenuItem from "app/components/MainMenuItem";
// import CommonConfirmDialog from "app/components/CommonDialog";
// import Path from "app/route/Path";

function ExaminationResultUploadPage() {
  const navigate = useNavigate();

  // input file ref
  const inputTypeFileReference = useRef(null);

  const ButtonClicked = () => {
    inputTypeFileReference.current?.click();
  };

  // choose csv file method:
  const handleSelectCSVFile = async (event) => {
    const csvFileFromLocal = event.target.files?.[0];
    const formData = new FormData();
    formData.append("file", csvFileFromLocal);
    // call API:
    await Api.examinationDatas.importCSVFileApi(formData).then((data) => {
      if (data.status === 201) {
        const lineError = data.data.result.line_error;
        let afterImportMsg =
          "頭皮データ結果\n" +
          data.data.result.dataCount +
          "件登録成功しました\n";
        afterImportMsg +=
          lineError.length == 0 ? "" : lineError.length + "件登録失敗しました";

        alert(afterImportMsg);
        // const commonValue = lineError.length > 0 &&
      } else {
      }
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "頭皮データ新規登録",
      }}
    >
      <p>ファイルのアップロード</p>

      <button
        className="border border-black rounded-sm py-2 px-2 m-auto"
        onClick={ButtonClicked}
      >
        アップロード
      </button>
      <input
        type="file"
        className="hidden"
        ref={inputTypeFileReference}
        onChange={(event) => handleSelectCSVFile(event)}
        // handle bug: duplicate choose the same file -> the second does not work
        onClick={(event) => (event.target.value = null)}
      />
    </DefaultLayout>
  );
}

export default ExaminationResultUploadPage;
