import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import ProductOrder from "app/components/ProductOrder";
import BackBtn from "../../../../components/BackButton";

function ProductDeliveryListPage() {
  const navigate = useNavigate();

  const [productDeliveryHistories, setProductDeliveryHistories] = useState([]);
  const [productDeliveryHistoriesLoaded, setProductDeliveryHistoriesLoaded] =
    useState(false);

  useEffect(() => {
    async function getAllProductDeliveryHistories() {
      const response = await Api.labProductSalonOrder.afterDeliver();
      const items = response.data;
      setProductDeliveryHistories(items);
      setProductDeliveryHistoriesLoaded(true);
    }

    getAllProductDeliveryHistories();
  }, []);

  const onDeliverButtonClicked = (productDelivery) => {
    navigate(Path.product.delivery.create(0), {
      state: { productDeliveryItem: productDelivery },
    });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "商品発送履歴",
      }}
    >
      <ProductOrder.Delivery.Table.Table
        items={productDeliveryHistories}
        loading={!productDeliveryHistoriesLoaded}
      >
        <ProductOrder.Delivery.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </ProductOrder.Delivery.Table.Loading>
        <ProductOrder.Delivery.Table.Empty>
          発送履歴がないです。
        </ProductOrder.Delivery.Table.Empty>
        <ProductOrder.Delivery.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              発送日
            </th>
            <th scope="col" className="px-6 py-4">
              商品名
            </th>
            <th scope="col" className="px-6 py-4">
              店舗名
            </th>
            <th scope="col" className="px-6 py-4">
              数量
            </th>
            <th scope="col" className="px-6 py-4">
              備考
            </th>
          </tr>
        </ProductOrder.Delivery.Table.Header>
        <ProductOrder.Delivery.Table.Items>
          {productDeliveryHistories.map((item) => (
            <ProductOrder.Delivery.Table.HistoryItem
              key={item.id}
              onDeliverButtonClick={onDeliverButtonClicked}
            >
              {item}
            </ProductOrder.Delivery.Table.HistoryItem>
          ))}
        </ProductOrder.Delivery.Table.Items>
      </ProductOrder.Delivery.Table.Table>
      <BackBtn url={Path.product.home}/>
    </DefaultLayout>
  );
}

export default ProductDeliveryListPage;
