import React from "react";
import Button from "app/components/common/Button";

function PrimaryButton(props) {
  const { children, ...otherProps } = props;

  return (
    <Button variant="contained" color="primary" {...otherProps}>
      {children}
    </Button>
  );
}

export default PrimaryButton;
