import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contractor from "app/components/Contractor";

function ContractorDetailPage(props) {
  const { contractorId, contractorType } = useParams();

  const [contractorItem, setContractorItem] = useState({});
  const [contractorItemLoaded, setContractorItemLoaded] = useState(false);

  useEffect(() => {
    async function getContractorDetail(contractorId) {
      if (contractorType === "agency") {
        return await Api.contractors.get(contractorId)
          .then((res) => {
            setContractorItem(res.data);
            setContractorItemLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setContractorItemLoaded(true);
          });
      } else {
        return await Api.outsourcings.get(contractorId)
          .then((res) => {
            setContractorItem(res.data);
            setContractorItemLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setContractorItemLoaded(true);
          });
      }
    }

    getContractorDetail(contractorId);
  }, [contractorId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: contractorItem.companyName,
      }}
      showNotice={false}
    >
      {!contractorItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <Contractor.Detail>{contractorItem}</Contractor.Detail>
        </div>
      )}
    </DefaultLayout>
  );
}

export default ContractorDetailPage;
