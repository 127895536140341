import React from "react";
import { useRef, useMemo, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
// import SearchBox from "app/components/common/SearchBox";
import Employee from "app/components/SystemSetting/Employee";
import { useParams } from "react-router-dom";
import {
  SCALP_LAB_ACCOUNT_TEXT,
  SCALP_LAB_ACCOUNT_TYPE,
} from "../../constants";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AnalyseStatusSelection from "app/components/AnalyseStatusSelection";
import { SearchBox, SearchBoxNoSearchButton } from "./components";
import useQueryConfig from "./useQueryConfig";
import PaginationRounded from "app/components/common/PaginationRounded";
import { createSearchParams } from "react-router-dom";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import { useLocation, useSearchParams } from "react-router-dom";
import BackBtn from "../../components/BackButton";
import client from "app/services/api/client";
import isEqual from "lodash/isEqual";

function EmployeeListPage() {
  const { employeeType } = useParams();
  const pageRoute = `/system/setting/employee/${employeeType}/list`;
  const navigate = useNavigate();
  const queryConfig = useQueryConfig();
  const [searchParams] = useSearchParams();

  const initialQueryConfig = {
    page: "1",
    perPage: "10",
    sort: "1",
  };
  const emtyQueryConfig = {};

  // **
  const registerButtonClicked = () => {
    navigate(Path.systemSetting.employee.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };
  // **

  const [inputValues, setInputValues] = useState({
    staffNumber: searchParams?.get("staffNumber")
      ? searchParams?.get("staffNumber")
      : "",
    fullname: searchParams?.get("fullname")
      ? searchParams?.get("fullname")
      : "",
    staffTeam: searchParams?.get("staffTeam")
      ? searchParams?.get("staffTeam")
      : "",
  });
  const { staffNumber, fullname, staffTeam } = inputValues;

  const [searchEmployees, setSearchEmployees] = useState({});
  const [searchEmployeesLoaded, setSearchEmployeesLoaded] = useState(false);
  // filter list state
  const [filterList, setFilterList] = useState([]);

  const totalPage = useMemo(() => {
    if (searchEmployees?.result?.totalPages)
      return searchEmployees?.result?.totalPages;
  }, [searchEmployees]);
  const currentPage = useMemo(() => {
    if (searchEmployees?.result?.page) return searchEmployees?.result?.page;
  }, [searchEmployees]);

  const handleFilterByAccessPrivileges = useCallback(
    (accessPrivilegesValue) => {
      navigate({
        pathname: pageRoute,
        search: createSearchParams(
          omitBy(
            {
              ...queryConfig,
              page: "1",
              perPage: "10",
              accessLevel: accessPrivilegesValue !== "all" ? accessPrivilegesValue : undefined,
            },
            isUndefined
          )
        ).toString(),
      });
    },
    [queryConfig, navigate]
  );

  const accessPrivilegesDefaultValue = useMemo(() => {
    let result = "all";
    if (queryConfig.accessLevel) result = queryConfig.accessLevel;
    return result;
  }, [queryConfig]);

  const getAccountType = () => {
    return employeeType === SCALP_LAB_ACCOUNT_TEXT[1]
      ? SCALP_LAB_ACCOUNT_TYPE.MANAGER
      : SCALP_LAB_ACCOUNT_TYPE.STAFF;
  };

  // use effect get the filter list API
  useEffect(() => {
    async function getAllAccessLevelListData(accountType) {
      await Api.employees
        .getAllAccessLevel(accountType)
        .then((response) => {
          const accessLevelData = response.accessLevelList;
          setFilterList(accessLevelData)
        });
    };
    // call API
    if(employeeType === "manager"){
      getAllAccessLevelListData(1);
    } else {
      getAllAccessLevelListData(2);
    }
  }, [employeeType]);

  const filterSelectionData = useMemo(() => {
    const mapData = filterList?.map((item) => ({
      value: item,
      title: item,
    }));
    const result = [
      {
        value: "all",
        title: "all",
      },
      ...mapData,
    ];
    // remove dupplicate object.value
    const uniqueResult = result.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );
    return uniqueResult;
  }, [filterList]);

  useEffect(() => {
    async function getAllEmployees(queryConfig) {
      await Api.employees
        .all(getAccountType(), queryConfig)
        .then((response) => {
          setSearchEmployees(response);
          setSearchEmployeesLoaded(true);
        });
    }
    const queryParams = new URLSearchParams(window.location.search).toString();
    // call API method: queryConfig !== initial queryConfig || !queryParams (no query params on url)
    const isEqualInitialQueryConfig = isEqual(queryConfig, initialQueryConfig);
    const isEqualEmtyQueryConfig = isEqual(queryConfig, emtyQueryConfig);
    const haveNoAnySearchText = !staffNumber && !fullname && !staffTeam;
    if (!isEqualInitialQueryConfig && !isEqualEmtyQueryConfig) {
      getAllEmployees(queryConfig);
    } else if (!queryParams && isEqualInitialQueryConfig) {
      getAllEmployees(queryConfig);
    } else if (isEqualInitialQueryConfig && haveNoAnySearchText) getAllEmployees(queryConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryConfig, navigate]);

  // Wrong employee type from url , return anyway
  if (employeeType != "manager" && employeeType != "staff") return;

  var pageTitle = "一覧";
  var employeeTypeText = "";
  if (employeeType == "manager") employeeTypeText = "管理者";
  if (employeeType == "staff") employeeTypeText = "スタッフ";

  pageTitle = employeeTypeText + pageTitle;

  // wait for the pagination at back end
  const searchClicked = async () => {
    navigate({
      pathname: pageRoute,
      search: createSearchParams(
        omitBy(
          {
            ...queryConfig,
            staffNumber: staffNumber ? staffNumber : undefined,
            fullname: fullname ? fullname : undefined,
            staffTeam: staffTeam ? staffTeam : undefined,
          },
          isUndefined
        )
      ).toString(),
    });
  };

  const onDetailClicked = (employeeItem) => {
    navigate(Path.systemSetting.employee.detail(employeeItem.id));
  };

  const handleSortButton = (sortValue) => {
    navigate({
      pathname: pageRoute,
      search: createSearchParams({
        ...queryConfig,
        sort: sortValue,
      }).toString(),
    });
  };

  const handleChangeInputValues = (value, inputName) => {
    setInputValues((previousValue) => ({
      ...previousValue,
      [inputName]: value,
    }));
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <SearchBoxNoSearchButton
        placeholder="社員IDから探す"
        value={staffNumber}
        setValue={(value) => handleChangeInputValues(value, "staffNumber")}
      />
      <SearchBoxNoSearchButton
        placeholder="フルネームで検索"
        value={fullname}
        setValue={(value) => handleChangeInputValues(value, "fullname")}
      />
      <SearchBox
        placeholder="所属から探す"
        value={staffTeam}
        setValue={(value) => handleChangeInputValues(value, "staffTeam")}
        onSearch={searchClicked}
      />
      <Employee.Table.Table
        items={searchEmployees}
        loading={!searchEmployeesLoaded}
      >
        <Employee.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Employee.Table.Loading>
        <Employee.Table.Empty>
          {employeeTypeText}がないです。
        </Employee.Table.Empty>
        <Employee.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4 items-center text-center">
              社員番号
              <div className="mt-1 ml-1">
                <button
                  className="border border-green-800 rounded-md"
                  onClick={() => handleSortButton(1)}
                >
                  <ExpandLessIcon sx={{ margin: "auto" }} />
                </button>
                <button
                  className="border border-green-800 rounded-md"
                  onClick={() => handleSortButton(-1)}
                >
                  <ExpandMoreIcon sx={{ margin: "auto" }} />
                </button>
              </div>
            </th>
            <th scope="col" className="px-6 py-4">
              氏名
            </th>
            <th scope="col" className="px-6 py-4">
              所属
            </th>
            <th scope="col" className="px-6 py-4 flex flex-col items-center">
              アクセス権限
              <AnalyseStatusSelection
                title={"状態"}
                arrayData={filterSelectionData}
                onchange={handleFilterByAccessPrivileges}
                valueFromUrl={accessPrivilegesDefaultValue}
              />
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Employee.Table.Header>
        <Employee.Table.Items>
          {searchEmployees?.data?.map((item) => (
            <Employee.Table.Item key={item.id} onDetailClick={onDetailClicked}>
              {item}
            </Employee.Table.Item>
          ))}
        </Employee.Table.Items>
      </Employee.Table.Table>
      <PaginationRounded
        pageRoute={pageRoute}
        queryConfig={queryConfig}
        totalPage={totalPage}
        currentPage={currentPage}
      />
      <BackBtn url={Path.systemSetting.home} />
    </DefaultLayout>
  );
}

export default EmployeeListPage;
