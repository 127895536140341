import React from "react";
import Label from "app/components/common/Label";

function KitDeliveryHistoryTableItem(props) {
  const { children } = props;
  const kitDeliveryHistoryItem = children;

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4 font-medium">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {kitDeliveryHistoryItem.deliveryDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitDeliveryHistoryItem.salon.id}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitDeliveryHistoryItem.salon.name}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {kitDeliveryHistoryItem.amount}
      </td>
      <td className="whitespace-pre-wrap px-6 py-4">
        {kitDeliveryHistoryItem.memo}
      </td>
    </tr>
  );
}

export default KitDeliveryHistoryTableItem;
