import React, { useRef, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import SegmentedControl from "app/components/commonUI/SegmentedControl";
import ProductGrid from "app/components/Product/ProductGrid";
import ProductGridItem from "app/components/Product/ProductGridItem";
// import MainMenuItem from "app/components/MainMenuItem";
// import CommonConfirmDialog from "app/components/CommonDialog";
// import Path from "app/route/Path";

function Recommend() {
  const navigate = useNavigate();

  const ButtonClicked = () => {
    console.log("ButtonClicked22222");
    navigate(Path.salonDetailsBasics);
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店一覧",
      }}
    >
      <p>加盟店リスト</p>

      <button onClick={ButtonClicked}>詳細</button>
    </DefaultLayout>
  );
}

export default Recommend;
