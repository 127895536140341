import { formatAddress, getNameFromFirstLast } from "app/utils";
import client from "./client";
import clientFormData from "./clientFormData";
import store from "app/redux/store";

import {
  // examinationDatas,
  kit as fakeKit,
  recommends as fakeRecommends,
  DMHistories as fakeDMHistories,
  // contractors,
  employees as fakeEmployees,
  contracts as fakeContracts,
} from "./fake_services";

import { checkScalpLabMember, getCustomerInfo, updateCustomerInfo, deleteCustomer, getCustomerBasicInfo, updateCustomerBasicInfo, searchAllCustomer, getReservationInfo, getAllCategory, getListServiceCategory, getAllCounselingSheet, addCounselingSheet, editCounselingSheet, addNewCustomer, addNewReservation, getAllCustomerVisitHistory, getDetailCustomerVisitHistory } from "./fake_services";
import { SCALP_LAB_ACCOUNT_TEXT } from "../../constants";

export const login = (loginName, password) => {
  return client.post("/scalp-lab/login", { loginName, password });
};

export { checkScalpLabMember, getCustomerInfo, updateCustomerInfo, deleteCustomer, getCustomerBasicInfo, updateCustomerBasicInfo, searchAllCustomer, getReservationInfo, getAllCategory, getListServiceCategory, getAllCounselingSheet, addCounselingSheet, editCounselingSheet, addNewCustomer, addNewReservation, getAllCustomerVisitHistory, getDetailCustomerVisitHistory };

export const salons = {
  all: (queryParams) => {
    return client.get("/salon/paginate", { params: queryParams }).then((res) => {
      return {
        data: res?.data?.result?.rows?.map((item) => {
          return {
            ...item,
            id: item?._id,
            address: formatAddress(item),
            telephone: item?.phone,
          };
        }),
        result: res?.data?.result
      };
    });
  },
  allNoPaginate: () => {
    return client.get("/salon/all").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item?._id,
            address: formatAddress(item),
            telephone: item?.phone,
          };
        }),
      };
    });
  },
  search: (searchText) => {
    return client.get("/salon/search", { params: { searchText } }).then((res) => {
      const result = res?.data?.result.map((item) => ({
        ...item,
        id: item._id,
      }));

      return { data: result };
    });
  },
  get: (salonId) => {
    return client.get(`/salon/get?id=${salonId}`).then((res) => {
      const { phone: telephone, _id: id, menus = [], staffs: artists = [] } = res?.data?.result || {};
      return {
        data: {
          ...res?.data?.result,
          telephone,
          id,
          menus,
          artists,
        },
      };
    });
  },
  edit: ({ payload, salonId, callbackSuccess, callbackFailed }) => {
    return client
      .put(`/salon/update-with-scalp?id=${salonId}`, payload)
      .then((res) => {
        callbackSuccess && callbackSuccess(res);
        return res;
      })
      .catch((err) => {
        callbackFailed && callbackFailed(err);
        return err;
      });
  },
  create: ({ payload, callbackSuccess, callbackFailed }) => {
    return client
      .post("/salon/create-with-scalp", payload)
      .then((res) => {
        callbackSuccess && callbackSuccess(res);
        return res;
      })
      .catch((err) => {
        callbackFailed && callbackFailed(err);
        return err;
      });
  },

  delete: (salonId) => {
    return client.delete(`/salon/delete?id=${salonId}`).then((res) => {
      return res;
    });
  },
};

export const salonContact = {
  create: (payload) => {
    return client.post("/scalp-lap-contact/create-contact", payload);
  },
};

export const examinationDatas = {
  all: () => {
  // Ricky : need to merge new code from NWS
    return client.get("/scalp-data/all").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item?._id,
            cusId: item?.customerNo,
            kitId: item?.inspectionKitId,
            cluster: item?.factorCluster,
            cellArea: item?.factorCellArea,
            peeling: item?.factorPeeling,
            cp: item?.factorCP,
            overall: item?.factorScore,
            registerDate: item?.createdAt,
            analyseStatus: item?.analyseStatus,
          };
        }),
      };
    });
  },
  get: (examinationDataId) => {
    return client.get(`/scalp-data/get?id=${examinationDataId}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
          id: res?.data?.result?._id,
          cusId: res?.data?.result?.customerNo,
          kitId: res?.data?.result?.inspectionKitId,
          cluster: res?.data?.result?.factorCluster,
          cellArea: res?.data?.result?.factorCellArea,
          peeling: res?.data?.result?.factorPeeling,
          cp: res?.data?.result?.factorCP,
          overall: res?.data?.result?.factorScore,
          registerDate: res?.data?.result?.createdAt,
          analyseStatus: res?.data?.result?.analyseStatus,
          name: res?.data?.result?.customerName,
        },
      };
    });
  },
  delete: (examinationDataId) => {
    return client.delete(`/scalp-data/delete?id=${examinationDataId}`);
  },
  update: ({ examinationDataId, payload }) => {
    return client.put(`/scalp-data/update?id=${examinationDataId}`, payload);
  },
  store: (payload) => {
    return client.post(`/scalp-data/create-without-salon`, payload);
  },
  getScalpDeliverStatusNotNone: () => {
    return client
      .get("/scalp-data/scalp-deliver-status-not-none")
      .then((res) => {
        return {
          data: res?.data?.result?.map((item) => {
            return {
              ...item,
              id: item?._id,
              cusId: item?.customerNo,
              kitId: item?.inspectionKitId,
              cluster: item?.factorCluster,
              cellArea: item?.factorCellArea,
              peeling: item?.factorPeeling,
              cp: item?.factorCP,
              overall: item?.factorScore,
              registerDate: item?.createdAt,
              analyseStatus: item?.analyseStatus,
            };
          }),
        };
      });
  },

  getScalpDeliverStatusIsDone: () => {
    return client
      .get("/scalp-data/scalp-deliver-status-is-done")
      .then((res) => {
        return {
          data: res?.data?.result?.map((item) => {
            return {
              ...item,
              id: item?._id,
              cusId: item?.customerNo,
              kitId: item?.inspectionKitId,
              cluster: item?.factorCluster,
              cellArea: item?.factorCellArea,
              peeling: item?.factorPeeling,
              cp: item?.factorCP,
              overall: item?.factorScore,
              registerDate: item?.createdAt,
              analyseStatus: item?.analyseStatus,
            };
          }),
        };
      });
  },

  updateScalpLabDeliverStatus: ({ examinationDataId, groupId, payload }) => {
    return client.put(
      `/scalp-data/update-scalpLabDeliverStatus?id=${examinationDataId}&gid=${groupId}`,
      payload
    );
  },

  updateSalonDeliverStatus: ({ examinationDataId, groupId, payload }) => {
    return client.put(
      `/scalp-data/update-salonDeliverStatus?id=${examinationDataId}&gid=${groupId}`,
      payload
    );
  },

  updateAnalyseStatus: ({ examinationDataId, payload }) => {
    return client.put(
      `/scalp-data/update-analyseStatus?id=${examinationDataId}`,
      payload
    );
  },

  // export
  exportApi: () => client.get("scalp-data/export-file-csv"),

  // export
  drcExportApi: () => client.get("scalp-data/drc-export-file-csv"),
  // import CSVFile
  importCSVFileApi: (csvFileFromLocal) =>
    clientFormData.post("scalp-data/import-file-csv", csvFileFromLocal),

  search: (searchText, filter) => {
    return client
      .get("/scalp-data/search", { params: { searchText, filter } })
      .then((res) => {
        const result = res?.data?.result.map((item) => ({
          ...item,
          id: item?._id,
          cusId: item?.customerNo,
          kitId: item?.inspectionKitId,
          cluster: item?.factorCluster,
          cellArea: item?.factorCellArea,
          peeling: item?.factorPeeling,
          cp: item?.factorCP,
          overall: item?.factorScore,
          registerDate: item?.createdAt,
          analyseStatus: item?.analyseStatus,
        }));

        return { data: result };
      });
  },
};


export const kit = {
  ...fakeKit,
  order: {
    ...fakeKit.order,
    histories: {
      ...fakeKit.order.histories,
      all: () => {
        return client.get("/inspection-kit-scalp-lab-order/all").then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item._id,
                memo: item.memo || "",
                salonId: item.salonId || "",
              };
            }),
          };
        });
      },
    },
    requests: {
      ...fakeKit.order.requests,
      create: (data) => {
        return client
          .post("/inspection-kit-scalp-lab-order/create", {
            ...data,
            salonId: data.salonId || "",
            amount: +data.amount,
          })
          .then((res) => {
            return {
              data: res?.data,
            };
          });
      },
      notDeliver: () => {
        return client.get("/inspection-kit-delivery/not-delivery").then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item._id,
                salon: {
                  ...item.salon,
                  id: item.salonId,
                },
                deliveryStatus: item.status === "pending" ? "notdeliver" : item.status,
                orderDate: item.deliveryDate,
              };
            }),
          };
        });
      },
      get: (id) => {
        return client.get(`/inspection-kit-delivery/get?id=${id}`).then((res) => {
          return {
            data: {
              ...res?.data?.result,
              id: res?.data?.result?._id,
              salon: {
                ...res?.data?.result.salon,
                id: res?.data?.result?.salon._id,
              },
            },
          };
        });
      },
    },
    salon: {
      orderConfirm: (id, data) => {
        return client
          .put(`/inspection-kit-salon-order/order-confirm?id=${id}`, {
            orderAmount: +data.orderAmount,
            orderStatus: "confirmed"
          })
          .then((res) => {
            return {
              data: res?.data,
            };
          });
      },
    },
  },
  delivery: {
    ...fakeKit.delivery,
    histories: {
      ...fakeKit.delivery.histories,
      all: () => {
        return client.get("/inspection-kit-delivery/all").then((res) => {
          return {
            data: res?.data?.result
              ?.map((item) => {
                return {
                  ...item,
                  id: item?._id,
                  salon: {
                    ...item.salon,
                    id: item._id,
                  },
                };
              })
              .filter((item) => item.status === "done"),
          };
        });
      },
    },
    create: (data) => {
      delete data["selectedSalonItem"];
      data.amount = +data.amount;
      return client.post("/inspection-kit-delivery/create", data).then((res) => {
        return {
          data: res?.data,
        };
      });
    },
    edit: (id, data) => {
      delete data["selectedSalonItem"];
      data.amount = +data.amount;
      return client.put(`/inspection-kit-delivery/update-delivery?id=${id}`, data).then((res) => {
        return {
          data: res?.data,
        };
      });
    },
  },
  stock: {
    ...fakeKit.stock,
    create: (data) => {
      return client
        .post("/inspection-kit-stock/create", {
          instockDate: data.inStockDate,
          amount: +data.amount,
          memo: data.memo,
          // orderFrom: data.orderFrom || "",
        })
        .then((res) => {
          return {
            data: res?.data,
          };
        });
    },
    histories: {
      ...fakeKit.stock.histories,
      all: () => {
        return client.get("/inspection-kit-stock/all").then((res) => {
          let total = 0;
          return {
            data: res?.data?.result?.map((item) => {
              total += item.amount;
              return {
                ...item,
                id: item._id,
                inStockDate: item.instockDate,
                // orderFrom: item.orderFrom || "",
              };
            }),
            total,
          };
        });
      },
      get: (id) => {
        return client.get(`/inspection-kit-stock/get?id=${id}`).then((res) => {
          return {
            data: {
              ...res?.data?.result,
              inStockDate: res?.data?.result?.instockDate,
              // orderFrom: res?.data?.result?.orderFrom || "",
              id: res?.data?.result?._id,
            },
          };
        });
      },
      edit: (id, data) => {
        return client
          .put(`/inspection-kit-stock/update?id=${id}`, {
            instockDate: data.inStockDate,
            amount: +data.amount,
            memo: data.memo,
            // orderFrom: data.orderFrom || "",
          })
          .then((res) => {
            return {
              data: res?.data,
            };
          });
      },
    },
  },
};
export const recommends = {
  ...fakeRecommends,
  // get all product list/ get product list by name
  getProductListApi: (searchText) => {
    if (searchText) return client.get(`/product/all?name=${searchText}`);
    return client.get("/product/all");
  },
  // create product item
  createProductItemApi: (createProductItemBody) => client.post("/product/create", createProductItemBody),
  // get product item:
  getProductItemDetailApi: (productItemId) => client.get(`product/get?id=${productItemId}`),
  // delete product item
  deleteProductItemApi: (productItemId) => client.delete(`product/delete?id=${productItemId}`),
  // upload product image api
  uploadProductImageApi: (productImageFile) => {
    const token = store.getState()?.auth.token?.accessToken || "";
    if (token) {
      return clientFormData.post("upload-image", productImageFile);
    }
  },
  // update product item:
  updateProductItemDetailApi: (updateProductItemBody, productItemId) => client.put(`/product/update?id=${productItemId}`, updateProductItemBody),
  // export
  exportApi: () => client.get("product/export-file-csv"),
  // import CSVFile
  importCSVFileApi: (csvFileFromLocal) => clientFormData.post("product/import-file-csv", csvFileFromLocal),
};
// DMHistories apis
export const DMHistories = {
  ...fakeDMHistories,
  // scalp - lab / dm - salon
  getDMSalonHistoriesApi: () => client.get("scalp-lap/dm-salon/all"),
  getDMSalonHistoryDetail: (dmSalonDetailId) => client.get(`scalp-lap/dm-salon/get?id=${dmSalonDetailId}`),
  // scalp - lab / dm - customer
  getDMCustomerApi: () => client.get("scalp-lab-dm-customer/all"),
  getDMCustomerDetailApi: (dmCustomerDetailId) => client.get(`scalp-lab-dm-customer/get?id=${dmCustomerDetailId}`),
};

export const employees = {
  ...fakeEmployees,
  all: (accountType, queryConfig) => {
    return client
      .get(`/scalp-lap-staff/paginate?accountType=${accountType}`, {
        params: queryConfig,
      })
      .then((res) => {
        return {
          data: res?.data?.result?.rows?.map((item) => {
            return {
              ...item,
              id: item?._id,
              employeeType:
                SCALP_LAB_ACCOUNT_TEXT[item?.scalpLapAccount?.accountType],
              employeeNumber: item?.staffNumber,
              affiliation: item?.staffTeam,
              accessPrivilege: item?.accessLevel,
              employeeLastName: item?.lastNameKanji,
              employeeFirstName: item?.firstNameKanji,
              employeeLastNameFurigana: item?.lastNameKata,
              employeeFirstNameFurigana: item?.firstNameKata,
              birthday: item?.dob,
              phoneNumber: item?.phone,
              email: item?.email,
              address: item?.address,
            };
          }),
          result: res?.data?.result,
        };
      });
  },
  getAllAccessLevel: (accountType) =>
    client.get(`/scalp-lap-staff/get-access-level?accountType=${accountType}`).then((response) => {
      return {
        accessLevelList: response?.data?.result,
      };
    }),
  get: (employeeId) => {
    return client.get(`/scalp-lap-staff/get?id=${employeeId}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
          id: res?.data?.result._id,
          employeeType:
            SCALP_LAB_ACCOUNT_TEXT[
              res?.data?.result.scalpLapAccount.accountType
            ],
          employeeNumber: res?.data?.result.staffNumber,
          affiliation: res?.data?.result.staffTeam,
          accessPrivilege: res?.data?.result.accessLevel,
          employeeLastName: res?.data?.result.lastNameKanji,
          employeeFirstName: res?.data?.result.firstNameKanji,
          employeeLastNameFurigana: res?.data?.result.lastNameKata,
          employeeFirstNameFurigana: res?.data?.result.firstNameKata,
          birthday: res?.data?.result.dob,
          phoneNumber: res?.data?.result.phone,
          email: res?.data?.result.email,
          address: res?.data?.result.address,
        },
      };
    });
  },
  update: ({ employeeId, payload }) => {
    return client.put(`/scalp-lap-staff/update?id=${employeeId}`, payload);
  },
  store: (payload) => {
    return client.post(`/scalp-lap-staff/create`, payload);
  },
  delete: (employeeId) => {
    return client.delete(`/scalp-lap-staff/delete?id=${employeeId}`);
  },
};

const sales = {
  search: (startDate, endDate, salonId) => {
    return client.get(`/Sale/sale/search?salon_id=${salonId}&&startDate=${startDate}&&endDate=${endDate}`);
  },
};

const deposits = {
  list: (salonId) => {
    return client.get(`/salon-payment/all?salonId=${salonId}`).then((res) => {
      const list = res?.data?.result || {};
      return list;
    });
  },
  get: (id) => {
    return client.get(`/salon-payment/get?id=${id}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
        },
      };
    });
  },
  create: ({ payload, callbackSuccess, callbackFailed }) => {
    return client
      .post("/salon-payment/create", payload)
      .then((res) => {
        callbackSuccess && callbackSuccess();
        return {
          data: res?.data,
        };
      })
      .catch((err) => {
        callbackFailed && callbackFailed();
        return err;
      });
  },
  edit: ({ id, payload, callbackSuccess, callbackFailed }) => {
    return client
      .put(`/salon-payment/update?id=${id}`, payload)
      .then((res) => {
        callbackSuccess && callbackSuccess();
        return {
          data: res?.data,
        };
      })
      .catch((err) => {
        callbackFailed && callbackFailed();
        return err;
      });
  },
};

export const contracts = {
  ...fakeContracts,
  // Old without pagination
  all: () => {
    return client.get("/contract/all").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            contractStoreName: item?.salon?.name,
            createDate: item.contractDate,
            sendDate: item.deliverDate,
            replyValidExpiryDate: item.replyBeforeDate,
            effectiveDate: item.confirmDate,
            status: item.contractStatus,
          };
        }),
      };
    });
  },

  // New with pagination
  list: (query) => {
    const { salonName, sortDate, contractStatus, page, perPage } = query || {};
    return client
      .get("/contract/paginate", {
        params: {
          salonName,
          sort: sortDate,
          contractStatus,
          page,
          perPage,
        },
      })
      .then((res) => {
        return {
          data: res?.data?.result?.rows?.map((item) => {
            return {
              ...item,
              id: item._id,
              contractStoreName: item?.salon?.name,
              createDate: item.contractDate,
              sendDate: item.deliverDate,
              replyValidExpiryDate: item.replyBeforeDate,
              effectiveDate: item.confirmDate,
              status: item.contractStatus,
            };
          }),
          total: res?.data?.result?.total,
        };
      });
  },

  create: (data) => {
    delete data["id"];
    return client.post("/contract-dm/create", data).then((res) => {
      return {
        data: res?.data,
      };
    });
  },
};

export const articles = {
  create: (payload) => {
    return client.post("/salon/salon-article/create", payload);
  },
};

export const contractors = {
  store: (payload) => {
    return client.post("/agency/create", payload);
  },
  all: () => {
    return client.get("/agency/all").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            contractorType: "agency",
            companyName: item.companyName,
            companyNameFurigana: item.companyNameKata,
            leaderLastName: item.picLastName,
            leaderFirstName: item.picFirstName,
            phoneNumber: item.phone,
            email: item.email,
            address: item.address,
          };
        }),
      };
    });
  },
  get: (agencyId) => {
    return client.get(`/agency/get?id=${agencyId}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
          id: res?.data?.result._id,
          contractorType: "agency",
          companyName: res?.data?.result.companyName,
          companyNameFurigana: res?.data?.result.companyNameKata,
          leaderLastName: res?.data?.result.picLastName,
          leaderFirstName: res?.data?.result.picFirstName,
          phoneNumber: res?.data?.result.phone,
          email: res?.data?.result.email,
          address: res?.data?.result.address,
        },
      };
    });
  },
  update: ({ agencyId, payload }) => {
    return client.put(`/agency/update?id=${agencyId}`, payload);
  },
  delete: (agencyId) => {
    return client.delete(`/agency/delete?id=${agencyId}`);
  },
};

export const outsourcings = {
  store: (payload) => {
    return client.post("/subcontactor/create", payload);
  },
  all: () => {
    return client.get("/subcontactor/all").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            contractorType: "outsourcing",
            companyName: item.companyName,
            companyNameFurigana: item.companyNameKata,
            leaderLastName: item.picLastName,
            leaderFirstName: item.picFirstName,
            phoneNumber: item.phone,
            email: item.email,
            address: item.address,
          };
        }),
      };
    });
  },
  get: (agencyId) => {
    return client.get(`/subcontactor/get?id=${agencyId}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
          id: res?.data?.result._id,
          contractorType: "outsourcing",
          companyName: res?.data?.result.companyName,
          companyNameFurigana: res?.data?.result.companyNameKata,
          leaderLastName: res?.data?.result.picLastName,
          leaderFirstName: res?.data?.result.picFirstName,
          phoneNumber: res?.data?.result.phone,
          email: res?.data?.result.email,
          address: res?.data?.result.address,
        },
      };
    });
  },
  update: ({ agencyId, payload }) => {
    return client.put(`/subcontactor/update?id=${agencyId}`, payload);
  },
  delete: (agencyId) => {
    return client.delete(`/subcontactor/delete?id=${agencyId}`);
  },
};
export const customers = {
  all: () => {
    return client.get("/customer/customer/all-for-scalp").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            name: getNameFromFirstLast(item),
          };
        }),
      };
    });
  },
};

export const DMs = {
  create: (data, customerOrSalon) => {
    const sendData = {
      presetTime: data.deliveryDate,
      title: data.title,
      content: data.content,
      [customerOrSalon ? "targetSalons" : "customerIds"]: [data.selectedOption.id],
    };
    return client.post(customerOrSalon ? "/scalp-lap/dm-salon/create" : "/scalp-lab-dm-customer/create", sendData).then((res) => {
      return {
        data: res?.data,
      };
    });
  },
};

export const uploadImageApi = (productImageFile) => {
  const token = store.getState()?.auth.token?.accessToken || "";
  if (token) {
    return clientFormData.post("upload-image", productImageFile);
  }
};

export const images = {
  upload: (payload) => {
    return clientFormData.post("upload-image", payload);
  },
  uploads: (payload) => {
    return clientFormData.post("upload-multi-image", payload);
  },
};

export const getAllLabScalpDataBatchingGroup = () => {
  return client.get("/scalp-data-group/lab/get-all-scalp-data-group").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getAllLabReceivedScalpDataBatchingGroup = () => {
  return client.get("/scalp-data-group/lab/get-all-received-scalp-data-group").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getAllDrcScalpDataBatchingGroup = () => {
  return client.get("/scalp-data-group/drc/get-all-scalp-data-group").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const getScalpDataByBatchingGroup = (group_id) => {
  return client
    .get("/scalp-data-group/get-with-scalp-data-populated", {
      params: { id: group_id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const batchDeliverToDrc = (group_id) => {
  return client.put(
    `/scalp-data-group/batch-deliver-to-drc?id=${group_id}`,
    {}
  );
};

export const labProductSalonOrder = {
  all: () => {
    return client
      .get("/scalp-lab-product-salon-order/all")
      .then((res) => {
        return {
          data: res?.data?.result?.map((item) => {
            return {
              ...item,
              id: item?._id,
              name: item?.name,
              category: item?.category,
              price: item?.price,
              imageUrl: item?.imageUrl,
              reason: item?.reason,
            };
          }),
        };
      });
  },

  notDeliver: () => {
    return client.get("/scalp-lab-product-salon-order/not-delivery").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            salon: {
              ...item.salon,
              id: item.salonId,
            },
            deliveryStatus: item.status === "pending" ? "notdeliver" : item.status,
            orderDate: item.orderDate,
          };
        }),
      };
    });
  },

  afterDeliver: () => {
    return client.get("/scalp-lab-product-salon-order/after-delivery").then((res) => {
      return {
        data: res?.data?.result?.map((item) => {
          return {
            ...item,
            id: item._id,
            salon: {
              ...item.salon,
              id: item.salonId,
            },
            deliveryStatus: item.status === "pending" ? "notdeliver" : item.status,
            orderDate: item.orderDate,
          };
        }),
      };
    });
  },

  create: (input) => {
    return client
    .post(
      `/scalp-lab-product-salon-order/create`,
      input
    )
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
  },

  orderConfirm: (id, data) => {
    return client
      .put(`/scalp-lab-product-salon-order/order-confirm?id=${id}`, {
        orderAmount: +data.orderAmount,
        orderStatus: "confirmed"
      })
      .then((res) => {
        return {
          data: res?.data,
        };
      });
  },

  orderSendDelivery: (id, data) => {
    return client
      .put(`/scalp-lab-product-salon-order/order-send-delivery?id=${id}`, {
        orderAmount: +data.orderAmount,
        orderStatus: "delivering",
        memo: data.memo,
        deliveryDate: data.deliveryDate
      })
      .then((res) => {
        return {
          data: res?.data,
        };
      });
  },

  get: (id) => {
    return client.get(`/scalp-lab-product-salon-order/get?id=${id}`).then((res) => {
      return {
        data: {
          ...res?.data?.result,
          id: res?.data?.result?._id,
          salon: {
            ...res?.data?.result.salon,
            id: res?.data?.result?.salon._id,
          },
        },
      };
    });
  },

}

const exportObjs = {
  examinationDatas,
  kit,
  sales,
  deposits,
  salons,
  recommends,
  DMHistories,
  contractors,
  employees,
  contracts,
  salonContact,
  outsourcings,
  customers,
  DMs,
  uploadImageApi,
  labProductSalonOrder
};

export default exportObjs;
