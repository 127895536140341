import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Drc from "app/components/Drc";

function ExaminationResultEditPage(props) {
  const { examinationDataId, stepId } = useParams();

  const [examinationDataItem, setExaminationDataItem] = useState({});
  const [examinationDataItemLoaded, setExaminationDataItemLoaded] =
    useState(false);

  useEffect(() => {
    async function getDepositItem(examinationDataId) {
      const response = await Api.examinationDatas.get(examinationDataId);
      const item = response.data;
      setExaminationDataItem(item);
      setExaminationDataItemLoaded(true);
    }

    getDepositItem(examinationDataId);
  }, [examinationDataId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ編集",
      }}
      showNotice={false}
    >
      {!examinationDataItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Drc.ExaminationResult.Form
          isEdit={true}
          stepId={+stepId}
          examinationDataItem={examinationDataItem}
        >
          {examinationDataItem}
        </Drc.ExaminationResult.Form>
      )}
    </DefaultLayout>
  );
}

export default ExaminationResultEditPage;
