import React from "react";
import Form from "app/components/common/Form";
import { useRef } from "react";
import { useState } from "react";
import GoogleMapComponent from "../GoogleMap";
import Button from "app/components/common/Button";
import Modal from "app/components/common/Modal";

const DEFAULT_LOCATION = {
  lng: 139.7692938,
  lat: 35.6801482,
};

function SalonArtistForm(props) {
  const { values } = props;
  const mapRef = useRef();
  const hadInitialLongLat = values?.longitude && values?.latitude;
  const INITIAL_LONG_LAT = {
    lat: parseFloat(values?.latitude),
    lng: parseFloat(values?.longitude),
  };
  const [geoCode, setGeoCode] = useState(hadInitialLongLat ? INITIAL_LONG_LAT : DEFAULT_LOCATION);
  const [center, setCenter] = useState(hadInitialLongLat ? INITIAL_LONG_LAT : DEFAULT_LOCATION);
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);

  const resetGeo = () => {
    setGeoCode(INITIAL_LONG_LAT || DEFAULT_LOCATION);
    setCenter(INITIAL_LONG_LAT || DEFAULT_LOCATION);
  };

  return (
    <div className="space-y-2">
      <Modal
        show={showSelectLocationModal}
        setShow={(val) => {
          setShowSelectLocationModal(val);
          resetGeo();
        }}
        staticBackdrop={true}
        title="地図で設定する"
        button2={
          <Button
            onClick={() => {
              setShowSelectLocationModal(false);
              resetGeo();
            }}>
            閉じる
          </Button>
        }>
        <div key={showSelectLocationModal} className="w-[400px] h-[300px]">
          <GoogleMapComponent mapRef={mapRef} geoCode={geoCode} setGeoCode={setGeoCode} center={center} setCenter={setCenter} />
        </div>
      </Modal>
      <Form.Row>
        <Form.Col>店舗名</Form.Col>
        <Form.ColGrow>{values?.name}</Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>店舗名（フリガナ）</Form.Col>
        <Form.ColGrow>{values?.nameKana}</Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>代表者</Form.Col>
        <Form.ColGrow>
          <Form.Row>
            <Form.Col className="basis-1 pr-2">姓</Form.Col>
            <Form.ColGrow>{values?.picLastName}</Form.ColGrow>
            <Form.Col className="basis-1 pr-2">名</Form.Col>
            <Form.ColGrow>{values?.picFirstName}</Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>電話番号</Form.Col>
        <Form.ColGrow>{values?.telephone}</Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>メールアドレス</Form.Col>
        <Form.ColGrow>{values?.email}</Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>住所</Form.Col>
        <Form.ColGrow>
          <Form.Row>
            <Form.Col>{values?.postalCode}</Form.Col>
          </Form.Row>
          <Form.Row>
            <Form.Col>{values?.address}</Form.Col>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>地図設定</Form.Col>
        <Form.ColGrow>
          <Form.Row>
            <Form.Col>経度</Form.Col>
            <Form.ColGrow>{values?.latitude}</Form.ColGrow>
          </Form.Row>
          <Form.Row>
            <Form.Col>緯度</Form.Col>
            <Form.ColGrow>{values?.longitude}</Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
        <Form.ColGrow>
          <Button.Secondary
            onClick={() => {
              setShowSelectLocationModal(true);
            }}>
            地図を見る
          </Button.Secondary>
        </Form.ColGrow>
      </Form.Row>
    </div>
  );
}
export default SalonArtistForm;
