import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainMenuGrid from "app/components/MainMenuGrid";
import MainMenuItem from "app/components/MainMenuItem";
import BackBtn from "../../../components/BackButton";

function DepositSalonHomePage() {
  const { salonId } = useParams();

  const [salon, setSalon] = useState({});

  useEffect(() => {
    async function getSalonById(salonId) {
      const response = await Api.salons.get(salonId);
      const items = response.data;
      setSalon(items);
    }

    getSalonById(salonId);
  }, [salonId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: salon.name ?? "ローティング中",
      }}
    >
      <MainMenuGrid>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Deposit.Sales salonId={salonId} />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Deposit.Manage salonId={salonId} />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Deposit.Invoice salonId={salonId} />
        </div>
        <div className="flex items-center justify-center aspect-square w-full h-full p-[10%]">
          <MainMenuItem.Salon.Deposit.Contact salonId={salonId} />
        </div>
      </MainMenuGrid>
      <BackBtn url={Path.salon.deposit.list}/>
    </DefaultLayout>
  );
}

export default DepositSalonHomePage;
