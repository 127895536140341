import React from "react";

function FormRow(props) {
  const { children, className, ...otherProps } = props;

  return (
    <div
      className={`flex flex-col xs:flex-row ${className ?? ""}`}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default FormRow;
