import Table from "./Table";
import TableEmpty from "./TableEmpty";
import TableHeader from "./TableHeader";
import TableItems from "./TableItems";
import TableLoading from "./TableLoading";

const exportObjs = {
  Table: Table,
  Empty: TableEmpty,
  Header: TableHeader,
  Items: TableItems,
  Loading: TableLoading,
};

export default exportObjs;
