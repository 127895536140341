import React from "react";

function TableHeader(props) {
  const { children, ...otherProps } = props;

  return (
    <thead
      className="border-b font-medium dark:border-neutral-500"
      {...otherProps}
    >
      {children}
    </thead>
  );
}

export default TableHeader;
