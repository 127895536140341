import homePage from "./homePage";
import createPage from "./createPage";
import listPage from "./listPage";
import detailPage from "./detailPage";
import editPage from "./editPage";

const exportObjs = {
  Home: homePage,
  Create: createPage,
  List: listPage,
  Detail: detailPage,
  Edit: editPage,
};

export default exportObjs;
