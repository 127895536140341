import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Api from "app/services/api";
import Path from "app/route/Path";
import Button from "app/components/common/Button";
import Salon from "app/components/Salon";
import BackBtn from "../../../components/BackButton";

export const DEPOSIT_STATUS = {
  PENDING:"pending",
  DONE:"done"
}

function DepositManagementPage() {
  const { salonId } = useParams();
  const navigate = useNavigate();

  const [salonDepositsPending, setSalonDepositsPending] = useState([]);
  const [salonDepositsPendingLoaded, setSalonDepositsPendingLoaded] =
    useState(false);
  const [salonDepositsDone, setSalonDepositsDone] = useState([]);
  const [salonDepositsDoneLoaded, setSalonDepositsDoneLoaded] = useState(false);

  useEffect(() => {
    async function getSalonDeposits() {
      const list = await Api.deposits.list(salonId);
      const pendingList = list?.filter(({ status }) => {
        return status === DEPOSIT_STATUS.PENDING || !status;
      });
      const doneList = list?.filter(({ status }) => {
        return status === DEPOSIT_STATUS.DONE;
      });
      setSalonDepositsDone(doneList);
      setSalonDepositsPending(pendingList);
      setSalonDepositsDoneLoaded(true);
      setSalonDepositsPendingLoaded(true);
    }

    getSalonDeposits();
  }, [salonId]);

  const onDepositFulfillClick = (salonDepositItem) => {
    navigate(
      Path.salon.deposit.fulfill(
        salonDepositItem.salonId,
        salonDepositItem._id,
        0
      )
    );
  };

  const onDepositCreateClick = () => {
    navigate(Path.salon.deposit.create(salonId, 0));
  };

  const onDepositEditClicked = (salonDepositItem) => {
    navigate(
      Path.salon.deposit.edit(salonDepositItem.salonId, salonDepositItem._id, 0)
    );
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "入金登録・履歴",
      }}
      showNotice={false}
    >
      <p>入金予定</p>
      <Salon.Deposit.Table.Table
        items={salonDepositsPending}
        loading={!salonDepositsPendingLoaded}
      >
        <Salon.Deposit.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Salon.Deposit.Table.Loading>
        <Salon.Deposit.Table.Empty>
          入金予定がないです。
        </Salon.Deposit.Table.Empty>
        <Salon.Deposit.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              支払期限
            </th>
            <th scope="col" className="px-6 py-4">
              請求番号
            </th>
            <th scope="col" className="px-6 py-4">
              件名
            </th>
            <th scope="col" className="px-6 py-4">
              入金予定額
            </th>
            <th scope="col" className="px-6 py-4">
              ステータス
            </th>
          </tr>
        </Salon.Deposit.Table.Header>
        <Salon.Deposit.Table.Items>
          {salonDepositsPending.map((item) => (
            <Salon.Deposit.Table.PendingItem
              key={item.id}
              onActionClick={onDepositFulfillClick}
            >
              {item}
            </Salon.Deposit.Table.PendingItem>
          ))}
        </Salon.Deposit.Table.Items>
      </Salon.Deposit.Table.Table>
      <p>入金一覧</p>
      <Button.Primary onClick={onDepositCreateClick}>入金登録</Button.Primary>
      <Salon.Deposit.Table.Table
        items={salonDepositsDone}
        loading={!salonDepositsDoneLoaded}
      >
        <Salon.Deposit.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Salon.Deposit.Table.Loading>
        <Salon.Deposit.Table.Empty>
          入金予定がないです。
        </Salon.Deposit.Table.Empty>
        <Salon.Deposit.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              入金日
            </th>
            <th scope="col" className="px-6 py-4">
              番号
            </th>
            <th scope="col" className="px-6 py-4">
              件名
            </th>
            <th scope="col" className="px-6 py-4">
              入金口座{" "}
            </th>
            <th scope="col" className="px-6 py-4">
              入金額
            </th>
            <th scope="col" className="px-6 py-4">
              備考
            </th>
            <th scope="col" className="px-6 py-4">
              修正
            </th>
          </tr>
        </Salon.Deposit.Table.Header>
        <Salon.Deposit.Table.Items>
          {salonDepositsDone.map((item) => (
            <Salon.Deposit.Table.DoneItem
              key={item.id}
              onActionClick={onDepositEditClicked}
            >
              {item}
            </Salon.Deposit.Table.DoneItem>
          ))}
        </Salon.Deposit.Table.Items>
      </Salon.Deposit.Table.Table>
      <BackBtn url={Path.salon.deposit.home(salonId)}/>
    </DefaultLayout>
  );
}

export default DepositManagementPage;
