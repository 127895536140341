import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import SearchBox from "app/components/common/SearchBox";
import Recommend from "app/components/Recommend";
import {recommends} from "app/services/api";
import { CSVLink, CSVDownload } from "react-csv";
import BackBtn from "../../components/BackButton";

function RecommendListPage() {
  const navigate = useNavigate();

  // **
  const registerButtonClicked = () => {
    navigate(Path.recommend.create(0));
  };
  const articleregisterButtonClicked = () => {
    navigate(Path.itemImport);
  };
  // **

  const [searchText, setSearchText] = useState("");
  const [searchRecommends, setSearchRecommends] = useState([]);
  const [searchRecommendsLoaded, setSearchRecommendsLoaded] = useState(false);

  useEffect(() => {
    const handleGetProductList = async () => {
      await recommends.getProductListApi().then((data) => {
        const productList = data.data.result
        setSearchRecommends(productList)
        setSearchRecommendsLoaded(true);
      })
    }
    handleGetProductList()
  }, []);

  const searchClicked = async (evt) => {
    await recommends.getProductListApi(searchText).then((data) => {
      const productList = data.data.result;
      setSearchRecommends(productList);
      setSearchRecommendsLoaded(true);
    });
  };

  const onDetailClicked = (recommendItem) => {
    navigate(Path.recommend.detail(recommendItem._id));
  };

  const exportButtonClicked = async () => {
    await recommends.exportApi().then((data) => {
      const csvDataFromServer = data.data;
      const blob = new Blob([csvDataFromServer], { type: "text/csv" });
      const csvURL = URL.createObjectURL(blob);
      const fileName = "csv-file";
      // create a element to get download
      const link = document.createElement("a");
      // set a element properties: href, download
      link.href = csvURL;
      link.setAttribute("download", `${fileName}.csv`);
      // add a element to DOM
      document.body.appendChild(link);
      link.click();
      // remove a element from DOM after finish
      document.body.removeChild(link);
    });
  };

  const importButtonClicked = () => {
    alert("エクスポート機能実装予定");
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "商品一覧",
      }}
      showNotice={false}
    >
      <Button.Primary onClick={registerButtonClicked}>新規登録</Button.Primary>
      <Button.Secondary onClick={articleregisterButtonClicked}>
        インポート
      </Button.Secondary>
      <Button.Secondary onClick={exportButtonClicked}>
        エクスポート
      </Button.Secondary>
      <SearchBox
        placeholder="商品を探す"
        value={searchText}
        setValue={setSearchText}
        onSearch={searchClicked}
      />
      <Recommend.Table.Table
        items={searchRecommends}
        loading={!searchRecommendsLoaded}
      >
        <Recommend.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </Recommend.Table.Loading>
        <Recommend.Table.Empty>商品がないです。</Recommend.Table.Empty>
        <Recommend.Table.Header>
          <tr>
            <th scope="col" className="px-6 py-4">
              商品写真
            </th>
            <th scope="col" className="px-6 py-4">
              商品名 (ソート機能実装待ち)
            </th>
            <th scope="col" className="px-6 py-4">
              販売価格 (ソート機能実装待ち)
            </th>
            <th scope="col" className="px-6 py-4">
              対応カテゴリ　(絞り込み機能実装待ち)
            </th>
            <th scope="col" className="px-6 py-4">
              選択
            </th>
          </tr>
        </Recommend.Table.Header>
        <Recommend.Table.Items>
          {searchRecommends.map((item) => (
            <Recommend.Table.Item
              key={item._id}
              onDetailClick={onDetailClicked}
            >
              {item}
            </Recommend.Table.Item>
          ))}
        </Recommend.Table.Items>
      </Recommend.Table.Table>
      <BackBtn url={Path.home}/>
    </DefaultLayout>
  );
}

export default RecommendListPage;
