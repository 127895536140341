import defaultThumpnail from "app/assets/images/default-thumbnail.jpg";
import { useEffect, useState } from "react";

export default function Image({ src, alt }) {
  const [errorImage, setErrorImage] = useState();

  // if user try to send the new src, clear the error state and then view the new src
  useEffect(() => {
    if(src) setErrorImage()
  }, [src])

  const handleSetErrorImage = () => setErrorImage(defaultThumpnail);
  
  return (
    <div className="w-[150px] h-[150px] mb-1">
      <img
        src={errorImage || src || defaultThumpnail}
        alt={alt}
        className="w-full h-full object-contain"
        onError={handleSetErrorImage}
      />
    </div>
  );
}
