import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api, { examinationDatas } from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import BackBtn from "../BackButton";

function ExaminationDataDetail(props) {
  const { children } = props;
  const examinationDataItem = children;

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async (evt) => {
    setIsSubmitting(true);
    const response = await Api.examinationDatas.delete(examinationDataItem.id);
    if (response.status === 200) {
      setShowDeleteReconfirmModal(false);
      setShowCompleteModal(true);
      setIsSubmitting(false);
    } else {
      // TODO: Error handling
      setIsSubmitting(false);
      alert("Error");
    }
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.examination.list);
  };

  const editClicked = () => {
    navigate(Path.examination.edit(examinationDataItem.id, 0));
  };

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title="この検査データを削除しますか？"
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            キャンセルを確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title="（最終確認）この検査データを削除しますか？"
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            キャンセルを確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title="この検査データを削除しました。"
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p className="font-bold">cusId: {examinationDataItem.cusId}</p>
            <p>kitId: {examinationDataItem.kitId}</p>

            {examinationDataItem.analyseStatus === "analyse_completed" ? (
              <>
                <p>cluster: {examinationDataItem.cluster}</p>
                <p>cellArea: {examinationDataItem.cellArea}</p>
                <p>peeling: {examinationDataItem.peeling}</p>
                <p>cp: {examinationDataItem.cp}</p>
                <p>overall: {examinationDataItem.overall}</p>
                <div>
                  registerDate:
                  <Label.DateTime dateFormat="YYYY-MM-DD">
                    {examinationDataItem.registerDate}
                  </Label.DateTime>
                </div>
                <p>グラフ未実装</p>
              </>
            ) : (
              <>
                <p>頭皮データを分析中です。分析終了後、結果が表示されます。</p>
              </>
            )}
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              この検査データを削除する
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              この検査データを編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row>
        <BackBtn url={Path.examination.list}/>
      </Layout.Container>
    </>
  );
}

export default ExaminationDataDetail;
