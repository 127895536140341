import EmployeeTableItem from "./EmployeeTableItem";
import EmployeeForm from "./EmployeeForm";
import EmployeeDetail from "./EmployeeDetail";
import EmployeeGridItem from "./EmployeeGridItem";
import Grid from "app/components/common/Grid";
import MainMenuGrid from "app/components/MainMenuGrid";

import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: MainMenuGrid,
  Grid: {
    ...Grid,
    Item: EmployeeGridItem,
  },
  Table: {
    ...Table,
    Item: EmployeeTableItem,
  },
  Form: EmployeeForm,
  Detail: EmployeeDetail,
};

export default exportObjs;
