import homePage from "./homePage";
import statusPage from "./statusPage";
import contactPage from "./contactPage";

const exportObjs = {
  Home: homePage,
  Status: statusPage,
  Contact: contactPage,
  // Detail: detailPage,
  // Edit: editPage,
};

export default exportObjs;
