import React from "react";

function Breadcrumb(props) {
  const { pageTitle } = props;

  return (
    <div className="px-6 py-1 flex page-title-container">
      <div className="grow flex justify-center">{pageTitle}</div>
    </div>
  );
}

export default Breadcrumb;
