export const formatAddress = (obj) => {
  const {
    postalCode = "",
    prefecture = "",
    city = "",
    street = "",
    building = "",
  } = obj || {};
  return [
    postalCode && "〒" + postalCode,
    prefecture,
    city,
    street,
    building,
  ]?.join(" ");
};

export const getTruthyImageFileType = (fileType) => fileType?.includes("image");

// encode image file name url
export const getRightUrl = (imageURL) => {
  const match = imageURL.match(/\/([^/]+)$/);
  if (match) {
    const rightmostPart = match[1];
    return rightmostPart;
  } else {
    return null;
  }
}
export const getLeftUrl = (imageUrl) => {
    const match = imageUrl.match(/^(.*?)(?:\/[^/]+)?$/);
    if (match) {
      const leftPart = match[1];
      return leftPart;
    } else {
      return null;
    }
}

export const getNameFromFirstLast = (obj) => {
  const { lastNameKata, firstNameKata, lastNameKanji, firstNameKanji } = obj;
  return `${lastNameKata} ${firstNameKata}`;
};

export const getStringAnalyseStatus = (valueFromServer) => {
  let result = ""
  if(valueFromServer) result = valueFromServer.replace(/^analyse_/, "").replace(/_/, " ");
  return result;
};
