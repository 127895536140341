// const customerManagementPrefix = "/customer-management";

const routePaths = {
  home: "/",
  login: "/login",
  examination: {
    list: "/examination/list",
    batchReceiveLab: "/examination/batch-receiveLab",
    batchDeliverDrc: "/examination/batch-deliver",
    batchReceiveLabDetail: (group_id) =>
    group_id
      ? `/scalp-lab-management/batch-receiveLab-detail/${group_id}/detail`
      : `/scalp-lab-management/batch-receiveLab-detail/:group_id/detail`,
    batchDeliverDrcDetail: (group_id) =>
    group_id
      ? `/scalp-lab-management/batch-deliverDrc/${group_id}/detail`
      : `/scalp-lab-management/batch-deliverDrc/:group_id/detail`,
    detail: (examinationDataId) =>
      examinationDataId
        ? `/examination/detail/${examinationDataId}`
        : `/examination/detail/:examinationDataId`,
    create: (stepId) => {
      if (stepId === undefined || stepId === null) {
        return `/examination/create/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/examination/create/${stepId}`;
      }

      return `/examination/create/0`;
    },
    edit: (examinationDataId, stepId) => {
      if (stepId === undefined || stepId === null) {
        return `/examination/edit/:examinationDataId/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/examination/edit/${examinationDataId}/${stepId}`;
      }

      return `/examination/edit/:examinationDataId/0`;
    },
  },
  kit: {
    home: "/kit/home",
    order: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/kit/order/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/kit/order/create/${stepId}`;
        }

        return `/kit/order/create/0`;
      },
      history: "/kit/order/history",
    },
    request: {
      list: "/kit/request/list",
    },
    delivery: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/kit/delivery/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/kit/delivery/create/${stepId}`;
        }

        return `/kit/delivery/create/0`;
      },
      fulfill: (kitRequestId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/kit/delivery/fulfill/:kitRequestId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/kit/delivery/fulfill/${kitRequestId}/${stepId}`;
        }

        return `/kit/delivery/fulfill/:kitRequestId/0`;
      },
      list: "/kit/delivery/list",
    },
    stock: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/kit/stock/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/kit/stock/create/${stepId}`;
        }

        return `/kit/stock/create/0`;
      },
      list: "/kit/stock/list",
      edit: (kitStockHistoryId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/kit/stock/edit/:kitStockHistoryId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/kit/stock/edit/${kitStockHistoryId}/${stepId}`;
        }

        return `/kit/stock/edit/:kitStockHistoryId/0`;
      },
    },
  },
  salon: {
    deposit: {
      list: "/salon/deposit/list",
      home: (salonId) =>
        salonId
          ? `/salon/deposit/home/${salonId}`
          : `/salon/deposit/home/:salonId`,
      sales: (salonId) =>
        salonId
          ? `/salon/deposit/sales/${salonId}`
          : `/salon/deposit/sales/:salonId`,
      contact: (salonId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/deposit/contact/:salonId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/deposit/contact/${salonId}/${stepId}`;
        }

        return `/salon/deposit/contact/:salonId/0`;
      },
      management: (salonId) =>
        salonId
          ? `/salon/deposit/manage/${salonId}`
          : `/salon/deposit/manage/:salonId`,
      edit: (salonId, invoiceNumber, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/deposit/edit/:salonId/:invoiceNumber/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/deposit/edit/${salonId}/${invoiceNumber}/${stepId}`;
        }

        return `/salon/deposit/edit/:salonId/:invoiceNumber/0`;
      },
      fulfill: (salonId, invoiceNumber, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/deposit/fulfill/:salonId/:invoiceNumber/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/deposit/fulfill/${salonId}/${invoiceNumber}/${stepId}`;
        }

        return `/salon/deposit/fulfill/:salonId/:invoiceNumber/0`;
      },
      create: (salonId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/deposit/create/:salonId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/deposit/create/${salonId}/${stepId}`;
        }

        return `/salon/deposit/create/:salonId/0`;
      },
    },
    manage: {
      home: "/salon/manage/home",
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/manage/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 6) {
          return `/salon/manage/create/${stepId}`;
        }

        return `/salon/manage/create/0`;
      },
      contact: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/manage/contact/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/manage/contact/create/${stepId}`;
        }

        return `/salon/manage/contact/create/0`;
      },
      article: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/manage/article/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/salon/manage/article/create/${stepId}`;
        }

        return `/salon/manage/article/create/0`;
      },
      list: "/salon/manage/list",
      detail: (salonId) =>
        salonId
          ? `/salon/manage/detail/${salonId}`
          : `/salon/manage/detail/:salonId`,
      edit: (salonId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/salon/manage/edit/:salonId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 6) {
          return `/salon/manage/edit/${salonId}/${stepId}`;
        }

        return `/salon/manage/edit/:salonId/0`;
      },
    },
  },

  contract: {
    home: "/contract/home",
    send: "/contract/send",
    status: "/contract/status",
    contact: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/contract/contact/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/contract/contact/create/${stepId}`;
        }

        return `/contract/contact/create/0`;
      },
    },
  },

  recommend: {
    list: "/recommend/list",
    detail: (recommendProductId) =>
      recommendProductId
        ? `/recommend/detail/${recommendProductId}`
        : `/recommend/detail/:recommendProductId`,
    create: (stepId) => {
      if (stepId === undefined || stepId === null) {
        return `/recommend/create/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/recommend/create/${stepId}`;
      }

      return `/recommend/create/0`;
    },
    edit: (recommendId, stepId) => {
      if (stepId === undefined || stepId === null) {
        return `/recommend/edit/:recommendId/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/recommend/edit/${recommendId}/${stepId}`;
      }

      return `/recommend/edit/:recommendId/0`;
    },
  },

  dm: {
    home: "/dm/home",
    detail: (DMHistoryId, customerOrSalon) =>
      (DMHistoryId && customerOrSalon)
        ? `/dm/${customerOrSalon}/history/detail/${DMHistoryId}`
        : `/dm/:customerOrSalon/history/detail/:DMHistoryId`,
    customer: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/dm/customer/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/dm/customer/create/${stepId}`;
        }

        return `/dm/customer/create/0`;
      },
      history: (customerOrSalon) => {
        if (customerOrSalon === undefined || customerOrSalon === null) {
          return `/dm/:customerOrSalon/history`;
        }

        return `/dm/${customerOrSalon}/history`;
      },
    },
    salon: {
      create: (stepId, customerOrSalon) => {
        if (stepId === undefined || stepId === null) {
          return `/dm/:customerOrSalon/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/dm/${customerOrSalon}/create/${stepId}`;
        }

        return `/dm/${customerOrSalon}/create/0`;
      },
    },
  },

  contractor: {
    home: "/contractor/home",
    detail: (contractorId, contractorType) =>
      contractorId
        ? `/contractor/${contractorType}/detail/${contractorId}`
        : `/contractor/:contractorType/detail/:contractorId`,
    create: (stepId, contractorType) => {
      if (stepId === undefined || stepId === null) {
        return `/contractor/:contractorType/create/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/contractor/${contractorType}/create/${stepId}`;
      }

      return `/contractor/${contractorType}/create/0`;
    },
    list: (contractorType) => {
      if (contractorType === undefined || contractorType === null) {
        return `/contractor/:contractorType/list`;
      }

      return `/contractor/${contractorType}/list`;
    },
    edit: (contractorId, stepId, contractorType) => {
      if (stepId === undefined || stepId === null) {
        return `/contractor/:contractorType/edit/:contractorId/:stepId`;
      }

      if (stepId >= 0 && stepId <= 2) {
        return `/contractor/${contractorType}/edit/${contractorId}/${stepId}`;
      }

      return `/contractor/${contractorType}/edit/:contractorId/0`;
    },
  },

  systemSetting: {
    home: "/system/setting/home",
    sharedInfoLevel: "/system/setting/sharedInfoLevel",
    employee: {
      detail: (employeeId) =>
        employeeId
          ? `/system/setting/employee/detail/${employeeId}`
          : `/system/setting/employee/detail/:employeeId`,
      create: (stepId, employeeType) => {
        if (stepId === undefined || stepId === null) {
          return `/system/setting/employee/:employeeType/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/system/setting/employee/${employeeType}/create/${stepId}`;
        }

        return `/system/setting/employee/${employeeType}/create/0`;
      },
      list: (employeeType) => {
        if (employeeType === undefined || employeeType === null) {
          return `/system/setting/employee/:employeeType/list`;
        }

        return `/system/setting/employee/${employeeType}/list`;
      },
      edit: (employeeId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/system/setting/employee/edit/:employeeId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/system/setting/employee/edit/${employeeId}/${stepId}`;
        }

        return `/system/setting/employee/edit/:employeeId/0`;
      },
    },
  },

  drc: {
    home: "/drc/home",
    salesOrderManagement: {
      list: "/drc/salesOrderManagement/list",
      batchReceiveDrc: "/drc/salesOrderManagement/batchReceiveDrc",
      batchReceiveDrcDetail: (group_id) =>
      group_id
        ? `/scalp-lab-management/batch-receiveDrc-detail/${group_id}/detail`
        : `/scalp-lab-management/batch-receiveDrc-detail/:group_id/detail`,
    },
    examinationResult: {
      detail: (Id) =>
        Id
          ? `/drc/examinationResult/detail/${Id}`
          : `/drc/examinationResult/detail/:Id`,
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/drc/examinationResult/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/drc/examinationResult/create/${stepId}`;
        }

        return `/drc/examinationResult/create/0`;
      },
      edit: (examinationDataId, stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/drc/examinationResult/edit/:examinationDataId/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/drc/examinationResult/edit/${examinationDataId}/${stepId}`;
        }

        return `/drc/examinationResult/edit/:examinationDataId/0`;
      },
      list: "/drc/examinationResult/list",
      upload: "/drc/examinationResult/upload",
    },
    labContact: {
      create: (stepId) => {
        if (stepId === undefined || stepId === null) {
          return `/drc/lab/contact/create/:stepId`;
        }

        if (stepId >= 0 && stepId <= 2) {
          return `/drc/lab/contact/create/${stepId}`;
        }

        return `/drc/lab/contact/create/0`;
      },
    },
  },

  product: {
    home: "/product/home",
    order: {
      list: "/product/order/list",
      delivery: {
        confirmForm: (productOrderId, stepId) => {
          if (stepId === undefined || stepId === null) {
            return `/product/order/delivery/confirmForm/:productOrderId/:stepId`;
          }
  
          if (stepId >= 0 && stepId <= 2) {
            return `/product/order/delivery/confirmForm/${productOrderId}/${stepId}`;
          }
  
          return `/product/order/delivery/confirmForm/:productOrderId/0`;
        },
        history: "/product/order/delivery/history"
      }
    },
  },

  examinationData2112: "/examinationData2112",
  kit2214: "/kit2214",
  scalpDataDetails: "/scalpDataDetails",
  salonlist: "/salonlist",
  salonRegistration: "/salonRegistration",
  contactSalon: "/contactSalon",
  articleCreation: "/articleCreation",
  agencyRegistration: "/agencyRegistration",
  agentlist: "/agentlist",
  contractorRegistration: "/contractorRegistration",
  contractorslist: "/contractorslist",
  itemRegistration: "/itemRegistration",
  itemDetails: "/itemDetails",
  itemImport: "/itemImport",
  dmRegistrationSalon: "/dmRegistrationSalon",
  distributionHistorySalon: "/distributionHistorySalon",
  dmRegistrationCustomer: "/dmRegistrationCustomer",
  distributionHistoryCustomer: "/distributionHistoryCustomer",
  administratorRegistration: "/administratorRegistration",
  administratorlist: "/administratorlist",
  staffRegistration: "/staffRegistration",
  stafflist: "/stafflist",
  informationSharinglevel: "/informationSharinglevel",
  sendContract: "/sendContract",
  contractStatus: "/contractStatus",
  contractContact: "/contractContact",
  invoiceIssue: "/invoiceIssue",
  paymentContact: "/paymentContact",
  registerKitShipping: "/registerKitShipping",
  registerKitShippingfromlist: "/registerKitShippingfromlist",
  registerDepositfromlist: "/registerDepositfromlist",
  registerDeposit: "/registerDeposit",
  depositCorrection: "/depositCorrection",
  scalpDataCorrection: "/scalpDataCorrection",
  kitStockFix: "/kitStockFix",
  paymentContactConfirmation: "/paymentContactConfirmation",
  invoiceVerification: "/invoiceVerification",
  invoiceEmail: "/invoiceEmail",
  salonAdvertising: "/salonAdvertising",
  salonDetailedMenu: "/salonDetailedMenu",
  salonDetailMenuCorrection: "/salonDetailMenuCorrection",
  salonDetailsBasicCorrection: "/salonDetailsBasicCorrection",
  salonDetailsBasics: "/salonDetailsBasics",
  salonDetailsStaff: "/salonDetailsStaff",
  salonDetailsStaffCorrection: "/salonDetailsStaffCorrection",
  salonMenuRegistration: "/salonMenuRegistration",
  salonStaffRegistration: "/salonStaffRegistration",
  agencyDetails: "/agencyDetails",
  agencyModification: "/agencyModification",
  consignmentDetails: "/consignmentDetails",
  consignmentModification: "/consignmentModification",
  distributionDetailsCustomer: "/distributionDetailsCustomer",
  distributionDetailsSalon: "/distributionDetailsSalon",
  dmContentConfirmationCustomer: "/dmContentConfirmationCustomer",
  dmContentConfirmationSalon: "/dmContentConfirmationSalon",
  administratorDetails: "/administratorDetails",
  informationSharingLevelChange: "/informationSharingLevelChange",
  staffDetails: "/staffDetails",
  contractContactConfirmation: "/contractContactConfirmation",
  contactSalonConfirmation: "/contactSalonConfirmation",
  articleCreationConfirmation: "/articleCreationConfirmation",
  scalpDataCorrectionConfirmation: "/scalpDataCorrectionConfirmation",
};

export default routePaths;
