import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import InputDateField from "app/components/commonUI/InputDateField";
import Api from "app/services/api";
import {
  ACCOUNT_STATUS,
  SCALP_LAB_ACCOUNT_STATUS,
  SCALP_LAB_ACCOUNT_TEXT,
  SCALP_LAB_ACCOUNT_TYPE,
} from "../../../constants";
import BackBtn from "../../BackButton";
import { validateEmail, validatePassword } from "app/helper";

function EmployeeForm(props) {
  const { isEdit, stepId, employeeType, children } = props;
  const employee = children;

  const employeeTypeTemp = employeeType;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    { name: "管理者情報を入力する" },
    { name: "入力確認" },
    { name: "入力完了" },
  ];

  var createActionMessage = "管理者を登録する";
  var editActionMessage = "管理者を修正する";
  var createFinishMessage = "管理者を登録しました";
  var editFinishMessage = "管理者を修正しました";

  if (employeeTypeTemp == "staff") {
    steps[0].name = "スタッフ情報を入力する";
    createActionMessage = "スタッフを登録する";
    editActionMessage = "スタッフを修正する";
    createFinishMessage = "スタッフを登録しました";
    editFinishMessage = "スタッフを修正しました";
  }

  const navigate = useNavigate();

  const getAccountType = () => {
    return employeeType === SCALP_LAB_ACCOUNT_TEXT[1]
      ? SCALP_LAB_ACCOUNT_TYPE.MANAGER
      : SCALP_LAB_ACCOUNT_TYPE.STAFF;
  };

  const {
    handleSubmit,
    // handleChange,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      id: isEdit ? employee.id : "",
      employeeType: isEdit ? employee.employeeType : employeeTypeTemp,
      employeeNumber: isEdit ? employee.employeeNumber : "",
      affiliation: isEdit ? employee.affiliation : "",
      accessPrivilege: isEdit ? employee.accessPrivilege : "",
      employeeLastName: isEdit ? employee.employeeLastName : "",
      employeeFirstName: isEdit ? employee.employeeFirstName : "",
      employeeLastNameFurigana: isEdit ? employee.employeeLastNameFurigana : "",
      employeeFirstNameFurigana: isEdit ? employee.employeeFirstNameFurigana : "",
      birthday: isEdit ? dayjs(employee.birthday, "YYYY-MM-DD") : dayjs(),
      phoneNumber: isEdit ? employee.phoneNumber : "",
      email: isEdit ? employee.email : "",
      address: isEdit ? employee.address : "",
      postalCode: isEdit ? employee.postalCode : "",
      // accountType: isEdit ? employee.accountType : getAccountType(),
      accountStatus: isEdit ? employee.accountStatus : SCALP_LAB_ACCOUNT_STATUS.ACTIVE,
      loginName: isEdit ? employee.loginName : "",
      password: isEdit ? employee.password : "",
    },
    onSubmit: (values) => {
      setIsSubmitting(true);

      if (isEdit) {
        let payload = {
          staffNumber: values.employeeNumber,
          staffTeam: values.affiliation,
          accessLevel: values.accessPrivilege,
          lastNameKanji: values.employeeLastName,
          firstNameKanji: values.employeeFirstName,
          lastNameKata: values.employeeLastNameFurigana,
          firstNameKata: values.employeeFirstNameFurigana,
          dob: values.birthday,
          phone: values.phoneNumber,
          email: values.email,
          address: values.address,
          postalCode: values.postalCode,
          // accountType: getAccountType(),
          accountStatus: Number(values.accountStatus),
        };
        return Api.employees
          .update({ employeeId: employee.id, payload })
          .then((res) => {
            gotoNextStep();
            setIsSubmitting(false);
          })
          .catch((e) => {
            setIsSubmitting(false);
            alert("Error");
          });
      }

      let payload = {
        staffNumber: values.employeeNumber,
        staffTeam: values.affiliation,
        accessLevel: values.accessPrivilege,
        lastNameKanji: values.employeeLastName,
        firstNameKanji: values.employeeFirstName,
        lastNameKata: values.employeeLastNameFurigana,
        firstNameKata: values.employeeFirstNameFurigana,
        dob: values.birthday,
        phone: values.phoneNumber,
        email: values.email,
        address: values.address,
        postalCode: values.postalCode,
        accountType: getAccountType(),
        accountStatus: Number(values.accountStatus),
        loginName: values.loginName,
        password: values.password,
      };

      return Api.employees
        .store(payload)
        .then((res) => {
          gotoNextStep();
          setIsSubmitting(false);
        })
        .catch((e) => {
          setIsSubmitting(false);
          alert("Error");
        });
    },
    validate: (values,props) => {
      const errors = {};
      if (!values.employeeNumber) {
        errors.employeeNumber = "The Employee Number field required";
      }
      if (!values.affiliation) {
        errors.affiliation = "The Affiliation field required";
      }
      if (!values.accessPrivilege) {
        errors.accessPrivilege = "The Access Privilege field required";
      }
      if (!values.employeeLastName) {
        errors.employeeLastName = "The Employee LastName field required";
      }
      if (!values.employeeFirstName) {
        errors.employeeFirstName = "The Employee FirstName field required";
      }
      if (!values.employeeLastNameFurigana) {
        errors.employeeLastNameFurigana = "The Employee LastName Furigana field required";
      }
      if (!values.employeeFirstNameFurigana) {
        errors.employeeFirstNameFurigana = "The Employee FirstName Furigana field required";
      }
      if (!values.birthday) {
        errors.birthday = "The Birthday field required";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "The PhoneNumber field required";
      }
      // Email
      if (!values.email) {
        errors.email = "The Email field required";
      }else if(!validateEmail(values.email)){
        errors.email = "Email must be an email format";
      }
      //
      if (!values.address) {
        errors.address = "The Address field required";
      }
      // Password
      if (!values.password) {
        errors.password = "The Password field required";
      }else if (!validatePassword(values.password)) {
        errors.password = "Password must contain at least one letter, one number, and be at least 8 characters long";
      }
      //
      if (!values.loginName) {
        errors.loginName = "The Login Name field required";
      }
      if (!values.postalCode) {
        errors.postalCode = "The Postal Code field required";
      }
      return errors;
    },
  });

  const gotoPrevStep = () => {
    if (values.employeeType === SCALP_LAB_ACCOUNT_TEXT[1])
      isEdit
        ? navigate(Path.systemSetting.employee.edit(employee.id, stepId - 1))
        : navigate(Path.systemSetting.employee.create(stepId - 1, "manager"));
    if (values.employeeType === SCALP_LAB_ACCOUNT_TEXT[2])
      isEdit
        ? navigate(Path.systemSetting.employee.edit(employee.id, stepId - 1))
        : navigate(Path.systemSetting.employee.create(stepId - 1, "staff"));
  };

  const gotoNextStep = () => {
    if (values.employeeType === SCALP_LAB_ACCOUNT_TEXT[1])
      isEdit
        ? navigate(Path.systemSetting.employee.edit(employee.id, stepId + 1))
        : navigate(Path.systemSetting.employee.create(stepId + 1, "manager"));
    if (values.employeeType === SCALP_LAB_ACCOUNT_TEXT[2])
      isEdit
        ? navigate(Path.systemSetting.employee.edit(employee.id, stepId + 1))
        : navigate(Path.systemSetting.employee.create(stepId + 1, "staff"));
  };

  const step1IsValid = !Object?.keys(errors)?.length && !!Object?.keys(touched)?.length

  // const step1IsValid = () => {
  //   if (!values.employeeNumber) {
  //     return false;
  //   }
  //   if (!values.affiliation) {
  //     return false;
  //   }
  //   if (!values.accessPrivilege) {
  //     return false;
  //   }
  //   if (!values.employeeLastName) {
  //     return false;
  //   }
  //   if (!values.employeeFirstName) {
  //     return false;
  //   }
  //   if (!values.employeeLastNameFurigana) {
  //     return false;
  //   }
  //   if (!values.employeeFirstNameFurigana) {
  //     return false;
  //   }
  //   if (!values.birthday) {
  //     return false;
  //   }
  //   if (!values.phoneNumber) {
  //     return false;
  //   }
  //   if (!values.email) {
  //     return false;
  //   }
  //   if (!values.address) {
  //     return false;
  //   }

  //   return true;
  // };

  const employeeCreateFinished = () => {
    navigate(Path.systemSetting.employee.list(values.employeeType));
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>社員番号</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="employeeNumber"
                  type="text"
                  className="w-full"
                  value={values.employeeNumber}
                  onBlur={handleBlur}
                  errorMsg={errors?.employeeNumber && touched?.employeeNumber && errors?.employeeNumber}
                  setValue={(newValue) => {
                    setFieldValue("employeeNumber", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>所属</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="affiliation"
                  type="text"
                  className="w-full"
                  value={values.affiliation}
                  onBlur={handleBlur}
                  errorMsg={errors?.affiliation && touched?.affiliation && errors?.affiliation}
                  setValue={(newValue) => {
                    setFieldValue("affiliation", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>アクセス権限</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="accessPrivilege"
                  type="text"
                  className="w-full"
                  value={values.accessPrivilege}
                  onBlur={handleBlur}
                  errorMsg={errors?.accessPrivilege && touched?.accessPrivilege && errors?.accessPrivilege}
                  setValue={(newValue) => {
                    setFieldValue("accessPrivilege", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            {!isEdit && <Form.Row>
              <Form.Col>アカウント名</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="loginName"
                  type="text"
                  className="w-full"
                  value={values.loginName}
                  onBlur={handleBlur}
                  errorMsg={errors?.loginName && touched?.loginName && errors?.loginName}
                  setValue={(newValue) => {
                    setFieldValue("loginName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>}
            {!isEdit && <Form.Row>
              <Form.Col>パスワード</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="password"
                  type="text"
                  className="w-full"
                  value={values.password}
                  onBlur={handleBlur}
                  errorMsg={errors?.password && touched?.password && errors?.password}
                  setValue={(newValue) => {
                    setFieldValue("password", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>}
            {/* <Form.Row>
              <Form.Col>アカウント種類</Form.Col>
              <Form.ColGrow>
                <Input.Select
                  name="accountType"
                  key="accountType"
                  className="w-full"
                  value={values.accountType}
                  setValue={(newValue) => {
                    setFieldValue("accountType", newValue);
                  }}
                  selections={[
                    { name: "Manager", value: SCALP_LAB_ACCOUNT_TYPE.MANAGER, key: 'manager_01' },
                    { name: "Staff", value: SCALP_LAB_ACCOUNT_TYPE.STAFF, key: "manager_02" }
                  ]}
                ></Input.Select>
              </Form.ColGrow>
            </Form.Row> */}
            <Form.Row>
              <Form.Col>アカウントステータス</Form.Col>
              <Form.ColGrow>
                <Input.Select
                  name="accountStatus"
                  type="text"
                  className="w-full"
                  value={values.accountStatus}
                  onBlur={handleBlur}
                  errorMsg={errors?.accountStatus && touched?.accountStatus && errors?.accountStatus}
                  setValue={(newValue) => {
                    setFieldValue("accountStatus", newValue);
                  }}
                  selections={ACCOUNT_STATUS}
                ></Input.Select>
              </Form.ColGrow>
            </Form.Row>

            <Form.Row></Form.Row>

            <Form.Row>
              <Form.Col>氏名</Form.Col>
              <Form.ColGrow>
                <Form.Col>姓</Form.Col>
                <Input.Field
                  name="employeeLastName"
                  type="text"
                  value={values.employeeLastName}
                  onBlur={handleBlur}
                  errorMsg={errors?.employeeLastName && touched?.employeeLastName && errors?.employeeLastName}
                  setValue={(newValue) => {
                    setFieldValue("employeeLastName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
              <Form.ColGrow>
                <Form.Col>名</Form.Col>
                <Input.Field
                  name="employeeFirstName"
                  type="text"
                  value={values.employeeFirstName}
                  onBlur={handleBlur}
                  errorMsg={errors?.employeeFirstName && touched?.employeeFirstName && errors?.employeeFirstName}
                  setValue={(newValue) => {
                    setFieldValue("employeeFirstName", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>氏名（フリガナ）</Form.Col>
              <Form.ColGrow>
                <Form.Col>セイ</Form.Col>
                <Input.Field
                  name="employeeLastNameFurigana"
                  type="text"
                  value={values.employeeLastNameFurigana}
                  onBlur={handleBlur}
                  errorMsg={errors?.employeeLastNameFurigana && touched?.employeeLastNameFurigana && errors?.employeeLastNameFurigana}
                  setValue={(newValue) => {
                    setFieldValue("employeeLastNameFurigana", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
              <Form.ColGrow>
                <Form.Col>メイ</Form.Col>
                <Input.Field
                  name="employeeFirstNameFurigana"
                  type="text"
                  value={values.employeeFirstNameFurigana}
                  onBlur={handleBlur}
                  errorMsg={errors?.employeeFirstNameFurigana && touched?.employeeFirstNameFurigana && errors?.employeeFirstNameFurigana}
                  setValue={(newValue) => {
                    setFieldValue("employeeFirstNameFurigana", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>生年月日</Form.Col>
              <Form.ColGrow>
                <InputDateField
                  value={values.birthday}
                  setValue={(newValue) => setFieldValue("birthday", newValue)}
                  disableFuture={true}
                  onBlur={handleBlur}
                  errorMsg={errors?.birthday && touched?.birthday && errors?.birthday}
                />
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>電話番号</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="phoneNumber"
                  type="text"
                  className="w-full"
                  value={values.phoneNumber}
                  onBlur={handleBlur}
                  errorMsg={errors?.phoneNumber && touched?.phoneNumber && errors?.phoneNumber}
                  setValue={(newValue) => {
                    setFieldValue("phoneNumber", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>メールアドレス</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="email"
                  type="text"
                  className="w-full"
                  value={values.email}
                  onBlur={handleBlur}
                  errorMsg={errors?.email && touched?.email && errors?.email}
                  setValue={(newValue) => {
                    setFieldValue("email", newValue);
                  }}
                ></Input.Field>  
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>ポータルコード</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="postalCode"
                  type="number"
                  className="w-full"
                  value={values.postalCode}
                  onBlur={handleBlur}
                  errorMsg={errors?.postalCode && touched?.postalCode && errors?.postalCode}
                  setValue={(newValue) => {
                    setFieldValue("postalCode", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>住所</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="address"
                  type="text"
                  className="w-full"
                  value={values.address}
                  onBlur={handleBlur}
                  errorMsg={errors?.address && touched?.address && errors?.address}
                  setValue={(newValue) => {
                    setFieldValue("address", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn />
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>社員番号</Form.Col>
              <Form.ColGrow>{values.employeeNumber}</Form.ColGrow>
            </Form.Row>
            <Form.Row></Form.Row>
            <Form.Row>
              <Form.Col>所属</Form.Col>
              <Form.ColGrow>{values.affiliation}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>アクセス権限</Form.Col>
              <Form.ColGrow>{values.accessPrivilege}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>氏名</Form.Col>
              <Form.ColGrow>
                {values.employeeLastName} {values.employeeFirstName}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>氏名（フリガナ）</Form.Col>
              <Form.ColGrow>
                {values.employeeLastNameFurigana}{" "}
                {values.employeeFirstNameFurigana}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>生年月日</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">
                  {values.birthday}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>電話番号</Form.Col>
              <Form.ColGrow>{values.phoneNumber}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>メールアドレス</Form.Col>
              <Form.ColGrow>{values.email}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>住所</Form.Col>
              <Form.ColGrow>{values.address}</Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? editActionMessage : createActionMessage}
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? editFinishMessage : createFinishMessage}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={employeeCreateFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default EmployeeForm;
