import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import ProductOrder from "app/components/ProductOrder";

function KitDeliveryFulfillPage() {
  const { productOrderId, stepId } = useParams();

  const [kitRequestItem, setKitRequestDataItem] = useState({});
  const [kitRequestItemLoaded, setKitRequestDataItemLoaded] = useState(false);

  useEffect(() => {
    async function getDepositItem(productOrderId) {
      const response = await Api.labProductSalonOrder.get(productOrderId);
      const item = response.data;
      setKitRequestDataItem(item);
      setKitRequestDataItemLoaded(true);
    }

    getDepositItem(productOrderId);
  }, [productOrderId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "発送登録",
      }}
      showNotice={false}
    >
      {!kitRequestItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <ProductOrder.Delivery.Form
          isPrefilled={true}
          stepId={+stepId}
          productOrderId={productOrderId}
        >
          {kitRequestItem}
        </ProductOrder.Delivery.Form>
      )}
    </DefaultLayout>
  );
}

export default KitDeliveryFulfillPage;
