import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import DefaultLayout from "app/layouts/DefaultLayout";
import Button from "app/components/common/Button";
import Salon from "app/components/Salon";
import Modal from "app/components/common/Modal";
import BackBtn from "../../../components/BackButton";

const DELETE_STEP = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  FAILED: "FAIL",
};

const MODAL_TITLE = {
  [DELETE_STEP.STEP_1]: "以下の加盟店を削除しますか?",
  [DELETE_STEP.STEP_2]: "本当に以下の加盟店を削除しますか?",
  [DELETE_STEP.STEP_3]: "加盟店を削除しました",
  [DELETE_STEP.FAILED]: "加盟店を削除に失敗しました",
};

function SalonManageCreatePage(props) {
  const { salonId } = useParams();

  const [salonItem, setSalonItem] = useState({});
  const [salonItemLoaded, setSalonItemLoaded] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getSalonItem(salonId) {
      const response = await Api.salons.get(salonId);
      const items = response.data;
      setSalonItem(items);
      setSalonItemLoaded(true);
    }
    getSalonItem(salonId);
  }, [salonId]);

  const handleEditButtonClicked = () => {
    navigate(Path.salon.manage.edit(salonId, 0));
  };

  const handleDeleteButtonClicked = () => {
    setShowDeleteModal(DELETE_STEP.STEP_1);
  };

  const onDeleteSalon = () => {
    setDeleteLoading(true);
    Api.salons
      .delete(salonItem._id)
      .then((res) => {
        console.log("res", res);
        setShowDeleteModal(DELETE_STEP.STEP_3);
      })
      .catch((err) => {
        console.log("err", err);
        setShowDeleteModal(DELETE_STEP.FAILED);
      });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "加盟店情報",
      }}
      showNotice={false}>
      {!salonItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <Modal
            show={!!showDeleteModal}
            setShow={setShowDeleteModal}
            staticBackdrop={true}
            title={MODAL_TITLE[showDeleteModal]}
            loading={deleteLoading}
            button1={
              showDeleteModal === DELETE_STEP.STEP_3 || showDeleteModal === DELETE_STEP.FAILED ? undefined : (
                <Button.Danger
                  onClick={() => {
                    setShowDeleteModal(DELETE_STEP.STEP_2);
                    if (showDeleteModal === DELETE_STEP.STEP_2) {
                      onDeleteSalon();
                    }
                  }}>
                  削除
                </Button.Danger>
              )
            }
            button2={
              <Button
                onClick={() => {
                  if (showDeleteModal === DELETE_STEP.STEP_3) {
                    setTimeout(() => {
                      navigate(Path.salon.manage.list);
                    }, 500);
                  }
                  setShowDeleteModal(false);
                }}>
                {showDeleteModal === DELETE_STEP.STEP_3 || showDeleteModal === DELETE_STEP.FAILED ? "終了" : "キャンセル"}
              </Button>
            }>
            {(showDeleteModal === DELETE_STEP.STEP_1 || showDeleteModal === DELETE_STEP.STEP_2) && salonItem?.name}
          </Modal>
          <Button.Danger onClick={handleDeleteButtonClicked}>削除</Button.Danger>
          <Button.Primary onClick={handleEditButtonClicked}>修正</Button.Primary>
          <Salon.BasicInfoFormConfirm values={salonItem}></Salon.BasicInfoFormConfirm>
        </div>
      )}
      <BackBtn url={Path.salon.manage.home}/>
    </DefaultLayout>
  );
}

export default SalonManageCreatePage;

// 2402
