import React, { useEffect, useRef, useState } from "react";
import Form from "app/components/common/Form";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Modal from "app/components/common/Modal";
import GoogleMapComponent from "app/components/GoogleMap";

const DEFAULT_LOCATION = {
  lng: 139.7692938,
  lat: 35.6801482,
};

function SalonArtistForm(props) {
  const { values, setFieldValue, handleBlur, onMapSetButtonClicked } = props;

  const hadInitialLongLat = values?.longitude && values?.latitude;
  const INITIAL_LONG_LAT = {
    lat: parseFloat(values?.latitude),
    lng: parseFloat(values?.longitude),
  };

  const mapRef = useRef();
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
  // Google Map States
  const [geoCode, setGeoCode] = useState(hadInitialLongLat ? INITIAL_LONG_LAT : DEFAULT_LOCATION);
  const [center, setCenter] = useState(hadInitialLongLat ? INITIAL_LONG_LAT : DEFAULT_LOCATION);

  const onAddLocation = ({ query, postalCode, geoCode }) => {
    const { lng, lat } = geoCode || {};
    if (window.google?.maps) {
      const geocoder = new window.google.maps.Geocoder();
      const payload = {};
      if (postalCode || query) {
        payload.address = postalCode || query;
      }
      if (lat && lng) {
        payload.location = {
          lat,
          lng,
        };
      }
      geocoder
        .geocode(payload)
        .then((response) => {
          if (Array.isArray(response.results) && response.results.length > 0) {
            const addressObj = response.results[0];
            const locationFunc = addressObj.geometry.location;
            const location = { lat: locationFunc.lat(), lng: locationFunc.lng() };
            const addressComponents = addressObj.address_components;
            setFieldValue("latitude", location?.lat);
            setFieldValue("longitude", location?.lng);
            setGeoCode(location);
            setCenter(location);
            if (addressComponents) {
              for (let i = 0; i < addressComponents.length; i += 1) {
                const addressTypes = addressComponents[i].types;
                if (addressTypes.includes("postal_code")) {
                  setFieldValue("postalCode", addressComponents[i].long_name);
                } else if (addressTypes.includes("administrative_area_level_1")) {
                  setFieldValue("prefecture", addressComponents[i].long_name);
                } else if (addressTypes.includes("locality") && addressTypes.includes("political")) {
                  setFieldValue("city", addressComponents[i].long_name);
                } else if (addressTypes.includes("sublocality_level_2") && addressTypes.includes("sublocality")) {
                  setFieldValue("street", addressComponents[i].long_name);
                } else if (addressTypes.includes("sublocality_level_3") && addressTypes.includes("sublocality")) {
                  setFieldValue("building", addressComponents[i].long_name);
                }
              }
            }
            mapRef?.current?.panTo(location);
          }
          setShowSelectLocationModal(false);
        })
        .catch((err) => {
          console.log("err", err);
          setShowSelectLocationModal(false);
        });
    }
  };

  const handleBlurBuilding = (e) => {
    handleBlur(e);
    const buildingValue = e.target.value;
    if (buildingValue) {
      let query = "";
      if (values.postalCode) query += ` ${values.postalCode}`;
      if (values.prefecture) query += ` ${values.prefecture}`;
      if (values.city) query += ` ${values.city}`;
      if (values.street) query += ` ${values.street}`;
      if (values.building) query += ` ${values.building}`;
      onAddLocation({ query });
    }
  };

  const handlePostalCodeChange = (newValue) => {
    setFieldValue("postalCode", newValue);
    if (typeof newValue === "string" && newValue.match(/^(\d{7})$/)) {
      onAddLocation({ postalCode: newValue });
    }
  };

  return (
    <div className="space-y-2">
      <Modal show={showSelectLocationModal} setShow={setShowSelectLocationModal} staticBackdrop={true} title="地図で設定する" button1={<Button.Primary onClick={() => onAddLocation({ geoCode })}>地図で設定する</Button.Primary>} button2={<Button onClick={() => setShowSelectLocationModal(false)}>閉じる</Button>}>
        <div key={showSelectLocationModal} className="w-[400px] h-[300px]">
          <GoogleMapComponent mapRef={mapRef} geoCode={geoCode} setGeoCode={setGeoCode} center={center} setCenter={setCenter} />
        </div>
      </Modal>
      <Form.Row>
        <Form.Col>店舗名</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="name"
            type="text"
            className="w-full"
            value={values.name}
            setValue={(newValue) => {
              setFieldValue("name", newValue);
            }}></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>店舗名（フリガナ）</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="nameKana"
            type="text"
            className="w-full"
            value={values.nameKana}
            setValue={(newValue) => {
              setFieldValue("nameKana", newValue);
            }}></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>代表者</Form.Col>
        <Form.ColGrow>
          <Form.Row className="space-x-2">
            <Form.Col className="basis-1 pr-2">姓</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="picLastName"
                type="text"
                className="w-full"
                value={values.picLastName}
                setValue={(newValue) => {
                  setFieldValue("picLastName", newValue);
                }}></Input.Field>
            </Form.ColGrow>
            <Form.Col className="basis-1 pr-2">名</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="picFirstName"
                type="text"
                className="w-full"
                value={values.picFirstName}
                setValue={(newValue) => {
                  setFieldValue("picFirstName", newValue);
                }}></Input.Field>
            </Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>電話番号</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="telephone"
            type="text"
            className="w-full"
            value={values.telephone}
            setValue={(newValue) => {
              setFieldValue("telephone", newValue);
            }}></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>メールアドレス</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="email"
            type="text"
            className="w-full"
            value={values.email}
            setValue={(newValue) => {
              setFieldValue("email", newValue);
            }}></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>住所</Form.Col>
        <Form.ColGrow className="space-y-2">
          <Form.Row>
            <Form.Col>
              <Input.Field name="postalCode" type="text" className="w-full" value={values.postalCode} placeholder="郵便番号" setValue={handlePostalCodeChange} />
            </Form.Col>
          </Form.Row>
          <Form.Row className="space-x-2">
            <Form.ColGrow>
              <Input.Field
                name="prefecture"
                type="text"
                className="w-full"
                value={values.prefecture}
                placeholder="県"
                setValue={(newValue) => {
                  setFieldValue("prefecture", newValue);
                }}
              />
            </Form.ColGrow>
            <Form.ColGrow>
              <Input.Field
                name="city"
                type="text"
                className="w-full"
                placeholder="市"
                value={values.city}
                setValue={(newValue) => {
                  setFieldValue("city", newValue);
                }}
              />
            </Form.ColGrow>
            <Form.ColGrow>
              <Input.Field
                name="street"
                type="text"
                className="w-full"
                placeholder="街"
                value={values.street}
                setValue={(newValue) => {
                  setFieldValue("street", newValue);
                }}
              />
            </Form.ColGrow>
            <Form.ColGrow>
              <Input.Field
                name="building"
                type="text"
                className="w-full"
                placeholder="住所"
                value={values.building}
                onBlur={handleBlurBuilding}
                setValue={(newValue) => {
                  setFieldValue("building", newValue);
                }}
              />
            </Form.ColGrow>
          </Form.Row>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>地図設定</Form.Col>
        <Form.ColGrow className="space-y-2">
          <Form.Row>
            <Form.Col className="basis-14 pr-2">緯度</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="latitude"
                type="number"
                className="w-full"
                value={values.latitude}
                setValue={(newValue) => {
                  setFieldValue("latitude", newValue);
                }}></Input.Field>
            </Form.ColGrow>
          </Form.Row>
          <Form.Row>
            <Form.Col className="basis-14 pr-2">経度</Form.Col>
            <Form.ColGrow>
              <Input.Field
                name="longitude"
                type="number"
                className="w-full"
                value={values.longitude}
                setValue={(newValue) => {
                  setFieldValue("longitude", newValue);
                }}></Input.Field>
            </Form.ColGrow>
          </Form.Row>
          <Form.Col>
            <Button.Secondary
              onClick={() => {
                setShowSelectLocationModal(true);
              }}>
              地図で設定する
            </Button.Secondary>
          </Form.Col>
        </Form.ColGrow>
      </Form.Row>
    </div>
  );
}
export default SalonArtistForm;
