import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function DMSalonCreateMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.dm.salon.create(0, "salon"));
  };

  return (
    <MainMenuItem
      itemTitleString="DMを作成する(美容室)"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default DMSalonCreateMenuItem;
