import React from "react";
import ErrorIcon from '@mui/icons-material/Error';

export default function InputField(props) {
  const {
    name,
    values,
    label,
    type,
    onChange,
    handleBlur,
    touched,
    errors,
    inputWrapClasses,
    labelClasses,
    inputClasses,
    inputProps = {},
    disabled,
  } = props;

  return (
    <div className="w-full h-auto">
      <div className={`w-full flex items-start ${inputWrapClasses || ""}`}>
        {label ? (
          <div className="">
            <label
              htmlFor={name}
              className={`block w-[48px] shrink-0 font-semibold leading-9 ${labelClasses || ""}`}
            >
              {label}
            </label>
          </div>
        ) : null}

        <div
          className={`${
            label ? "w-[calc(100%-48px)]" : "w-full"
          } flex flex-col`}
        >
          <input
            name={name}
            id={name}
            className={`focus:outline-[#1976d2] w-full border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px] ${
              inputClasses || ""
            } ${touched && errors ? "!border-red-500" : ""} ${disabled ? "!bg-gray-100 !border-[#cbcbcb]" : ""} `}
            value={values}
            type={type}
            onChange={(e) => onChange(e.target.value)}
            onBlur={handleBlur}
            disabled={disabled}
            autoComplete="off"
            {...inputProps}
          />

          {touched && errors ? (
            <p className="w-full !text-red-600 !text-[14px]">
              <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
                <ErrorIcon fontSize="inherit" className="text-xs text-red-600 font-thin" />
              </span>
              <span>
                {errors}
              </span>
              
              </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
