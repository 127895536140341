import React from "react";

function DMGridItem(props) {
  const { children, onClicked } = props;
  const DMHistoryItem = children;

  const onSalonClick = () => {
    if (onClicked) {
      onClicked(DMHistoryItem);
    }
  };

  return (
    <div className="bg-white px-2 py-6 flex flex-row" onClick={onSalonClick}>
      <div>
        <img src={DMHistoryItem.imageUrl} alt={DMHistoryItem.name} />
      </div>
      <div className="px-2">
        <p className="font-bold">id: {DMHistoryItem.id}</p>
        <p className="font-bold">name: {DMHistoryItem.name}</p>
      </div>
    </div>
  );
}

export default DMGridItem;
