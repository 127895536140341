import React from "react";
import dayjs from "dayjs";

function DateTimeLabel(props) {
  const { className, children, dateFormat, useParag } = props;
  let useDateFormat = "YYYY-MM-DD HH:mm:ss";

  if (dateFormat !== undefined && dateFormat !== null) {
    useDateFormat = dateFormat;
  }

  const dayjsDate = dayjs(children);

  if (useParag) {
    return <p className={className}>{dayjsDate.format(useDateFormat)}</p>;
  }

  return <span className={className}>{dayjsDate.format(useDateFormat)}</span>;
}

export default DateTimeLabel;
