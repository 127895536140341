import KitMenuGrid from "./KitMenuGrid";
import KitOrderHistoryTableItem from "./Order/KitOrderHistoryTableItem";
import KitOrderForm from "./Order/KitOrderForm";
import KitDeliveryHistoryTableItem from "./Delivery/KitDeliveryHistoryTableItem";
import KitDeliveryForm from "./Delivery/KitDeliveryForm";
import KitRequestHistoryTableItem from "./Request/KitRequestHistoryTableItem";
import KitStockHistoryTableItem from "./Stock/KitStockHistoryTableItem";
import KitStockForm from "./Stock/KitStockForm";
import Table from "app/components/common/Table";

const exportObjs = {
  MenuGrid: KitMenuGrid,
  Order: {
    Table: {
      ...Table,
      Item: KitOrderHistoryTableItem,
    },
    Form: KitOrderForm,
  },
  Delivery: {
    Table: {
      ...Table,
      Item: KitDeliveryHistoryTableItem,
    },
    Form: KitDeliveryForm,
  },
  Request: {
    Table: {
      ...Table,
      Item: KitRequestHistoryTableItem,
    },
  },
  Stock: {
    Table: {
      ...Table,
      Item: KitStockHistoryTableItem,
    },
    Form: KitStockForm,
  },
};

export default exportObjs;
