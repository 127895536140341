import React from "react";
import { useState, useEffect } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Api from "app/services/api";
import Path from "app/route/Path";
import Button from "app/components/common/Button";
import ExaminationData from "app/components/ExaminationData";

function ExaminationDataListPage() {
  const navigate = useNavigate();
  // Ricky : need to merge new code from NWS

  const [examinationDatas, setExaminationDatas] = useState([]);
  const [examinationDatasLoaded, setExaminationDatasLoaded] = useState(false);

  const [arriveBtnTxt, setArriveBtnTxt] = useState(false);

  useEffect(() => {
    async function getAllExaminationDatas() {
      const response = await Api.examinationDatas.all();
      const items = response.data;
      setExaminationDatas(items);
      setExaminationDatasLoaded(true);
    }

    getAllExaminationDatas();
  }, []);

  useEffect(() => {
    setArriveBtnTxt("到着");
  }, []);

  const registerButtonClicked = () => {
    navigate(Path.examination.create(0));
  };

  const exportButtonClicked = async () => {
    await Api.examinationDatas.exportApi().then((data) => {
      const csvDataFromServer = data.data;
      const blob = new Blob([csvDataFromServer], { type: "text/csv" });
      const csvURL = URL.createObjectURL(blob);
      const fileName = "csv-file";
      // create a element to get download
      const link = document.createElement("a");
      // set a element properties: href, download
      link.href = csvURL;
      link.setAttribute("download", `${fileName}.csv`);
      // add a element to DOM
      document.body.appendChild(link);
      link.click();
      // remove a element from DOM after finish
      document.body.removeChild(link);
    });
  };

  const onDetailClicked = (examinationData) => {
    navigate(Path.examination.detail(examinationData.id));
  };

  const onArrivedClicked = (examinationData) => {
    // update salonDeliverStatus for scalp data
    console.log("到着しました", examinationData);

    let payload = {
      // analyseStatus: values.analyseStatus,
      // factorCellArea: Number(values.cellArea),
      // factorCluster: Number(values.cluster),
      // factorCP: Number(values.cp),
      customerNo: examinationData.customerNo,
      inspectionKitId: examinationData.inspectionKitId,
      // factorScore: Number(values.overall),
      // factorPeeling: Number(values.peeling),
      // analyseDate: values.registerDate.toISOString(),
      // inspectionDate: examinationDataItem.inspectionDate,
      // name: examinationDataItem.name,
      salonDeliverStatus: "done",
    };

    Api.examinationDatas
      .updateSalonDeliverStatus({
        examinationDataId: examinationData.id,
        payload,
      })
      .then((res) => {
        async function getAllExaminationDatas() {
          const response = await Api.examinationDatas.all();
          const items = response.data;
          setExaminationDatas(items);
          setExaminationDatasLoaded(true);
        }

        getAllExaminationDatas();
        //gotoNextStep();
        //setIsSubmitting(false);
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  };

  const onDrcDeliverClicked = (examinationData) => {
    // update scalpLabDeliverStatus for scalp data
    console.log("到着しました", examinationData);

    let payload = {
      // analyseStatus: values.analyseStatus,
      // factorCellArea: Number(values.cellArea),
      // factorCluster: Number(values.cluster),
      // factorCP: Number(values.cp),
      customerNo: examinationData.customerNo,
      inspectionKitId: examinationData.inspectionKitId,
      // factorScore: Number(values.overall),
      // factorPeeling: Number(values.peeling),
      // analyseDate: values.registerDate.toISOString(),
      // inspectionDate: examinationDataItem.inspectionDate,
      // name: examinationDataItem.name,
      //salonDeliverStatus: "done",
      //orderDate: new Date(),
      scalpLabDeliverStatus: "pending",
    };

    console.log("payload ", payload);

    Api.examinationDatas
      .updateScalpLabDeliverStatus({
        examinationDataId: examinationData.id,
        payload,
      })
      .then((res) => {
        async function getAllExaminationDatas() {
          const response = await Api.examinationDatas.all();
          const items = response.data;
          setExaminationDatas(items);
          setExaminationDatasLoaded(true);
        }

        getAllExaminationDatas();
        //gotoNextStep();
        //setIsSubmitting(false);
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  };

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ",
      }}
    >
      {/* chen */}
      <Button.Primary onClick={() => {
        navigate(Path.examination.batchReceiveLab);
      }}>検体到着管理</Button.Primary>

      <Button.Primary onClick={() => {
        navigate(Path.examination.batchDeliverDrc);
      }}>検体発送</Button.Primary>

      <Button.Secondary onClick={exportButtonClicked}>
        エクスポート
      </Button.Secondary>

      <ExaminationData.Table.Table
        items={examinationDatas}
        loading={!examinationDatasLoaded}
      >
        <ExaminationData.Table.Loading>
          <tbody>
            <tr>
              <td colSpan="100">ローディング中</td>
            </tr>
          </tbody>
        </ExaminationData.Table.Loading>
        <ExaminationData.Table.Empty>
          検査データがないです。
        </ExaminationData.Table.Empty>
        <ExaminationData.Table.Header>
          <tr>
            {/* <th scope="col" className="px-6 py-4">
              -
            </th>
            <th scope="col" className="px-6 py-4">
              -
            </th> */}
            <th scope="col" className="px-6 py-4">
              お客様ID
            </th>
            <th scope="col" className="px-6 py-4">
              キットID
            </th>
            <th scope="col" className="px-6 py-4">
              クラスター
            </th>
            <th scope="col" className="px-6 py-4">
              細胞面積
            </th>
            <th scope="col" className="px-6 py-4">
              多重剥離
            </th>
            <th scope="col" className="px-6 py-4">
              CP
            </th>
            <th scope="col" className="px-6 py-4">
              総合
            </th>
            <th scope="col" className="px-6 py-4">
              登録日時
            </th>
            <th scope="col" className="px-6 py-4">
              分析状況
            </th>
            <th scope="col" className="px-6 py-4">
              詳細
            </th>
          </tr>
        </ExaminationData.Table.Header>
        <ExaminationData.Table.Items>
          {examinationDatas
            .filter((item) => item.salonDeliverStatus !== undefined) // 篩選具有 salonDeliverStatus 欄位的物件
            .map((item) => (
              <ExaminationData.Table.Item
                key={item.id}
                onDetailClick={onDetailClicked}
                onArrivedClick={onArrivedClicked}
                onDrcDeliverClick={onDrcDeliverClicked}
                arriveBtnTxt={
                  item.salonDeliverStatus == "pending" ? "到着" : "到着した"
                }
                drcDeliverBtnTxt={
                  item.scalpLabDeliverStatus == "none"
                    ? "DRCに発送"
                    : "DRCに発送した"
                }
              >
                {item}
              </ExaminationData.Table.Item>
            ))}
        </ExaminationData.Table.Items>
      </ExaminationData.Table.Table>
    </DefaultLayout>
  );
}

export default ExaminationDataListPage;
