import React from "react";
import { ErrorText } from "./FieldInput";

function InputSelect(props) {
  const { value, setValue, selections, onBlur, errorMsg, errStyles, ...otherProps } = props;

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  return (
    <>
      <select value={value} onChange={handleChange} onBlur={onBlur} {...otherProps}>
        {selections.map((selection) => (
          <option value={selection.value} key={selection.value}>
            {selection.name}
          </option>
        ))}
      </select>
      {errorMsg && <ErrorText errorMsg={errorMsg} style={errStyles} />}
    </>
  );
}

export default InputSelect;
