import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Employee from "app/components/SystemSetting/Employee";

function EmployeeCreatePage(props) {
  const { employeeType, stepId } = useParams();

  var pageTitle = "";
  if (employeeType == "manager") pageTitle = "管理者情報入力";
  if (employeeType == "staff") pageTitle = "スタッフ情報入力";
  if (employeeType != "manager" && employeeType != "staff") return;

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <Employee.Form stepId={+stepId} employeeType={employeeType} />
    </DefaultLayout>
  );
}

export default EmployeeCreatePage;
