import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "app/layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import SegmentedControl from "app/components/commonUI/SegmentedControl";
import ProductGrid from "app/components/Product/ProductGrid";
import ProductGridItem from "app/components/Product/ProductGridItem";
import {recommends} from "app/services/api"
// import MainMenuItem from "app/components/MainMenuItem";
// import CommonConfirmDialog from "app/components/CommonDialog";
// import Path from "app/route/Path";
import FinishModal from "./FinishModal";
import Button from "app/components/common/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackBtn from "../../components/BackButton";

function Recommend() {
  // state management finished status upload csv file import
  const [showFinishedModal, setShowFinishedModal] = useState(false)
  const [numberOfImportItems, setNumberOfImportItems] = useState(0)

  const navigate = useNavigate();

  // input file ref
  const inputTypeFileReference = useRef(null);

  const ButtonClicked = () => {
    inputTypeFileReference.current?.click();
  };

  // choose csv file method:
  const handleSelectCSVFile = async (event) => {
    const csvFileFromLocal = event.target.files?.[0];
    const formData = new FormData();
    formData.append("file", csvFileFromLocal);
    // call API:
    await recommends
      .importCSVFileApi(formData)
      .then((data) => {
        const lineErrorArray = data.data.result.line_error;
        const lineErrorArrayLength = lineErrorArray.length
        if(lineErrorArrayLength < 1){
          const totalImportedItems = data.data.result.total;
          setNumberOfImportItems(totalImportedItems)
        }
      })
      .finally(() => {
        setShowFinishedModal(true)
      })
  };

  // close modal and reset the state
  const handleCloseMedal = () => {
    setShowFinishedModal(false)
    setNumberOfImportItems(0)
  }
  
  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "商品一括登録",
      }}
    >
      <FinishModal
        show={showFinishedModal}
        setShow={setShowFinishedModal}
        staticBackdrop={true}
        handleCloseMedal={handleCloseMedal}
        // title="インポートが完了しました"
        headerContent={
          <CheckCircleIcon
            sx={{
              fill: "#62D4AD",
              fontSize: "4.5rem",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%,0)",
              top: "-100%",
              zIndex: "10"
            }}
          />
        }
      >
        <p className="text-2xl py-4 text-center">インポートが完了しました</p>
        {/* <p className="text-xl pb-4 text-center">O件の商品をインポート</p> */}
        <p className="text-xl pb-4 text-center">{`${numberOfImportItems}件の商品をインポート`}</p>
      </FinishModal>
      <p>ファイルのアップロード</p>

      <button
        className="border border-black rounded-sm py-2 px-2 m-auto"
        onClick={ButtonClicked}
      >
        アップロード
      </button>
      <input
        type="file"
        className="hidden"
        ref={inputTypeFileReference}
        onChange={(event) => handleSelectCSVFile(event)}
        // handle bug: duplicate choose the same file -> the second does not work
        onClick={(event) => (event.target.value = null)}
      />
      <BackBtn />
    </DefaultLayout>
  );
}

export default Recommend;
