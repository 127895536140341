import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Path from "./Path";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
import ExaminationDataPage from "../pages/ExaminationData";
import BatchReceiveLab from "../pages/ExaminationData/BatchReceiveLab";
import BatchReceiveLabDetail from "../pages/ExaminationData/BatchReceiveLab/BatchReceiveLabDetail";
import BatchDeliverDrc from "../pages/ExaminationData/BatchDeliverDrc";
import BatchDeliverDrcDetail from "../pages/ExaminationData/BatchDeliverDrc/BatchDeliverDrcDetail";
import KitPage from "../pages/Kit";
import Salon from "../pages/Salon";
import ProductPage from  "../pages/Product";

import Contract from "../pages/Contract";
import Recommend from "../pages/Recommend";
import DM from "../pages/DM";
import Contractor from "../pages/Contractor";
import SystemSetting from "../pages/SystemSetting";
import Drc from "../pages/Drc";
import ExaminationData2112 from "../pages/ExaminationData2112";
import Kit2214 from "../pages/Kit2214";
import ScalpDataDetails from "../pages/ScalpDataDetails";
import Salonlist from "../pages/Salonlist";
import SalonRegistration from "../pages/SalonRegistration";
import ContactSalon from "../pages/ContactSalon";
import ArticleCreation from "../pages/ArticleCreation";
import AgencyRegistration from "../pages/AgencyRegistration";
import Agentlist from "../pages/Agentlist";
import ContractorRegistration from "../pages/ContractorRegistration";
import Contractorslist from "../pages/Contractorslist";
import ItemRegistration from "../pages/ItemRegistration";
import ItemDetails from "../pages/ItemDetails";
import ItemImport from "../pages/ItemImport";
import DMRegistrationSalon from "../pages/DMRegistrationSalon";
import DistributionHistorySalon from "../pages/DistributionHistorySalon";
import DMRegistrationCustomer from "../pages/DMRegistrationCustomer";
import DistributionHistoryCustomer from "../pages/DistributionHistoryCustomer";
import AdministratorRegistration from "../pages/AdministratorRegistration";
import Administratorlist from "../pages/Administratorlist";
import StaffRegistration from "../pages/StaffRegistration";
import Stafflist from "../pages/Stafflist";
import InformationSharinglevel from "../pages/InformationSharinglevel";
import SendContract from "../pages/SendContract";
import ContractStatus from "../pages/ContractStatus";
import ContractContact from "../pages/ContractContact";
import InvoiceIssue from "../pages/InvoiceIssue";
import PaymentContact from "../pages/PaymentContact";
import RegisterKitShipping from "../pages/RegisterKitShipping";
import RegisterKitShippingfromlist from "../pages/RegisterKitShippingfromlist";
import RegisterDepositfromlist from "../pages/RegisterDepositfromlist";
import RegisterDeposit from "../pages/RegisterDeposit";
import DepositCorrection from "../pages/DepositCorrection";
import ScalpDataCorrection from "../pages/ScalpDataCorrection";
import KitStockFix from "../pages/KitStockFix";
import PaymentContactConfirmation from "../pages/PaymentContactConfirmation";
import InvoiceVerification from "../pages/InvoiceVerification";
import InvoiceEmail from "../pages/InvoiceEmail";
import SalonAdvertising from "../pages/SalonAdvertising";
import SalonDetailedMenu from "../pages/SalonDetailedMenu";
import SalonDetailMenuCorrection from "../pages/SalonDetailMenuCorrection";
import SalonDetailsBasicCorrection from "../pages/SalonDetailsBasicCorrection";
import SalonDetailsBasics from "../pages/SalonDetailsBasics";
import SalonDetailsStaff from "../pages/SalonDetailsStaff";
import SalonDetailsStaffCorrection from "../pages/SalonDetailsStaffCorrection";
import SalonMenuRegistration from "../pages/SalonMenuRegistration";
import SalonStaffRegistration from "../pages/SalonStaffRegistration";
import AgencyDetails from "../pages/AgencyDetails";
import AgencyModification from "../pages/AgencyModification";
import ConsignmentDetails from "../pages/ConsignmentDetails";
import ConsignmentModification from "../pages/ConsignmentModification";
import DistributionDetailsCustomer from "../pages/DistributionDetailsCustomer";
import DistributionDetailsSalon from "../pages/DistributionDetailsSalon";
import DMContentConfirmationCustomer from "../pages/DMContentConfirmationCustomer";
import DMContentConfirmationSalon from "../pages/DMContentConfirmationSalon";
import AdministratorDetails from "../pages/AdministratorDetails";
import InformationSharingLevelChange from "../pages/InformationSharingLevelChange";
import StaffDetails from "../pages/StaffDetails";
import ContractContactConfirmation from "../pages/ContractContactConfirmation";
import ArticleCreationConfirmation from "../pages/ArticleCreationConfirmation";
import ContactSalonConfirmation from "../pages/ContactSalonConfirmation";
import ScalpDataCorrectionConfirmation from "../pages/ScalpDataCorrectionConfirmation";

// import Payment from "../pages/13/1303";
// // import ReservationInfoPage from "../pages/11/ReservationInfoPage";
// import PaymentCash from "../pages/13/1306";

// import FirstTimeCustomerPage from "../pages/11/FirstTimeCustomerPage";
// import ConfirmCustomerInfoPage from "../pages/11/ConfirmCustomerInfoPage";
// import CustomterCounselingSheetPage from "../pages/11/CustomterCounselingSheetPage";
// import CreateNewReservationPage from "../pages/11/CreateNewReservationPage";
// import ConfirmReservationPage from "../pages/11/ConfirmReservationPage";
// // During Service
// import DuringServicePage from "../pages/12/DuringServicePage";

// // Customer Management
// import CustomerManagementTopPage from "../pages/14/CustomerManagementTopPage";
// import DetailCustomerPage from "../pages/14/DetailCustomerPage";
// import Customer1StImpressionAndFactCheckPage from "../pages/14/Customer1StImpressionAndFactCheckPage";
// import CustomerBasicInfoPage from "../pages/14/CustomerBasicInfoPage";
// import CustomerCouselingSheetPage from "../pages/14/CustomerCouselingSheetPage";
// import CustomerVisitHistoryPage from "../pages/14/CustomerVisitHistoryPage";
// import RegisterNewCustomerPage from "../pages/14/RegisterNewCustomerPage";
// import Invoice from "../pages/13/components/Invoice";

function Router() {
  // const token = useSelector((state) => state.auth.token?.accessToken);

  const element = useRoutes([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        { path: Path.home, element: <HomePage /> },
        {
          path: Path.examination.list,
          element: <ExaminationDataPage.List />,
        },
        {
          path: Path.examination.detail(),
          element: <ExaminationDataPage.Detail />,
        },
        {
          path: Path.examination.create(),
          element: <ExaminationDataPage.Create />,
        },
        {
          path: Path.examination.edit(),
          element: <ExaminationDataPage.Edit />,
        },
        {
          path: Path.examination.batchReceiveLab,
          element: <BatchReceiveLab />,
        },
        {
          path: Path.examination.batchReceiveLabDetail(),
          element: <BatchReceiveLabDetail />,
        },
        {
          path: Path.examination.batchDeliverDrcDetail(),
          element: <BatchDeliverDrcDetail />,
        },
        {
          path: Path.examination.batchDeliverDrc,
          element: <BatchDeliverDrc />,
        },
        { path: Path.kit.home, element: <KitPage.Home /> },
        { path: Path.kit.order.create(), element: <KitPage.Order.Create /> },
        { path: Path.kit.order.history, element: <KitPage.OrderHistory /> },
        { path: Path.kit.request.list, element: <KitPage.Request.List /> },
        {
          path: Path.kit.delivery.create(),
          element: <KitPage.Delivery.Create />,
        },
        {
          path: Path.kit.delivery.fulfill(),
          element: <KitPage.Delivery.Fulfill />,
        },
        {
          path: Path.kit.delivery.list,
          element: <KitPage.Delivery.List />,
        },
        {
          path: Path.kit.stock.create(),
          element: <KitPage.Stock.Create />,
        },
        {
          path: Path.kit.stock.list,
          element: <KitPage.Stock.List />,
        },
        {
          path: Path.kit.stock.edit(),
          element: <KitPage.Stock.Edit />,
        },
        { path: Path.salon.deposit.list, element: <Salon.Deposit.SalonList /> },
        { path: Path.salon.deposit.home(), element: <Salon.Deposit.Home /> },
        { path: Path.salon.deposit.sales(), element: <Salon.Deposit.Sales /> },
        {
          path: Path.salon.deposit.contact(),
          element: <Salon.Deposit.Contact />,
        },
        {
          path: Path.salon.deposit.management(),
          element: <Salon.Deposit.Management />,
        },
        { path: Path.salon.deposit.edit(), element: <Salon.Deposit.Edit /> },
        {
          path: Path.salon.deposit.fulfill(),
          element: <Salon.Deposit.Fulfill />,
        },
        {
          path: Path.salon.deposit.create(),
          element: <Salon.Deposit.Create />,
        },
        { path: Path.salon.manage.home, element: <Salon.Manage.Home /> },
        {
          path: Path.salon.manage.contact(),
          element: <Salon.Manage.Contact />,
        },
        {
          path: Path.salon.manage.article(),
          element: <Salon.Manage.Article />,
        },
        {
          path: Path.salon.manage.create(),
          element: <Salon.Manage.Create />,
        },
        { path: Path.salon.manage.list, element: <Salon.Manage.List /> },
        {
          path: Path.salon.manage.detail(),
          element: <Salon.Manage.Detail />,
        },
        { path: Path.salon.manage.edit(), element: <Salon.Manage.Edit /> },
        { path: Path.contract.home, element: <Contract.Home /> },
        { path: Path.contract.status, element: <Contract.Status /> },
        { path: Path.contract.contact.create(), element: <Contract.Contact /> },

        { path: Path.recommend.list, element: <Recommend.List /> },
        {
          path: Path.recommend.create(),
          element: <Recommend.Create />,
        },
        {
          path: Path.recommend.detail(),
          element: <Recommend.Detail />,
        },
        {
          path: Path.recommend.edit(),
          element: <Recommend.Edit />,
        },

        { path: Path.dm.home, element: <DM.Home /> },
        {
          path: Path.dm.customer.create(),
          element: <DM.Customer.Create />,
        },
        {
          path: Path.dm.salon.create(),
          element: <DM.Salon.Create />,
        },
        {
          path: Path.dm.customer.history(),
          element: <DM.Customer.History />,
        },
        {
          path: Path.dm.detail(),
          element: <DM.Detail />,
        },

        { path: Path.contractor.home, element: <Contractor.Home /> },
        {
          path: Path.contractor.create(),
          element: <Contractor.Create />,
        },
        {
          path: Path.contractor.list(),
          element: <Contractor.List />,
        },
        {
          path: Path.contractor.detail(),
          element: <Contractor.Detail />,
        },
        {
          path: Path.contractor.edit(),
          element: <Contractor.Edit />,
        },

        { path: Path.systemSetting.home, element: <SystemSetting.Home /> },
        {
          path: Path.systemSetting.sharedInfoLevel,
          element: <SystemSetting.SharedInfoLevel />,
        },
        {
          path: Path.systemSetting.employee.create(),
          element: <SystemSetting.Employee.Create />,
        },
        {
          path: Path.systemSetting.employee.list(),
          element: <SystemSetting.Employee.List />,
        },
        {
          path: Path.systemSetting.employee.detail(),
          element: <SystemSetting.Employee.Detail />,
        },
        {
          path: Path.systemSetting.employee.edit(),
          element: <SystemSetting.Employee.Edit />,
        },
        { path: Path.drc.home, element: <Drc.Home /> },
        {
          path: Path.drc.salesOrderManagement.batchReceiveDrc,
          element: <Drc.SalesOrderManagement.BatchReceiveDrc />,
        },
        {
          path: Path.drc.salesOrderManagement.batchReceiveDrcDetail(),
          element: <Drc.SalesOrderManagement.BatchReceiveDrcDetail />,
        },
        {
          path: Path.drc.examinationResult.upload,
          element: <Drc.ExaminationResult.Upload />,
        },
        {
          path: Path.drc.examinationResult.create(),
          element: <Drc.ExaminationResult.Create />,
        },
        {
          path: Path.drc.examinationResult.detail(),
          element: <Drc.ExaminationResult.Detail />,
        },
        {
          path: Path.drc.examinationResult.edit(),
          element: <Drc.ExaminationResult.Edit />,
        },
        {
          path: Path.drc.examinationResult.list,
          element: <Drc.ExaminationResult.List />,
        },
        {
          path: Path.drc.labContact.create(),
          element: <Drc.LabContact.Create />,
        },

        { path: Path.examinationData2112, element: <ExaminationData2112 /> },

        { path: Path.kit2214, element: <Kit2214 /> },

        { path: Path.scalpDataDetails, element: <ScalpDataDetails /> },

        { path: Path.salonlist, element: <Salonlist /> },

        { path: Path.salonRegistration, element: <SalonRegistration /> },

        { path: Path.contactSalon, element: <ContactSalon /> },

        { path: Path.articleCreation, element: <ArticleCreation /> },

        { path: Path.agencyRegistration, element: <AgencyRegistration /> },

        { path: Path.agentlist, element: <Agentlist /> },

        {
          path: Path.contractorRegistration,
          element: <ContractorRegistration />,
        },

        { path: Path.contractorslist, element: <Contractorslist /> },

        { path: Path.itemRegistration, element: <ItemRegistration /> },

        { path: Path.itemDetails, element: <ItemDetails /> },

        { path: Path.itemImport, element: <ItemImport /> },

        { path: Path.dmRegistrationSalon, element: <DMRegistrationSalon /> },

        {
          path: Path.distributionHistorySalon,
          element: <DistributionHistorySalon />,
        },

        {
          path: Path.dmRegistrationCustomer,
          element: <DMRegistrationCustomer />,
        },

        {
          path: Path.distributionHistoryCustomer,
          element: <DistributionHistoryCustomer />,
        },

        {
          path: Path.administratorRegistration,
          element: <AdministratorRegistration />,
        },

        { path: Path.administratorlist, element: <Administratorlist /> },

        { path: Path.staffRegistration, element: <StaffRegistration /> },

        { path: Path.stafflist, element: <Stafflist /> },

        {
          path: Path.informationSharinglevel,
          element: <InformationSharinglevel />,
        },

        { path: Path.sendContract, element: <SendContract /> },

        { path: Path.contractStatus, element: <ContractStatus /> },

        { path: Path.contractContact, element: <ContractContact /> },

        { path: Path.invoiceIssue, element: <InvoiceIssue /> },

        { path: Path.paymentContact, element: <PaymentContact /> },

        { path: Path.registerKitShipping, element: <RegisterKitShipping /> },

        {
          path: Path.registerKitShippingfromlist,
          element: <RegisterKitShippingfromlist />,
        },

        {
          path: Path.registerDepositfromlist,
          element: <RegisterDepositfromlist />,
        },

        { path: Path.registerDeposit, element: <RegisterDeposit /> },

        { path: Path.depositCorrection, element: <DepositCorrection /> },

        { path: Path.scalpDataCorrection, element: <ScalpDataCorrection /> },

        { path: Path.kitStockFix, element: <KitStockFix /> },

        {
          path: Path.paymentContactConfirmation,
          element: <PaymentContactConfirmation />,
        },

        { path: Path.invoiceVerification, element: <InvoiceVerification /> },

        { path: Path.invoiceEmail, element: <InvoiceEmail /> },

        { path: Path.salonAdvertising, element: <SalonAdvertising /> },

        { path: Path.salonDetailedMenu, element: <SalonDetailedMenu /> },

        {
          path: Path.salonDetailMenuCorrection,
          element: <SalonDetailMenuCorrection />,
        },

        {
          path: Path.salonDetailsBasicCorrection,
          element: <SalonDetailsBasicCorrection />,
        },

        { path: Path.salonDetailsBasics, element: <SalonDetailsBasics /> },

        { path: Path.salonDetailsStaff, element: <SalonDetailsStaff /> },

        {
          path: Path.salonDetailsStaffCorrection,
          element: <SalonDetailsStaffCorrection />,
        },

        {
          path: Path.salonMenuRegistration,
          element: <SalonMenuRegistration />,
        },

        {
          path: Path.salonStaffRegistration,
          element: <SalonStaffRegistration />,
        },

        { path: Path.agencyDetails, element: <AgencyDetails /> },

        { path: Path.agencyModification, element: <AgencyModification /> },

        { path: Path.consignmentDetails, element: <ConsignmentDetails /> },

        {
          path: Path.consignmentModification,
          element: <ConsignmentModification />,
        },

        {
          path: Path.distributionDetailsCustomer,
          element: <DistributionDetailsCustomer />,
        },

        {
          path: Path.distributionDetailsSalon,
          element: <DistributionDetailsSalon />,
        },

        {
          path: Path.dmContentConfirmationCustomer,
          element: <DMContentConfirmationCustomer />,
        },

        {
          path: Path.dmContentConfirmationSalon,
          element: <DMContentConfirmationSalon />,
        },

        { path: Path.administratorDetails, element: <AdministratorDetails /> },

        {
          path: Path.informationSharingLevelChange,
          element: <InformationSharingLevelChange />,
        },

        { path: Path.staffDetails, element: <StaffDetails /> },

        {
          path: Path.contractContactConfirmation,
          element: <ContractContactConfirmation />,
        },

        {
          path: Path.articleCreationConfirmation,
          element: <ArticleCreationConfirmation />,
        },

        {
          path: Path.contactSalonConfirmation,
          element: <ContactSalonConfirmation />,
        },

        {
          path: Path.scalpDataCorrectionConfirmation,
          element: <ScalpDataCorrectionConfirmation />,
        },

        { path: Path.product.home, element: <ProductPage.Home /> },
        { path: Path.product.order.list, element: <ProductPage.Order.List /> },
        {
          path: Path.product.order.delivery.confirmForm(),
          element: <ProductPage.Order.Delivery.ConfirmForm />,
        },
        { path: Path.product.order.delivery.history, element: <ProductPage.Order.Delivery.History /> },

        // { path: Path.firstTimeCustomer, element: <FirstTimeCustomerPage /> },
        // {
        //   path: Path.confirmCustomerInfo(),
        //   element: <ConfirmCustomerInfoPage />,
        // },
        // {
        //   path: Path.customterCounselingSheet(),
        //   element: <CustomterCounselingSheetPage />,
        // },
        // {
        //   path: Path.createNewReservation(),
        //   element: <CreateNewReservationPage />,
        // },
        // {
        //   path: Path.confirmReservationInfo(),
        //   element: <ConfirmReservationPage />,
        // },

        // { path: Path.payment, element: <ListCustomers /> },
        // { path: Path.paymentCustomer, element: <Payment /> },
        // { path: Path.paymentCash, element: <PaymentCash /> },
        // { path: Path.invoice, element: <Invoice /> },

        // // add other

        // // During Service
        // { path: Path.duringService(), element: <DuringServicePage /> },

        // // Customer Management
        // { path: Path.customerManagement, element: <CustomerManagementTopPage /> },
        // { path: Path.customerManagementRegisterNew, element: <RegisterNewCustomerPage /> },
        // { path: Path.customerManagementBasicInfo(), element: <CustomerBasicInfoPage /> },
        // { path: Path.customerManagementDetail(), element: <DetailCustomerPage /> },
        // { path: Path.customerManagementFactCheck(), element: <Customer1StImpressionAndFactCheckPage /> },
        // { path: Path.customerManagementCounselingSheet(), element: <CustomerCouselingSheetPage /> },
        // { path: Path.customerManagementVisitHistory(), element: <CustomerVisitHistoryPage /> },
      ],
    },

    {
      path: "/",
      element: <AuthRoute />,
      children: [
        { path: Path.login, element: <LoginPage /> },
        // add register, reset_password
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return element;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
