import React from "react";
import Form from "app/components/common/Form";
import Input from "app/components/common/Input";
import {
  MENU_TYPE,
  PUBLISH_STATUS,
} from "app/components/Salon/Management/ManagementForm";

function SalonArtistForm(props) {
  const { values, setFieldValue } = props;

  return (
    <div className="space-y-2">
      <Form.Row>
        <Form.Col>メニュー名</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="name"
            type="text"
            className="w-full"
            value={values.addMenu.name}
            setValue={(newValue) => {
              setFieldValue("addMenu.name", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>メニュー説明文</Form.Col>
        <Form.ColGrow>
          <Input.MultilineField
            name="description"
            type="text"
            className="w-full"
            value={values.addMenu.description}
            setValue={(newValue) => {
              setFieldValue("addMenu.description", newValue);
            }}
          ></Input.MultilineField>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>所要時間</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="duration"
            type="number"
            className="w-full"
            value={values.addMenu.duration}
            positiveOnly
            setValue={(newValue) => {
              setFieldValue("addMenu.duration", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>料金</Form.Col>
        <Form.ColGrow>
          <Input.Field
            name="price"
            type="number"
            className="w-full"
            value={values.addMenu.price}
            setValue={(newValue) => {
              setFieldValue("addMenu.price", newValue);
            }}
          ></Input.Field>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>メニューの種類</Form.Col>
        <Form.ColGrow>
          <Input.Select
            name="menuType"
            className="w-full"
            value={values.addMenu.menuType}
            setValue={(newValue) => {
              setFieldValue("addMenu.menuType", newValue);
            }}
            selections={[
              { name: "シャンプー", value: MENU_TYPE.SHAMPOO },
              { name: "トリートメント", value: MENU_TYPE.TREATMENT },
            ]}
          ></Input.Select>
        </Form.ColGrow>
      </Form.Row>
      <Form.Row>
        <Form.Col>掲載ステータス</Form.Col>
        <Form.ColGrow>
          <Input.Select
            name="publishStatus"
            className="w-full"
            value={values.addMenu.publishStatus}
            setValue={(newValue) => {
              setFieldValue("addMenu.publishStatus", newValue);
            }}
            selections={[
              { name: "公開中", value: PUBLISH_STATUS.PUBLISH },
              { name: "未公開", value: PUBLISH_STATUS.NOT_PUBLISH },
            ]}
          ></Input.Select>
        </Form.ColGrow>
      </Form.Row>
    </div>
  );
}
export default SalonArtistForm;
