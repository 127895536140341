import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import { Link } from "react-router-dom";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import TableBatchDelivery from "./TableBatchDelivery";
import { getAllLabReceivedScalpDataBatchingGroup } from "app/services/api";
//import NoData from "../../../components/commonUI/NoData";

function ScalpDataBatchingGroupPage() {
  const [fetchScalpDataBatchingGroup, setFetchScalpDataBatchingGroup] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadScalpDataBatchingGroup = () => {
    setFetchScalpDataBatchingGroup({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllLabReceivedScalpDataBatchingGroup()
      .then((res) => {
        setFetchScalpDataBatchingGroup({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchScalpDataBatchingGroup({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadScalpDataBatchingGroup();
  }, []);

  console.log("Ricky --fetchScalpDataBatchingGroup--", fetchScalpDataBatchingGroup)

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "検査データ",
      }}
      showNotice={false}
    >
      <MainContentLayout
      pageTitle= {"検査"}
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
        <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-white">
            <h2 className="text-base lg:text-xl font-semibold">頭皮データ一括発送</h2>

          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchScalpDataBatchingGroup.isLoading ? <LoadingProgress /> : null}

                {!fetchScalpDataBatchingGroup.isLoading &&
                fetchScalpDataBatchingGroup.error ? (
                  <LoadInitError error={fetchScalpDataBatchingGroup.error} />
                ) : null}
                {!fetchScalpDataBatchingGroup.isLoading &&
                !fetchScalpDataBatchingGroup.error ? (
                  Array.isArray(fetchScalpDataBatchingGroup.data) &&
                  fetchScalpDataBatchingGroup.data.length > 0 ? (
                    <TableBatchDelivery
                      allHistoryOrdering={fetchScalpDataBatchingGroup.data}
                    />
                  ) : (
                    // <NoData />
                    <div>データがないです</div>
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link to={-1} className="w-auto h-8 flex items-center justify-center gap-2 bg-[#666FC8] px-4 rounded text-white">
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ScalpDataBatchingGroupPage;
