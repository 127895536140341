import React from "react";

function FieldInput(props) {
  const { value, setValue, positiveOnly, onBlur, errorMsg, errStyles,...otherProps } = props;

  return (
    <>
    <input
      value={
        positiveOnly ? (value > 0 ? parseInt(value, 10).toString() : 0) : value
      }
      onChange={(evt) => setValue(evt.target.value)}
      onBlur={onBlur}
      {...otherProps}
    />
    {errorMsg && <ErrorText errorMsg={errorMsg} style={errStyles} />}
    </>
  );
}

function ErrorText({ errorMsg, style }) {
  return <div style={{ color: "red", fontSize: "0.75em", padding: "0.25em 0", ...style }}>{errorMsg || "Invalid"}</div>;
}

export { ErrorText };

export default FieldInput;
