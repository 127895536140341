import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Input from "app/components/common/Input";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Api from "app/services/api";
import BackBtn from "../../BackButton";

function KitOrderForm(props) {
  const { stepId } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const steps = [
    { name: "発注登録" },
    { name: "入力確認" },
    { name: "登録完了" },
  ];

  const navigate = useNavigate();

  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    // handleBlur,
  } = useFormik({
    initialValues: {
      orderDate: dayjs(),
      amount: 0,
      memo: "",
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const response = await Api.kit.order.requests.create(values);
      setIsSubmitting(false);
      gotoNextStep();
      // alert(JSON.stringify(values, null, 2));
      // setTimeout(() => {
      //   gotoNextStep();
      //   setIsSubmitting(false);
      // }, 1000);
    },
  });

  const gotoPrevStep = () => {
    navigate(Path.kit.order.create(stepId - 1));
  };

  const gotoNextStep = () => {
    navigate(Path.kit.order.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.orderDate) {
      return false;
    }
    if (values.amount <= 0) {
      return false;
    }
    if (!values.memo) {
      return false;
    }

    return true;
  };

  const kitOrderFinished = () => {
    navigate(Path.kit.order.history);
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Form.Row>
              <Form.Col>発注日</Form.Col>
              <Form.ColGrow>
                <Input.DatePicker
                  disablePast
                  name="orderDate"
                  className="w-full"
                  value={values.orderDate}
                  setValue={(newValue) => {
                    setFieldValue("orderDate", newValue);
                  }}
                ></Input.DatePicker>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>数量</Form.Col>
              <Form.ColGrow>
                <Input.Field
                  name="amount"
                  type="number"
                  className="w-full"
                  value={values.amount}
                  setValue={(newValue) => {
                    setFieldValue("amount", newValue);
                  }}
                ></Input.Field>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow>
                <Input.MultilineField
                  name="memo"
                  className="w-full"
                  value={values.memo}
                  setValue={(newValue) => {
                    setFieldValue("memo", newValue);
                  }}
                ></Input.MultilineField>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.kit.order.history}/>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Form.Row>
              <Form.Col>発注日</Form.Col>
              <Form.ColGrow>
                <Label.DateTime dateFormat="YYYY-MM-DD">
                  {values.orderDate}
                </Label.DateTime>
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>数量</Form.Col>
              <Form.ColGrow>{values.amount}</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.Col>備考</Form.Col>
              <Form.ColGrow>
                <p className="whitespace-pre-wrap">{values.memo}</p>
              </Form.ColGrow>
            </Form.Row>
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                発注情報を登録します
              </Button.Primary>
            </Panel.Justify>
          </div>
        );

      case 2:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>発注情報を登録しました</Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={kitOrderFinished}>完了</Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default KitOrderForm;
