import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";

function SalesOrderTableItem(props) {
  const { children, onDetailClick, arriveBtnTxt, onArrivedClick } = props;
  const salesOrder = children;

  const onDetailClicked = () => {
    onDetailClick(salesOrder);
  };

  const onArrivedClicked = () => {
    onArrivedClick(salesOrder);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary
          disabled={salesOrder.scalpLabDeliverStatus != "done" ? false : true}
          onClick={onArrivedClicked}
        >
          {arriveBtnTxt}
        </Button.Primary>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Label.DateTime dateFormat="YYYY-MM-DD">
          {salesOrder.orderDate}
        </Label.DateTime>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {salesOrder.inspectionKitId}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        {salesOrder.scalpLabDeliverStatus}
      </td>
    </tr>
  );
}

export default SalesOrderTableItem;
