import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contractor from "app/components/Contractor";

function ContractorEditPage(props) {
  const { contractorId, stepId, contractorType } = useParams();

  const [contractorItem, setContractorItem] = useState({});
  const [contractorItemLoaded, setContractorItemLoaded] = useState(false);

  useEffect(() => {
    async function getContractorItem(contractorId) {
      if (contractorType === "agency") {
        return await Api.contractors.get(contractorId)
          .then((res) => {
            setContractorItem(res.data);
            setContractorItemLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setContractorItemLoaded(true);
          });
      } else {
        return await Api.outsourcings.get(contractorId)
          .then((res) => {
            setContractorItem(res.data);
            setContractorItemLoaded(true);
          })
          .catch((e) => {
            alert("Error");
            setContractorItemLoaded(true);
          });
      }
    }

    getContractorItem(contractorId);
  }, [contractorId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "代理店・委託先を編集",
      }}
      showNotice={false}
    >
      {!contractorItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Contractor.Form
          isEdit={true}
          stepId={+stepId}
          contractorType={contractorItem.contractorType}
          contractorId={contractorId}
        >
          {contractorItem}
        </Contractor.Form>
      )}
    </DefaultLayout>
  );
}

export default ContractorEditPage;
