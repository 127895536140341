import listPage from "./listPage";
import createPage from "./createPage";
import detailPage from "./detailPage";
import editPage from "./editPage";

const exportObjs = {
  List: listPage,
  Detail: detailPage,
  Create: createPage,
  Edit: editPage,
};

export default exportObjs;
