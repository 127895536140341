import homePage from "./homePage";
//import orderCreatePage from "./Order/createPage";
//import orderHistoryPage from "./Order/listPage";
import orderListPage from "./Order/listPage";
import deliveryHistoryPage from "./Order/Delivery/listPage";
//import deliveryCreatePage from "./Order/Delivery/createPage";
import deliveryConfirmFormPage from "./Order/Delivery/confromFormPage";

const exportObjs = {
  Home: homePage,
  Order: {
    //Create: orderCreatePage,
    List: orderListPage,
    Delivery: {
      ConfirmForm: deliveryConfirmFormPage,
      History: deliveryHistoryPage
    }
  },
  Delivery: {
    //List: deliveryListPage,
    //Create: deliveryCreatePage,
  },
  // Stock: {
  //   List: stockListPage,
  //   Create: stockCreatePage,
  //   Edit: stockEditPage,
  // },
  //OrderHistory: orderHistoryPage,
};

export default exportObjs;
