/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Recommend from "app/components/Recommend";
import {recommends} from "app/services/api"
import BackBtn from "../../components/BackButton";
import Path from "app/route/Path";

function RecommendDetailPage() {
  const { recommendProductId } = useParams();

  const productId = useMemo(() => {
    if(recommendProductId) return recommendProductId
  }, [recommendProductId])

  const [recommendItem, setRecommendItem] = useState({});
  const [recommendItemLoaded, setRecommendItemLoaded] = useState(false);

  useEffect(() => {
    const handleGetProductItemDetai = async () => {
      await recommends.getProductItemDetailApi(productId).then((data) => {
        const productItem = data.data.result
        setRecommendItem(productItem)
        setRecommendItemLoaded(true);
      })
    }
    handleGetProductItemDetai(productId)
  }, [recommendProductId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: recommendItem?.productName,
      }}
      showNotice={false}
    >
      {!recommendItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <div className="space-y-4">
          <Recommend.Detail>{recommendItem}</Recommend.Detail>
        </div>
      )}
      <BackBtn url={Path.recommend.list}/>
    </DefaultLayout>
  );
}

export default RecommendDetailPage;
