import React from "react";
import { useNavigate } from "react-router-dom";
import MainMenuItem from "app/components/MainMenuItem";
import Path from "app/route/Path";

function SendMenuItem(props) {
  const navigate = useNavigate();

  const onMenuItemClicked = () => {
    navigate(Path.contract.send);
  };

  return (
    <MainMenuItem
      itemTitleString="契約書を送付する"
      onClick={onMenuItemClicked}
      size="100%"
    />
  );
}

export default SendMenuItem;
