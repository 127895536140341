import React from "react";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import {
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
} from "@mui/material";

function EmployeeTableItem(props) {
  const { children, onDetailClick } = props;
  const employee = children;

  const onDetailClicked = () => {
    onDetailClick(employee);
  };

  return (
    <tr className="border-b dark:border-neutral-500">
      <td className="whitespace-nowrap px-6 py-4">{employee.employeeNumber}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {employee.employeeLastName} {employee.employeeFirstName}
      </td>
      <td className="whitespace-nowrap px-6 py-4">{employee.affiliation}</td>
      <td className="whitespace-nowrap px-6 py-4">
        {employee.accessPrivilege}
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default EmployeeTableItem;
