import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Path from "app/route/Path";
import StepsBar from "app/components/common/StepsBar";
import Button from "app/components/common/Button";
// import Label from "app/components/common/Label";
import Panel from "app/components/common/Panel";
import Form from "app/components/common/Form";
import Salon from "app/components/Salon";
import Modal from "app/components/common/Modal";
import Api from "app/services/api";
import BackBtn from "../../BackButton";

export const PUBLISH_STATUS = {
  PUBLISH: "published",
  NOT_PUBLISH: "notpublished",
};

export const MENU_TYPE = {
  SHAMPOO: "shampoo",
  TREATMENT: "treatment",
};

function ManagementForm(props) {
  const { isEdit, stepId, children } = props;
  const salonItem = children;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddMenuModal, setShowAddMenuModal] = useState(false);
  const [showAddArtistModal, setShowAddArtistModal] = useState(false);

  const sampleEmptyAddMenu = {
    name: "",
    description: "",
    duration: 60,
    price: 1000,
    publishStatus: PUBLISH_STATUS.PUBLISH,
    menuType: MENU_TYPE.SHAMPOO,
  };

  const sampleEmptyAddArtist = {
    lastName: "",
    firstName: "",
    lastNameKana: "",
    firstNameKana: "",
    imageUrl: "",
    dob: dayjs().add(-25, "year"),
    telephone: "",
    email: "",
    postalCode: "",
    address: "",
    loginName: "",
    password: "",
  };

  const steps = [
    { name: "基本情報入力" },
    { name: "基本情報確認" },
    { name: "メニュー入力" },
    { name: "メニュー確認" },
    { name: "担当者入力" },
    { name: "担当者確認" },
    { name: "入力完了" },
  ];

  const navigate = useNavigate();
  const {
    handleSubmit,
    // handleChange,
    // errors,
    values,
    setFieldValue,
    // resetForm,
    // touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      id: isEdit ? salonItem?.id : "",
      name: isEdit ? salonItem?.name : "",
      // imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
      nameKana: isEdit ? salonItem?.nameKana : "",
      picLastName: isEdit ? salonItem?.picLastName : "",
      picFirstName: isEdit ? salonItem?.picFirstName : "",
      telephone: isEdit ? salonItem?.telephone : "",
      email: isEdit ? salonItem?.email : "",
      postalCode: isEdit ? salonItem?.postalCode : "",
      address: isEdit ? salonItem?.address : "",
      latitude: isEdit ? salonItem?.latitude : "",
      longitude: isEdit ? salonItem?.longitude : "",
      addMenu: { ...sampleEmptyAddMenu },
      menus: isEdit ? salonItem?.menus : [],
      addArtist: { ...sampleEmptyAddArtist },
      artists: isEdit ? salonItem?.artists : [],
      prefecture: isEdit ? salonItem?.prefecture : "",
      city: isEdit ? salonItem?.city : "",
      street: isEdit ? salonItem?.street : "",
      building: isEdit ? salonItem?.building : "",
    },
    onSubmit: (values) => {
      const {
        name,
        nameKana,
        picLastName,
        picFirstName,
        telephone: phone,
        email,
        latitude,
        longitude,
        menus,
        artists,
        postalCode,
        prefecture,
        city,
        street,
        building,
      } = values || {};
      const payload = {
        name,
        nameKana,
        picLastName,
        picFirstName,
        // Address
        postalCode,
        prefecture,
        city,
        street,
        building,
        //
        workingHours: null,
        phone,
        latitude: `${latitude}`,
        longitude: `${longitude}`,
        email,
        menus,
        staffs: artists,
        status: 0,
      };
      setIsSubmitting(true);
      // Edit
      if (isEdit) {
        const editPayload = {
          ...payload,
          menus: payload?.menus?.map((item) => {
            const obj = item;
            obj._id = item?.id;
            if (obj?._id) {
              return obj;
            }
            delete obj.duration;
            delete obj.price;
            delete obj.id;
            return obj;
          }),
          staffs: payload?.staffs?.map((item) => {
            const {
              lastNameKana: lastNameKata,
              firstNameKana: firstNameKata,
              lastName: lastNameKanji,
              firstName: firstNameKanji,
              dob,
              telephone: phone,
              email,
              postalCode,
              address,
              imageUrl,
              loginName,
              password,
              id: _id,
            } = item || {};
            if (_id) {
              const obj = item;
              obj._id = item?.id;
              return obj;
            }
            return {
              lastNameKata,
              firstNameKata,
              lastNameKanji,
              firstNameKanji,
              dob,
              phone,
              email,
              postalCode,
              address,
              imageUrl,
              loginName,
              password,
              _id,
            };
          }),
        };
        Api.salons.edit({
          payload: editPayload,
          salonId: values?.id,
          callbackSuccess: () => {
            gotoNextStep();
            setIsSubmitting(false);
          },
        });
      }
      // Create
      else {
        Api.salons.create({
          payload,
          callbackSuccess: () => {
            gotoNextStep();
            setIsSubmitting(false);
          },
        });
      }
    },
  });

  useEffect(() => {
    if (!showAddArtistModal) {
      setFieldValue("addArtist", { ...sampleEmptyAddArtist });
    }
    if (!showAddMenuModal) {
      setFieldValue("addMenu", { ...sampleEmptyAddMenu });
    }
  }, [showAddArtistModal, showAddMenuModal]);

  const gotoPrevStep = () => {
    isEdit
      ? navigate(Path.salon.manage.edit(salonItem?.id, stepId - 1))
      : navigate(Path.salon.manage.create(stepId - 1));
  };

  const gotoNextStep = () => {
    isEdit
      ? navigate(Path.salon.manage.edit(salonItem?.id, stepId + 1))
      : navigate(Path.salon.manage.create(stepId + 1));
  };

  const step1IsValid = () => {
    if (!values.name) {
      return false;
    }
    // imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    if (!values.nameKana) {
      return false;
    }
    if (!values.picLastName) {
      return false;
    }
    if (!values.picFirstName) {
      return false;
    }
    if (!values.telephone) {
      return false;
    }
    if (!values.email) {
      return false;
    }
    if (!values.postalCode) {
      return false;
    }
    if (!values.prefecture) {
      return false;
    }
    if (!values.city) {
      return false;
    }
    if (!values.street) {
      return false;
    }
    if (!values.building) {
      return false;
    }
    if (!values.latitude) {
      return false;
    }
    if (!values.longitude) {
      return false;
    }
    return true;
  };

  const addMenuIsValid = () => {
    if (!values.addMenu.name) {
      return false;
    }
    // imageUrl: "https://placehold.jp/1D7874/ffffff/150x150.png?text=美容室1",
    if (!values.addMenu.description) {
      return false;
    }
    if (!values.addMenu.duration) {
      return false;
    }
    if (!values.addMenu.price) {
      return false;
    }
    return true;
  };

  const addArtistIsValid = () => {
    console.log("values.addArtist", values.addArtist);
    if (!values.addArtist.lastName) {
      console.log("Case 1");
      return false;
    }
    if (!values.addArtist.firstName) {
      console.log("Case 2");
      return false;
    }
    if (!values.addArtist.lastNameKana) {
      console.log("Case 3");
      return false;
    }
    if (!values.addArtist.firstNameKana) {
      console.log("Case 4");
      return false;
    }
    // if (!values.addArtist.imageUrl) {
    //   return false;
    // }
    if (!values.addArtist.dob) {
      console.log("Case 5");
      return false;
    }
    if (!values.addArtist.telephone) {
      console.log("Case 6");
      return false;
    }
    if (!values.addArtist.email) {
      console.log("Case 7");
      return false;
    }
    if (!values.addArtist.postalCode) {
      console.log("Case 8");
      return false;
    }
    if (!values.addArtist.address) {
      console.log("Case 9");
      return false;
    }
    if (!values.addArtist.loginName && !values.addArtist.id) {
      console.log("Case 10");
      return false;
    }
    if (!values.addArtist.password && !values.addArtist.id) {
      console.log("Case 11");
      return false;
    }
    return true;
  };

  const salonCreateFinished = () => {
    navigate(Path.salon.manage.home);
  };

  const onAddMenuClicked = () => {
    if (!addMenuIsValid()) {
      // TODO: Error
      alert("入力エラー");
      return;
    }

    const { name, description, duration, price, publishStatus, menuType, id } =
      values?.addMenu || {};

    const CONVERT_PUBLISH_STATUS = {
      [PUBLISH_STATUS.PUBLISH]: 0,
      [PUBLISH_STATUS.NOT_PUBLISH]: 1,
    };

    const formatAddMenuItem = {
      name,
      description,
      durationString: `${duration}`,
      durationNumber: duration,
      priceTaxExc: price,
      priceTaxInc: price,
      publicityStatus: CONVERT_PUBLISH_STATUS[publishStatus],
      itemToUse: "",
      menuType,
      imageUrl: "",
      // for form
      duration,
      price,
      id,
    };

    if (id) {
      const newList = values?.menus?.map((menuItem) => {
        if (menuItem?.id === id) {
          return formatAddMenuItem;
        }
        return menuItem;
      });
      setFieldValue("menus", newList);
      setShowAddMenuModal(false);
    } else {
      values.menus.push(formatAddMenuItem);
    }
    setFieldValue("addMenu", { ...sampleEmptyAddMenu });
  };

  const onEditMenuClicked = (menuItem) => {
    setFieldValue("addMenu", { ...menuItem });
    setShowAddMenuModal(true);
  };

  const handleMenuDeleteClicked = (menuItem) => {
    setFieldValue(
      "menus",
      values.menus.filter((eachMenuItem) => eachMenuItem.id !== menuItem.id)
    );
  };

  const onEditArtistClicked = (artistItem) => {
    console.log("artistItem", artistItem);
    setFieldValue("addArtist", { ...artistItem, dob: dayjs(artistItem?.dob) });
    setShowAddArtistModal(true);
  };

  const onAddArtistClicked = () => {
    if (!addArtistIsValid()) {
      // TODO: Error
      alert("入力エラー");
      return;
    }

    const {
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      imageUrl,
      dob,
      telephone,
      email,
      postalCode,
      address,
      loginName,
      password,
      id,
    } = values?.addArtist || {};

    const formatAddArtistItem = {
      lastNameKata: lastNameKana,
      firstNameKata: firstNameKana,
      lastNameKanji: lastName,
      firstNameKanji: firstName,
      dob: dob?.toISOString(),
      phone: telephone,
      email,
      postalCode,
      address,
      imageUrl,
      // for form
      telephone,
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      loginName,
      password,
      id,
    };

    if (id) {
      const newList = values?.artists?.map((artistItem) => {
        if (artistItem?.id === id) {
          return formatAddArtistItem;
        }
        return artistItem;
      });
      setFieldValue("artists", newList);
      setShowAddArtistModal(false);
    } else {
      values.artists.push(formatAddArtistItem);
    }

    setFieldValue("addArtist", { ...sampleEmptyAddArtist });
  };

  const handleArtistDeleteClicked = (artistItem) => {
    setFieldValue(
      "artists",
      values.artists.filter(
        (eachArtistItem) => eachArtistItem.id !== artistItem.id
      )
    );
  };

  const handleProfileButtonClicked = () => {
    alert("プロファイルの画像選択予定");
  };

  const renderByStepIndex = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Salon.BasicInfoForm
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <BackBtn url={Path.salon.manage.home} />
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 1:
        return (
          <div className="mx-2 space-y-4">
            <Salon.BasicInfoFormConfirm values={values} />
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary onClick={gotoNextStep}>次へ</Button.Primary>
            </Panel.Justify>
          </div>
        );
      case 2:
        return (
          <>
            <Modal
              show={showAddMenuModal}
              setShow={setShowAddMenuModal}
              staticBackdrop={true}
              title="メニュー入力"
              button1={
                <Button.Primary onClick={(evt) => onAddMenuClicked(evt)}>
                  メニューを追加する
                </Button.Primary>
              }
              button2={
                <Button onClick={() => setShowAddMenuModal(false)}>
                  閉じる
                </Button>
              }
            >
              <Salon.Menu.Form
                values={values}
                setFieldValue={setFieldValue}
                onProfileButtonClicked={handleProfileButtonClicked}
              />
            </Modal>
            <Salon.Menu.FormConfirm
              values={values}
              onMenuDeleteClicked={handleMenuDeleteClicked}
              onEditClicked={onEditMenuClicked}
            />
            <Button.Primary onClick={() => setShowAddMenuModal(true)}>
              メニューを追加
            </Button.Primary>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 3:
        return (
          <>
            <Salon.Menu.FormConfirm values={values} />
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary onClick={gotoNextStep}>次へ</Button.Primary>
            </Panel.Justify>
          </>
        );
      case 4:
        return (
          <>
            <Modal
              show={showAddArtistModal}
              setShow={setShowAddArtistModal}
              staticBackdrop={true}
              title="担当者入力"
              button1={
                <Button.Primary onClick={(evt) => onAddArtistClicked(evt)}>
                  担当者を追加する
                </Button.Primary>
              }
              button2={
                <Button onClick={() => setShowAddArtistModal(false)}>
                  閉じる
                </Button>
              }
            >
              <Salon.Artist.Form
                values={values}
                setFieldValue={setFieldValue}
                onProfileButtonClicked={handleProfileButtonClicked}
              />
            </Modal>
            <Salon.Artist.FormConfirm
              values={values}
              onArtistDeleteClicked={handleArtistDeleteClicked}
              onEditClicked={onEditArtistClicked}
            />
            <Button.Primary onClick={() => setShowAddArtistModal(true)}>
              担当者を追加
            </Button.Primary>

            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary onClick={gotoNextStep} disabled={!step1IsValid()}>
                次へ
              </Button.Primary>
            </Panel.Justify>
          </>
        );
      case 5:
        return (
          <>
            <Salon.Artist.FormConfirm values={values} />
            <Panel.Justify className="!sticky !bottom-0 w-full bg-white p-2">
              <Button onClick={gotoPrevStep}>戻る</Button>
              <Button.Primary type="submit" loading={isSubmitting}>
                {isEdit ? "加盟店を編集する" : "加盟店を登録する"}
              </Button.Primary>
            </Panel.Justify>
          </>
        );

      case 6:
        return (
          <div className="text-center space-y-4 mx-2">
            <Form.Row>
              <Form.ColGrow>
                {isEdit ? "加盟店を編集完了" : "加盟店を登録完了"}
              </Form.ColGrow>
            </Form.Row>
            <Form.Row>
              <Form.ColGrow>
                <Button.Primary onClick={salonCreateFinished}>
                  完了
                </Button.Primary>
              </Form.ColGrow>
            </Form.Row>
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <StepsBar steps={steps} currentStepIndex={stepId} />
      <Form.Form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
        {renderByStepIndex(stepId)}
      </Form.Form>
    </>
  );
}

export default ManagementForm;
