import homePage from "./homePage";
import orderCreatePage from "./Order/createPage";
import orderHistoryPage from "./Order/listPage";
import requestListPage from "./Request/listPage";
import deliveryListPage from "./Delivery/listPage";
import deliveryCreatePage from "./Delivery/createPage";
import deliveryFulfillPage from "./Delivery/fulfillPage";
import stockListPage from "./Stock/listPage";
import stockCreatePage from "./Stock/createPage";
import stockEditPage from "./Stock/editPage";

const exportObjs = {
  Home: homePage,
  Order: {
    Create: orderCreatePage,
  },
  Request: {
    List: requestListPage,
  },
  Delivery: {
    List: deliveryListPage,
    Create: deliveryCreatePage,
    Fulfill: deliveryFulfillPage,
  },
  Stock: {
    List: stockListPage,
    Create: stockCreatePage,
    Edit: stockEditPage,
  },
  OrderHistory: orderHistoryPage,
};

export default exportObjs;
