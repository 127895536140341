import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "app/services/api";
import DefaultLayout from "app/layouts/DefaultLayout";
import Recommend from "app/components/Recommend";
import {recommends} from "app/services/api"

function RecommendEditPage(props) {
  const { recommendId, stepId } = useParams();
  console.log("recommendId: ", recommendId);

  const [recommendItem, setRecommendItem] = useState({});
  const [recommendItemLoaded, setRecommendItemLoaded] = useState(false);

  useEffect(() => {
    // async function getRecommendItem(recommendId) {
    //   const response = await Api.recommends.get(recommendId);
    //   const item = response.data;
    //   setRecommendItem(item);
    //   setRecommendItemLoaded(true);
    // }

    // getRecommendItem(recommendId);

    // call api get product item detail
    const handleGetProductItemDetai = async () => {
      await recommends.getProductItemDetailApi(recommendId).then((data) => {
        const productItem = data.data.result;
        setRecommendItem(productItem);
        setRecommendItemLoaded(true);
      });
    };
    handleGetProductItemDetai(recommendId);
  }, [recommendId]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: "おすすめ商品を編集",
      }}
      showNotice={false}
    >
      {!recommendItemLoaded ? (
        <div>ローディング中</div>
      ) : (
        <Recommend.Form
          isEdit={true}
          stepId={+stepId}
          recommendId={recommendId}
        >
          {recommendItem}
        </Recommend.Form>
      )}
    </DefaultLayout>
  );
}

export default RecommendEditPage;
