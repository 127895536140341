import * as React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/ja";

import { defaultDateTimeFormat } from "app/constants";

function DateTimePickerInput(props) {
  const { value, setValue, disabled, className, ...otherProps } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DateTimePicker
        timeSteps={{ minutes: 1 }}
        value={value}
        className={className}
        onChange={(newValue) => setValue(newValue)}
        format={defaultDateTimeFormat}
        disabled={disabled}
        sx={{
          "& .MuiInputBase-input": {
            padding: "6px 16px !important",
            lineHeight: "24px !important",
            height: "24px !important",
            fontSize: "16px",
          },
          "& .Mui-disabled": {
            backgroundColor: "#f3f4f6 !important",
          },
          "& input.Mui-disabled ": {
            color: "#000 !important",
            "-webkit-text-fill-color": "#000 !important",
          },
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  );
}

export default DateTimePickerInput;
