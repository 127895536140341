import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../route/Path";
import dayjs from "dayjs";

export default function TableBatchDelivery(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  const DELIVERY_STATUS = {
    PENDING: "未発送",
    DELIVEING: "発送中",
    DONE: "発送完了",
    UNKNOWN: "未知"
  }

  const RECEIVE_STATUS = {
    RECEIVED: "到着",
    DELIVEING: "未到着",
    UNKNOWN: "未知"
  }

  return (
    <div className="w-full h-full relative overflow-auto min-w-[900px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          管理番号
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          美容室名
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          検体数
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          到着状況
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
        美容室発送日時
        </div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row, index) => (
        <div className="w-full h-14 flex bg-white border-b">
          <div className="w-1/6 flex items-center justify-center">
            {row.manageCode}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.salonId.name}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.inspectionKitIds.length}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {
              row.salonDeliverStatus === "pending"? RECEIVE_STATUS.UNKNOWN : 
              row.salonDeliverStatus === "delivering"? RECEIVE_STATUS.DELIVEING : 
              row.salonDeliverStatus === "done"? RECEIVE_STATUS.RECEIVED : 
              DELIVERY_STATUS.UNKNOWN
            }
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.salonDeliverDate? dayjs(row.salonDeliverDate).format("YYYY-MM-DD HH:mm") : "-"}
          </div>
          <div className="w-1/6 flex items-end justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(
                  Path.examination.batchReceiveLabDetail(row?._id)
                );
              }}
            >
              管理
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
