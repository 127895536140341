import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import Api from "app/services/api";
import Button from "app/components/common/Button";
import Label from "app/components/common/Label";
import Layout from "app/components/common/Layout";
import Modal from "app/components/common/Modal";
import Input from "app/components/common/Input";
import Image from "app/components/Image"
import { useParams } from "react-router-dom";
import {recommends} from "app/services/api"

function RecommendDetail(props) {
  const { children } = props;
  const recommendItem = children;

  // get productId from url
  const { recommendProductId } = useParams();

  const navigate = useNavigate();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeleteReconfirmModal, setShowDeleteReconfirmModal] =
    useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showDeleteConfirmationModalClicked = (evt) => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteClicked = (evt) => {
    setShowDeleteConfirmationModal(false);
    setShowDeleteReconfirmModal(true);
  };

  const reconfirmDeleteClicked = async () => {
    await recommends
      .deleteProductItemApi(recommendProductId)
      .then(() => {
        setShowDeleteReconfirmModal(false);
        setShowCompleteModal(true);
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
        alert("Error");
      });
  };

  const closeCompleteModalClicked = (evt) => {
    setShowCompleteModal(false);
    navigate(Path.recommend.list);
  };

  const editClicked = () => {
    navigate(Path.recommend.edit(recommendProductId, 0));
  };

  return (
    <>
      <Modal
        show={showDeleteConfirmationModal}
        setShow={setShowDeleteConfirmationModal}
        staticBackdrop={true}
        title="このおすすめ商品を削除しますか？"
        button1={
          <Button.Danger onClick={(evt) => confirmDeleteClicked(evt)}>
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteConfirmationModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showDeleteReconfirmModal}
        setShow={setShowDeleteReconfirmModal}
        staticBackdrop={true}
        title="（最終確認）このおすすめ商品を削除しますか？"
        button1={
          <Button.Danger
            onClick={(evt) => reconfirmDeleteClicked(evt)}
            loading={isSubmitting}
          >
            削除を確認する
          </Button.Danger>
        }
        button2={
          <Button onClick={() => setShowDeleteReconfirmModal(false)}>
            閉じる
          </Button>
        }
      ></Modal>

      <Modal
        show={showCompleteModal}
        setShow={setShowCompleteModal}
        staticBackdrop={true}
        title="このおすすめ商品を削除しました。"
        button1={
          <Button.Secondary onClick={(evt) => closeCompleteModalClicked(evt)}>
            終了
          </Button.Secondary>
        }
      ></Modal>

      <Layout.Container>
        <Layout.Row>
          <Layout.Col grow={true}>
            <p>id: {recommendItem?._id}</p>
            <p className="font-bold">商品名: {recommendItem?.name}</p>
            <p>商品説明: {recommendItem?.description}</p>
            <p className="flex items-center">商品写真:  <Image src={recommendItem?.imageUrl}  /></p>
            <p>販売価格: {recommendItem?.price}</p>
            <p>対応カテゴリ: {recommendItem?.category}</p>
            <p>製品範囲: {recommendItem?.productScope}</p>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Danger onClick={showDeleteConfirmationModalClicked}>
              削除
            </Button.Danger>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row single={true}>
          <Layout.Col>
            <Button.Secondary onClick={editClicked}>
              このおすすめ商品を編集
            </Button.Secondary>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </>
  );
}

export default RecommendDetail;
