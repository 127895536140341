import React from "react";

function DurationLabel(props) {
  const { className, children } = props;

  const price = children;

  return <p className={className}>{price}分</p>;
}

export default DurationLabel;
