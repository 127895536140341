import React from "react";
import dayjs from "dayjs";
import Label from "app/components/common/Label";
import Button from "app/components/common/Button";
import Text from "app/components/common/Text";

function MonthRange(props) {
  const { year, month, setYear, setMonth } = props;

  const startDate = dayjs(new Date(year, month - 1, 1));
  const endDate = startDate.add(1, "month").subtract(1, "day");

  const onPrevMonthClicked = (evt) => {
    const newStartDate = startDate.subtract(1, "month");
    setYear(newStartDate.year());
    setMonth(newStartDate.month() + 1);
  };

  const onNextMonthClicked = (evt) => {
    const newStartDate = startDate.add(1, "month");
    setYear(newStartDate.year());
    setMonth(newStartDate.month() + 1);
  };

  return (
    <div className="flex mx-auto">
      <div className="flex-none w-14 h-14">
        <Button className="h-full" onClick={onPrevMonthClicked}>
          &lt;
        </Button>
      </div>
      <div className="flex-none text-center">
        <Text.Thick useParag={true}>今月の売上</Text.Thick>
        <Label.DateTime dateFormat="YYYY年MM月DD日">{startDate}</Label.DateTime>
        〜<Label.DateTime dateFormat="YYYY年MM月DD日">{endDate}</Label.DateTime>
      </div>
      <div className="flex-none w-14 h-14">
        <Button className="h-full" onClick={onNextMonthClicked}>
          &gt;
        </Button>
      </div>
    </div>
  );
}

export default MonthRange;
