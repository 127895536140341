import React from "react";

function ThickText(props) {
  const { children, className, useParag, ...otherProps } = props;

  if (useParag) {
    return (
      <p className={`font-bold ${className ?? ""}`} {...otherProps}>
        {children}
      </p>
    );
  }

  return (
    <span className={`font-bold ${className ?? ""}`} {...otherProps}>
      {children}
    </span>
  );
}

export default ThickText;
