import React from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import Contract from "app/components/Contract";

function ContactCreatePage(props) {
  const { stepId } = useParams();

  var pageTitle = "連絡内容入力";

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "md:!block",
        pageTitle: pageTitle,
      }}
      showNotice={false}
    >
      <Contract.Contact.Form stepId={+stepId} />
    </DefaultLayout>
  );
}

export default ContactCreatePage;
