import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BarcodeScanner from "./BarcodeScanner"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BarcodeScannerModal({ openDialog, onCodeDetected, handleOnNextCloseClicked }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [code, setCode] = React.useState(false);

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const onCloseClicked = () => {
    handleClose();
    handleOnNextCloseClicked();
  }

  const handleCodeDetected = (code) => {
    // 在这里接收从 BarcodeScanner 组件传来的条形码信息
    handleClose();
    setCode(code)
    onCodeDetected(code);
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          バーコードをスキャン
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <BarcodeScanner onCodeDetected={handleCodeDetected} ></BarcodeScanner>
        </Box>
      </Modal>
    </div>
  );
}