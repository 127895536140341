import React from "react";
import Label from "app/components/common/Label";
import Button from "app/components/common/Button";
import { useMemo } from "react";
import { getStringAnalyseStatus } from "app/utils";
import dayjs from "dayjs";

function ExaminationDataTableItem(props) {
  const {
    children,
    onDetailClick,
    arriveBtnTxt,
    drcDeliverBtnTxt,
    onArrivedClick,
    onDrcDeliverClick,
  } = props;
  const examinationDataItem = children;

  const onDetailClicked = () => {
    onDetailClick(examinationDataItem);
  };

  const onArrivedClicked = () => {
    onArrivedClick(examinationDataItem);
  };

  const onDrcDeliverClicked = () => {
    onDrcDeliverClick(examinationDataItem);
  };

  const analyseStatus = useMemo(() => {
    let result = "";
    if (examinationDataItem.analyseStatus)
      result = getStringAnalyseStatus(examinationDataItem.analyseStatus);
    return result;
  }, [examinationDataItem.analyseStatus]);

  return (
    <tr className="border-b dark:border-neutral-500">
      {/* Chen: Temp hide */}
      {/* <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary
          disabled={
            examinationDataItem.salonDeliverStatus != "done" ? false : true
          }
          onClick={onArrivedClicked}
        >
          {arriveBtnTxt}
        </Button.Primary>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <Button.Primary
          onClick={onDrcDeliverClicked}
          disabled={
            examinationDataItem.salonDeliverStatus == "done" &&
            examinationDataItem.scalpLabDeliverStatus == "none"
              ? false
              : true
          }
        >
          {drcDeliverBtnTxt}
        </Button.Primary>
      </td> */}
      <td className="whitespace-nowrap px-1 py-4 text-center font-medium">
        {examinationDataItem.cusId}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.kitId}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.cluster}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.cellArea}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.peeling}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.cp}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {examinationDataItem.overall}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center">
        {/* <Label.DateTime dateFormat="YYYY-MM-DD"> */}
        {examinationDataItem.scalpLabReceiveDate ? dayjs(examinationDataItem.scalpLabReceiveDate).format("YYYY-MM-DD") : "-"}
        {/* </Label.DateTime> */}
      </td>
      <td className="whitespace-nowrap px-1 py-4 text-center capitalize">
        {analyseStatus}
      </td>
      <td className="whitespace-nowrap px-1 py-4">
        <Button.Primary onClick={onDetailClicked}>詳細</Button.Primary>
      </td>
    </tr>
  );
}

export default ExaminationDataTableItem;
