import DatePickerInput from "./DatePickerInput";
import TimePickerInput from "./TimePickerInput";
import FieldInput from "./FieldInput";
import MultilineFieldInput from "./MultilineFieldInput";
import Select from "./Select";
import DateTimePickerInput from "./DateTimePickerInput";
import AutocompleteInput from "./AutocompleteInput";
import FieldForwardInput from "./ForwardFieldInput";

const exportObjs = {
  Field: FieldInput,
  FieldForward: FieldForwardInput,
  MultilineField: MultilineFieldInput,
  DatePicker: DatePickerInput,
  TimePicker: TimePickerInput,
  DateTimePicker: DateTimePickerInput,
  AutocompleteInput: AutocompleteInput,
  Select: Select,
};

export default exportObjs;
