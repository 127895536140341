import React, { useRef, useEffect, useState } from 'react';
//import Webcam from 'react-webcam';
import Quagga from '@ericblade/quagga2';
import { toast } from "react-toastify";

const videoConstraints = {
  facingMode: { exact: "environment" }
};

const BarcodeScanner = ({ onCodeDetected }) => {
  const webcamRef = useRef(null);
  const [code, setCode] = useState();

  useEffect(() => {
    const initCamera = async () => {
      try {
        // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // const video = webcamRef.current.video;
        // video.srcObject = stream;
        // video.play();
        Quagga.init({
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: document.querySelector('#container'),
          },
          decoder: {
            readers: ["code_128_reader", "ean_reader", "upc_reader"]
          }
        }, (err) => {
          if (err) {
            console.error(err);
            toast.error(
              "このデバイスにはカメラがないか、カメラへのアクセス許可がありません。"
            );
            onCodeDetected("")
            return;
          }
          console.log("QuaggaJS 初始化成功");
          //setCode("QuaggaJS 初始化成功")
          Quagga.start();
        });

        Quagga.onProcessed(function(result){
          var ctx = Quagga.canvas.ctx.overlay;
          var canvas = Quagga.canvas.dom.overlay;

          ctx.clearRect(0, 0, parseInt(canvas.width), parseInt(canvas.height));



          if (result) {
              if (result.box) {
                  console.log(JSON.stringify(result.box));
                  Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, ctx, {color: 'blue', lineWidth: 2});
              }
          }

          // 検出完了時の赤線
        if (result?.codeResult && result?.codeResult?.code) {
          Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, ctx, {color: 'red', lineWidth: 3});
      }
        });

        Quagga.onDetected((result) => {
          console.log("检测到条形码：", result.codeResult.code);
          setCode(result.codeResult.code)
          if (result.codeResult.code != "") onCodeDetected(result.codeResult.code)
        });
      } catch (err) {
        console.error('Error accessing the camera:', err);
        setCode("Error accessing the camera")
        toast.error(
          "このデバイスにはカメラがないか、カメラへのアクセス許可がありません。"
        );
      }
    };

    initCamera();

    return () => {
      Quagga.stop();
    };
  }, []);

  return (
    <div>
      <style>
        {`
          #container > video, #container > canvas {
            width: 100%;
          }
          #container > canvas {
            position: absolute;
            left: 0;
            top: 0;
          }
        `}
      </style>
      {/* <Webcam
        audio={false}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        width={640}
        height={480}
        screenshotFormat="image/jpeg"
      /> */}
          <div id="container"></div>
          <p id="process"></p>
          <p id="result"></p>
        <div>{code}</div>
    </div>
  );
};

export default BarcodeScanner;
