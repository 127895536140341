import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BarcodeScanner from "./BarcodeScanner"
const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

export default function RegisterSuccessFailModal({ openDialog, onCodeDetected, handleOnCloseClicked, title, content, isSuccess }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [code, setCode] = React.useState(false);

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const onCloseClicked = () => {
    handleClose();
    handleOnCloseClicked();
  }

  const handleCodeDetected = (code) => {
    // 在这里接收从 BarcodeScanner 组件传来的条形码信息
    handleClose();
    setCode(code)
    onCodeDetected(code);
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{}}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <div class="fixed z-10 inset-0 overflow-y-auto" id="my-modal">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div>
                        {isSuccess? (
                          <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                              <svg class="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                  stroke="currentColor" aria-hidden="true">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M12 2a10 10 0 100 20a10 10 0 100-20z" fill="none" />
                              </svg>
                          </div>
                        ): (
                          <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        )}
                        <div class="mt-3 text-center sm:mt-5">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                {title}
                            </h3>
                            <div class="mt-2">
                                <p dangerouslySetInnerHTML= {{__html: content}} class="text-sm text-gray-500">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-6">
                        <button
                            //class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                            className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 ${isSuccess ? "bg-blue-600 hover:bg-blue-700": "bg-red-600 hover:bg-red-700" } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm`}
                            onClick={onCloseClicked}
                            >
                            閉じる
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}